export default class ProductLanguageSerializer {
  fromJson(json) {
    const productsLanguages = {};

    Object.assign(
      productsLanguages,
      json.id_produto_idioma && { idProdutoIdioma: json.id_produto_idioma },
      json.idioma && { idIdioma: json.idioma.id_idioma },
      json.nome && { nome: json.nome },
      json.descricao && { descricao: json.descricao }
    );
    return productsLanguages;
  }

  toJson(products) {
    const productsLanguagesToJson = {};

    Object.assign(
      productsLanguagesToJson,

      products.id && {
        id_produto_idioma: products.id,
      },
      products.idIdioma && { id_idioma: products.idIdioma },
      products.nome && { nome: products.nome },
      products.descricao && { descricao: products.descricao }
    );
    return productsLanguagesToJson;
  }
}
