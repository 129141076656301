import api from "./api.service";
import CoreApiService from "./core-api.service";

export default class RedeSocialService extends CoreApiService {
  constructor() {
    super(undefined, "redes-sociais", undefined);
  }

  async incrementarClique(idRedeSocial) {
    await api.put(`${this.endpoint}/${idRedeSocial}/cliques`);
  }
}
