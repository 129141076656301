/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Slide } from "@mui/material";
import favoritarProdutoIndex from "assets/img/expo-revestir/icons/heart-icons.svg";
import favoritarProdutoPreenchidoIndex from "assets/img/expo-revestir/icons/filled-heart-icons.svg";
import UsuarioService from "services/usuario.service";
import ProductsImagesService from "../../../../services/products-images.service";
import { AppContext } from "store";
import { Col, Row } from "reactstrap";
import useWindowDimensions from "utils/verificar-dimensao-da-tela";

export default function ProdutoItem({ produto, idExpositor }) {
  const { uriUnico } = useParams();
  const [ativo, setAtivo] = useState(false);
  const history = useHistory();
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const { user, setUser } = useContext(AppContext);
  const [produtoImagem, setProdutoImagem] = useState([]);
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();

  const _usuarioService = new UsuarioService();

  useEffect(() => {
    async function init() {
      const _productsImagesService = new ProductsImagesService();
      const response = await _productsImagesService.listSub(produto.id);
      setProdutoImagem(response);
      setLoading(false);
    }

    init();
  }, []);

  async function onFavoritarProdutoItem() {
    _usuarioService.favoritar({ id_produto: produto.id }).then((response) => {
      const aux = { ...user };

      if (response.favoritado) {
        const favoritado = {
          idProduto: produto.id,
          idExpositor: idExpositor,
          expositorUriUnico: uriUnico,
        }

        if (produto.imagens?.[0]?.id) {
          favoritado.imagens = [{ id_produto_imagem: produto.imagens[0].id }]
        }

        aux.produtosFavoritos.push(favoritado);
      } else {
        aux.produtosFavoritos = aux.produtosFavoritos.filter(
          (item) => item.idProduto !== produto.id
        );
      }

      setUser(aux);
    });
  }

  function verificaFavoritoProdutoItem() {
    if (user && user.hasOwnProperty("produtosFavoritos")) {
      return user.produtosFavoritos.find(
        (item) => item.idProduto === produto.id
      );
    }
  }

  return (
    <>
      <div className="produto-loading-container">
        {loading && (
          <lottie-player
            src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px", margin: "0 auto" }}
            loop
            autoplay
          />
        )}
      </div>

      {width >= 768 ? (
        <div
          hidden={loading}
          className="produto-card"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_URL}produto-imagens/${produtoImagem[0]?.id}/display)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          onMouseOver={() => setAtivo(true)}
          onMouseOut={() => setAtivo(false)}
          ref={containerRef}
        >
          <Slide direction="up" in={ativo} container={containerRef.current}>
            <div className="produto-card-body" id={produto.id}>
                <h4>{produto.nome}</h4>
              <p>{produto.descricao}</p>

              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="7"
                  xl="7"
                  className="dimensao-container"
                >
               <div
                className="favoritar-produto"
                onClick={onFavoritarProdutoItem}
              >
                <img
               src={
                 verificaFavoritoProdutoItem()
                   ? favoritarProdutoPreenchidoIndex
                   : favoritarProdutoIndex
               }
               alt="favoritar produto"
             />
             </div>
                  {produto.dimensao && (
                    <p>
                      {t("Dimensão")}: {produto.dimensoesProduto}
                    </p>
                  )}
                </Col>

                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="5"
                  xl="5"
                  className="botao-produto-container"
                >
                  <button
                    className="mr-1 btn-galeria-dark"
                    onClick={() =>
                      history.push(
                        `/expositor/${uriUnico}/produto/${produto.id}`
                      )
                    }
                  >
                    <span>{t("Ver produto")}</span>
                  </button>
                </Col>
              </Row>
            </div>
          </Slide>
        </div>
      ) : (
        <>
          <div
            hidden={loading}
            className="produto-card"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_API_URL}produto-imagens/${produtoImagem[0]?.id}/display)`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            onMouseOver={() => setAtivo(true)}
            onMouseOut={() => setAtivo(false)}
            ref={containerRef}
          />

          <div className="produto-card-body" id={produto.id}>
              <h4>{produto.nome}</h4>
            <p>{produto.descricao}</p>

            <Row >
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="7"
                xl="7"
                className="dimensao-container"
              >
                {produto.dimensao && (
                  <p>
                    {t("Dimensão")}: {produto.dimensoesProduto}
                  </p>
                )}
                    <div className="favoritar-produto" onClick={onFavoritarProdutoItem}>
                      <img
                     src={
                       verificaFavoritoProdutoItem()
                       ? favoritarProdutoPreenchidoIndex
                       : favoritarProdutoIndex
                      }
                      alt="favoritar produto"
                      />
                </div>
              <div
                className="botao-produto-container"
                >
                <button
                  className="mr-1 btn-galeria-dark"
                  onClick={() =>
                    history.push(`/expositor/${uriUnico}/produto/${produto.id}`)
                  }
                  >
                  <span>{t("Ver produto")}</span>
                </button>
                    </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
}
