import AuthLayout from "components/Layouts/AuthLayout";
import React from "react";
import { Redirect, Route } from "react-router";
import Authentication from "../services/authentication.service";
import Index from "views/Index";

const URL_REDIRECIONAR = "url_redirecionar";

const validaUrlAssentador = (pathname) => {
  const url = pathname.split("/")[1];
  if (url === "") {
    return "home";
  }

  return url;
};

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isPublic,
  ...rest
}) {
  const signed = Authentication.isAuthenticated();
  const trocarSenha = Authentication._user && Authentication._user.trocarSenha;
  const isAssentador =
    Authentication._user && Authentication._user?.perfil.id === 4;

  const isEspecificador =
    Authentication._user && Authentication._user?.perfil.id === 5;    

  if (
    !signed &&
    !isPublic &&
    (isPrivate ||
      !["/entrar", "/sair", "/recuperar-senha", "/mapa", "/visitante"].includes(
        rest.location.pathname
      ))
  ) {
    const pathname = window.location.pathname;
    localStorage.setItem(URL_REDIRECIONAR, pathname);

    return <Redirect to="/entrar" />;
  }

  if (
    (isAssentador || isEspecificador) &&
    ![
      "sair",
      "mudar-senha",
      "assentador-todos-cursos",
      "assentador-cursos",
      "cursos-modulo",
      "video",
      'perfil',
    ].includes(validaUrlAssentador(rest.location.pathname))
  ) {
    if (rest.path) {
      if (!"/assentador-cursos".includes(rest.path)) {
        return <Redirect to="/assentador-cursos" />;
      }
    } else {
      return <Redirect to="/assentador-cursos" />;
    }
  }

  if (trocarSenha || rest.path === "/mudar-senha") {
    if (trocarSenha === undefined) {
      return <Redirect to="/" />;
    }

    if (trocarSenha && rest.path !== "/mudar-senha") {
      return <Redirect to="/mudar-senha" />;
    }
  }

  let Layout = Index;
  if (!signed || trocarSenha || isPublic) {
    Layout = AuthLayout;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
