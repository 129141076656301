export default class CursoAulaIdiomaSerializer {
  fromJson(json) {
    const idioma = {};

    Object.assign(
      idioma,
      json.id_curso_aula_idioma && {
        idCursoAulaIdioma: json.id_curso_aula_idioma,
      },
      json.descricao && { descricao: json.descricao },
      json.titulo && { titulo: json.titulo },
      json.instrutor && { instrutor: json.instrutor },
      json.link_video && { linkVideo: json.link_video },
      json.id_curso_aula && { idCursoAula: json.id_curso_aula },
      json.id_idioma && { idIdioma: json.id_idioma }
    );

    return idioma;
  }
}
