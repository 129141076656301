import { Message } from "primereact/message";

export default class AlertService {
  static success(menssage, close = true) {
    Message.messages.show({
      severity: "success",
      summary: "Sucesso",
      detail: menssage,
      sticky: true,
    });

    if (close) {
      this.close();
    }
  }

  static warn(menssage, close = true) {
    Message.messages.show({
      severity: "warn",
      summary: "Atenção",
      detail: menssage,
      sticky: true,
    });

    if (close) {
      this.close();
    }
  }

  static error(menssage, close = true) {
    Message.messages.show({
      severity: "error",
      summary: "Erro",
      detail: menssage,
      sticky: true,
    });

    if (close) {
      this.close();
    }
  }

  static clear() {
    Message.messages.clear();
  }

  static close() {
    setTimeout(() => {
      this.clear();
    }, 5000);
  }
}
