import BaseSerializer from "./base.serializer";
import ProductsTypeIdiomaSerializer from "./product-type-idioma.serializer";

export default class ProductTypeSerializer extends BaseSerializer {
  fromJson(json) {
    const expositorProductType = {};

    Object.assign(
      expositorProductType,
      json.id_produto_tipo && { value: json.id_produto_tipo },
      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new ProductsTypeIdiomaSerializer())
      }
    );

    return expositorProductType;
  }
}
