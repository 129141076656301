export default class ExpositoresClicadosSerializer {
  fromJson(json) {
    const expositoresClicados = {};

    if (
      json.hasOwnProperty("expositoresClicados") &&
      json.expositoresClicados.length
    ) {
      return json.expositoresClicados.map((item) => {
        return {
          id: item.id_expositor,
          uriUnico: item.uri_unico,
          nomeFantasia: item.nome_fantasia,
        };
      });
    }

    return expositoresClicados;
  }

  toJson(expositor) {
    const expositoresClicados = {};

    Object.assign(expositoresClicados);
    return expositoresClicados;
  }
}
