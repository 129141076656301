import { useEffect, useState } from "react";
import { useParams } from "react-router";
import MapaInterativoService from "services/mapa-interativo.service";
import iconPin from '../../assets/img/pin.png';

import "./styles.scss";

class ResponsiveImageMap {
  constructor(map, img, width) {
    this.img = img;
    this.originalWidth = width;
    this.areas = [];

    for (const area of map.getElementsByTagName("area")) {
      this.areas.push({
        element: area,
        originalCoords: area.coords.split(","),
      });
    }

    window.addEventListener("resize", (e) => this.resize(e));
    this.resize();
  }

  resize() {
    const ratio = this.img.offsetWidth / this.originalWidth;

    for (const area of this.areas) {
      const newCoords = [];
      for (const originalCoord of area.originalCoords) {
        newCoords.push(Math.round(originalCoord * ratio));
      }
      area.element.coords = newCoords.join(",");
    }

    return true;
  }
}

export default function MapaInterativo() {
  const { slug } = useParams();
  const [mapa, setMapa] = useState();

  useEffect(() => {
    async function init() {
      const _mapaInerativoService = new MapaInterativoService();
      const data = await _mapaInerativoService.get(slug);
      setMapa(data);

      const map = document.getElementById("map-area");
      const image = document.getElementById("img_ID");

      image.onload = () => {
        new ResponsiveImageMap(map, image, image.naturalWidth);

        const areas = document.getElementsByTagName("area");

        for (let i = 0; i < areas.length; i++) {
          areas[i].setAttribute(
            "title",
            areas[i].dataset.nome + "\nEstande: " + areas[i].dataset.estande
          );
          areas[i].setAttribute('onmouseover', 'myHover(this)');
          areas[i].setAttribute('onmouseout', 'myLeave()');
          areas[i].setAttribute('onclick', 'openExpositor(this)')
          areas[i].setAttribute('ondblclick', 'openExpositorMobile(this)')
        }

        // eslint-disable-next-line no-undef
        myInit()
      };
    }

    init();
  }, [slug]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div id="title-mapa" style={{ zIndex: 99 }}>
          <div className="arrow_box">
            <div id="box-mapa-title"></div>
            <div id="box-mapa-desc"></div>
          </div>
          <img src={iconPin} alt="Pin" />
        </div>
        <canvas id="myCanvas" style={{ zIndex: 9 }}></canvas>
        <img
          id="img_ID"
          src={`${process.env.REACT_APP_API_URL}mapas/${mapa?.id}/display`}
          alt="mapa"
          useMap="#image-map"
          width="100%"
        />
        <div dangerouslySetInnerHTML={{ __html: mapa?.html }}></div>
      </div>
    </>
  );
}
