export default class SexoIdiomaSerializer {
  fromJson(json) {
    const SexoIdiomaSerializer = {};

    Object.assign(
      SexoIdiomaSerializer,
      json.id_usuario_sexo && {
        idUsuarioSexo: json.id_usuario_sexo,
        value: json.id_usuario_sexo,
      },
      json.sexo && { sexo: json.sexo, label: json.sexo }
    );

    return SexoIdiomaSerializer;
  }
}
