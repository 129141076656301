export default class FaleComExpositorSerializer {
  toJson(contato) {
    const contatoToJson = {};

    Object.assign(
      contatoToJson,
      contato.idExpositor && { id_expositor: contato?.idExpositor },
      contato.assunto !== "" && { assunto: contato?.assunto }
    );

    return contatoToJson;
  }
}
