import ButtonSave from "components/ButtonSave";
import SelecionarIdioma from "components/SelecionarIdioma";
import TextInput from "components/TextInput";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Authentication from "services/authentication.service";
import { AppContext } from "store";
import * as Yup from "yup";
import ModalCadastroUsuario from "../../components/expo-revestir/ModalCadastroUsuario";
import ContainerVisitante from "./ContainerVisitante";

export default function LoginPage() {
  const history = useHistory();
  const [hasError, setHasError] = useState("");
  const [errorMenssage, setErrorMenssage] = useState("");
  const [loading, setloading] = useState(false);
  const { setUser } = useContext(AppContext);
  const [userLogin, setUserLogin] = useState("");
  const [isRememberLogin, setIsRememberLogin] = useState(false);
  const [modalCadastrarNovoUsuario, setModalCadastrarNovoUsuario] =
    useState(false);
  const { t } = useTranslation();

  function saveLogin(remember, login) {
    if (remember) {
      localStorage.setItem("userLogin", login);
      localStorage.setItem("rememberUserLogin", true);
    } else {
      localStorage.removeItem("userLogin");
      localStorage.removeItem("rememberUserLogin");
    }
  }

  async function handleSignIn(data) {
    setErrorMenssage("");
    try {
      setloading(true);
      await new Authentication().singIn(data.user, data.password);
      setUser(Authentication._user);
      saveLogin(isRememberLogin, data.user);
      setHasError(false);

      const url_redirect = localStorage.getItem("url_redirecionar");
      if (url_redirect) {
        localStorage.removeItem("url_redirecionar");
        history.push(url_redirect);
      } else {
        history.push("/home");
      }
    } catch ({ response }) {
      if (response && response.data) {
        if (response.data.iniciado === false) {
          const { data_inicio, data_fim, hora, msg } = response.data;

          history.push(
            `/visitante?data_inicio=${data_inicio}&data_fim=${data_fim}&hora=${hora}&msg=${msg}`
          );
        }

        if (response.data.msg === "Unauthorized") {
          setHasError(true);
          setErrorMenssage("");
        } else {
          setHasError(false);
          setErrorMenssage(response.data.msg);
        }
      }
    } finally {
      setloading(false);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("rememberUserLogin")) {
      setUserLogin(localStorage.getItem("userLogin"));
      setIsRememberLogin(true);
    }
  }, []);

  const errorMessage = (
    <div className="error-menssage">
      {t("Login ou senha incorretos, por favor tente novamente")}
    </div>
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          user: userLogin,
          password: "",
        }}
        validationSchema={Yup.object({
          user: Yup.string().required(t("Obrigatório")),
          password: Yup.string().required(t("Obrigatório")).nullable(),
        })}
        onSubmit={(values) => {
          handleSignIn(values);
        }}
      >
        {(props) => (
          <>
            <div className="login_wrapper">
              <ContainerVisitante />

              <div className="container-login">
                <div className="login_text_form">
                  <SelecionarIdioma />

                  <div className="login_text">
                    <h1>{t("Conecte-se")}</h1>
                  </div>

                  <ModalCadastroUsuario
                    modalCadastrarNovoUsuario={modalCadastrarNovoUsuario}
                    setModalCadastrarNovoUsuario={setModalCadastrarNovoUsuario}
                  />

                  <Form
                    className="form"
                    autoComplete="off"
                    style={{ zIndex: "1" }}
                  >
                    <Row style={{ flexDirection: "column" }}>
                      <Col sm="12">
                        <FormGroup>
                          <TextInput
                            label={t("E-mail")}
                            className="form-control"
                            name="user"
                            placeholder={t("E-mail")}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="12">
                        <FormGroup>
                          <TextInput
                            label={t("Senha/CPF")}
                            className="form-control"
                            name="password"
                            placeholder={t("Senha/CPF")}
                            type="password"
                          />
                        </FormGroup>
                      </Col>

                      <Col
                        sm="12"
                        style={{
                          textAlign: "left",
                          margin: "10px 0",
                        }}
                      >
                        <FormGroup check>
                          <Label check>
                            <Input
                              checked={isRememberLogin}
                              defaultValue=""
                              type="checkbox"
                              onChange={() =>
                                setIsRememberLogin(!isRememberLogin)
                              }
                            />
                            {t("Mantenha-me conectado")}
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    <ButtonSave
                      loading={loading}
                      type="submit"
                      styles="w-all mr-1 btn-login"
                      label={t("Entrar")}
                    >
                      {t("Entrar")}
                    </ButtonSave>

                    {hasError ? errorMessage : null}
                    {errorMenssage && (
                      <div className="error-menssage">{errorMenssage}</div>
                    )}

                    <div
                      onClick={() => history.push("/recuperar-senha")}
                      className="text-link"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {t("Esqueceu a senha? Recuperar Senha")}
                    </div>

                    <div>
                    
                      Não tem Acesso? Por favor, entre em contato com o Rodrigo Azevedo: <a href="mailto:projetos@anfacer.org.br">projetos@anfacer.org.br</a> ou <a href="tel:+551197325-2326">(11) 9.7325-2326</a>. Muito obrigado!
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
