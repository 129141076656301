/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import EventService from "services/evento.service";
import getDataLocale from "utils/map-data";
import QueryOptions from "utils/query-options";
import ModalMaisInfoSobreEvento from "../../components/expo-revestir/ModalMaisInfoSobreEvento";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import EventosConteudosDisponiveis from "components/expo-revestir/Eventos/EventosConteudosDisponiveis";
import UsuarioService from "services/usuario.service";
import EditionService from "services/edition.service";

export default function EventosFuturos() {
  const [evento, setEvento] = useState();
  const [modalAberto, setModalAberto] = useState(false);
  const [time] = useState(new Date().getTime());
  const [eventoExpositor, setEventoExpositor] = useState([]);
  const [eventosCorrentes, setEventosCorrentes] = useState([]);
  const [eventosDisponiveis, setEventosDisponiveis] = useState([]);
  const [eventosPassados, setEventosPassados] = useState([]);
  const [showLimparFiltragemBtn, setShowLimparFiltragemBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [eventosAgendados, setEventosAgendados] = useState();
  const { t } = useTranslation();

  const _eventosService = new EventService();
  const _edicaoService = new EditionService();

  async function pegarTodosEventos() {
    const queryOptionEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
    const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
      queryOptionEdicaoAtiva
    );
    const eventosCorrentesResposta = await _eventosService.list(
      new QueryOptions([
        ["evento_anfacer", true],
        ["evento_corrente"],
        ["edicao", edicaoAtual?.id],
      ])
    );
    setEventosCorrentes(eventosCorrentesResposta);

    const eventoResponseEventosPassados = await _eventosService.list(
      new QueryOptions([
        ["evento_anfacer", true],
        ["evento_passado"],
        ["edicao", edicaoAtual?.id],
      ])
    );
    setEventosPassados(eventoResponseEventosPassados);

    const eventosDisponiveisResponse = await _eventosService.list(
      new QueryOptions([
        ["evento_disponivel", 1],
        ["edicao", edicaoAtual?.id],
      ])
    );
    setEventosDisponiveis(eventosDisponiveisResponse);
  }

  useEffect(() => {
    async function init() {
      pegarTodosEventos();

      const _calendarioEventosAgendados = new UsuarioService();
      const respostaApiEventosAgendados =
        await _calendarioEventosAgendados.agendaEventos();

      setEventosAgendados(respostaApiEventosAgendados.eventosAgendados);
      setEventoExpositor(eventoExpositor);
      setLoading(false);
    }

    init();
  }, []);

  const eventosOpcoes = [
    { label: t("Evento oficial"), value: 1 },
    { label: t("Evento parceiro"), value: 2 },
    { label: t("Evento expositor"), value: 3 },
  ];

  function verificarRotuloBotao(evento) {
    if (eventosAgendados?.length) {
      for (let index = 0; index < eventosAgendados?.length; index++) {
        const elemento = eventosAgendados[index];

        if (elemento?.idEvento === evento?.id) {
          return t("Agendado");
        }
      }

      return t("Agendar");
    } else {
      return t("Agendar");
    }
  }

  async function filtrarEvento(ev) {
    async function pegarEventosTipos(eventoEstado, eventoTipo) {
      const queryOptionEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
        queryOptionEdicaoAtiva
      );
      return await _eventosService.list(
        new QueryOptions([
          ["evento_anfacer", true],
          [eventoEstado],
          ["evento_tipo", eventoTipo],
          ["edicao", edicaoAtual?.id],
        ])
      );
    }
    setShowLimparFiltragemBtn(true);

    switch (ev.value) {
      case 1:
        setEventosCorrentes(
          await pegarEventosTipos("evento_corrente", "oficial")
        );
        setEventosDisponiveis(
          await pegarEventosTipos("evento_disponivel", "oficial")
        );
        setEventosPassados(
          await pegarEventosTipos("evento_passado", "oficial")
        );
        break;

      case 2:
        setEventosCorrentes(
          await pegarEventosTipos("evento_corrente", "parceiro")
        );
        setEventosDisponiveis(
          await pegarEventosTipos("evento_disponivel", "parceiro")
        );
        setEventosPassados(
          await pegarEventosTipos("evento_passado", "parceiro")
        );
        break;

      case 3:
        setEventosCorrentes(
          await pegarEventosTipos("evento_corrente", "expositor")
        );

        setEventosDisponiveis(
          await pegarEventosTipos("evento_disponivel", "expositor")
        );

        setEventosPassados(
          await pegarEventosTipos("evento_passado", "expositor")
        );
        break;

      default:
        pegarTodosEventos();
        break;
    }
  }

  return (
    <>
      <ModalMaisInfoSobreEvento
        modalAberto={modalAberto}
        setModal={setModalAberto}
        evento={evento}
        eventosAgendados={eventosAgendados}
        setEventosAgendados={setEventosAgendados}
      />

      <div className="produto-loading-container">
        {loading && (
          <lottie-player
            src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px", margin: "0 auto" }}
            loop
            autoplay
          />
        )}
      </div>

      <div hidden={loading}>
        <div className="container eventos-futuros">
          <Row className="filtragem-e-info-container">
            <Col xs="12" sm="8" md="9" lg="9" xl="10">
              <h1>Próximos eventos</h1>
            </Col>
            <Col className="seletor" xs="12" sm="4" md="3" lg="3" xl="2">
              <Select
                closeMenuOnSelect={true}
                onChange={(ev) => {
                  filtrarEvento(ev);
                }}
                options={eventosOpcoes}
                placeholder={t("Tipo de evento")}
              />
              {showLimparFiltragemBtn && (
                <div className="limpar-filtros-container">
                  <span
                    className="limpar-filtros"
                    onClick={() => {
                      filtrarEvento({});
                      setShowLimparFiltragemBtn(false);
                    }}
                  >
                    {t("Limpar filtros")}
                  </span>
                </div>
              )}
            </Col>
          </Row>

          <div className="eventos-container">
            <div className="eventos-expositor-container">
              <Row>
                {eventosCorrentes?.length ? (
                  <>
                    {eventosCorrentes?.map((evento) => (
                      <Col xs="12" sm="12" md="6" lg="4" xl="3">
                        <div
                          className="imagem-card"
                          onClick={() => {
                            setModalAberto(!modalAberto);
                            setEvento(evento);
                          }}
                        >
                          <div>
                            <div className="data-evento-carrosel">
                              <span>{format(evento.dataInicio, "dd")}</span>
                              <span>
                                {format(evento.dataInicio, "MMM", {
                                  locale: getDataLocale(),
                                })}
                              </span>
                            </div>
                            {evento.idEventoCategoria === 1 && (
                              <div
                                style={{
                                  borderTop: "3px solid #52C9E8",
                                }}
                              >
                                <div class="card-evento">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time}`}
                                    alt="Imagem do evento"
                                  />
                                </div>
                              </div>
                            )}
                            {evento.idEventoCategoria === 2 && (
                              <div
                                style={{
                                  borderTop: "3px solid #60269E",
                                }}
                              >
                                <div class="card-evento">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time}`}
                                    alt="Imagem do evento"
                                  />
                                </div>
                              </div>
                            )}
                            {evento.idEventoCategoria === 3 && (
                              <div
                                style={{
                                  borderTop: "3px solid #F87FB4",
                                }}
                              >
                                <div class="card-evento">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time}`}
                                    alt="Imagem do evento"
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="card-body">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                justifyContent: "flex-start",
                                alignContent: "center",
                                alignItems: "center",
                                marginBottom: "15px",
                              }}
                            >
                              {evento.idEventoCategoria === 1 && (
                                <div
                                  style={{
                                    backgroundColor: "#52C9E8",
                                    padding: "1px 5px",
                                  }}
                                >
                                  <p style={{ margin: "0", color: "#fff" }}>
                                    {t("Evento oficial")}
                                  </p>
                                </div>
                              )}

                              {evento.idEventoCategoria === 2 && (
                                <div
                                  style={{
                                    backgroundColor: "#60269E",
                                    padding: "1px 5px",
                                  }}
                                >
                                  <p style={{ margin: "0", color: "#fff" }}>
                                    {t("Evento parceiro")}
                                  </p>
                                </div>
                              )}

                              {evento.idEventoCategoria === 3 && (
                                <div
                                  style={{
                                    backgroundColor: "#F87FB4",
                                    padding: "1px 5px",
                                  }}
                                >
                                  <p style={{ margin: "0", color: "#fff" }}>
                                    {t("Evento expositor")}
                                  </p>
                                </div>
                              )}

                              <div className="plataforma-evento">
                                {evento.eventoExterno ? (
                                  <div
                                    style={{
                                      backgroundColor: "#FE391F",
                                      padding: "1px 5px",
                                    }}
                                  >
                                    <p style={{ margin: "0", color: "#fff" }}>
                                      {t("Evento externo")}
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor: "#93D501",
                                      padding: "1px 5px",
                                    }}
                                  >
                                    <p style={{ margin: "0", color: "#fff" }}>
                                      {t("Plataforma Expo Revestir")}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="text-container">
                              <h3>{evento.titulo}</h3>
                              <p>{evento.descricao}</p>
                            </div>
                            <div className="card-footer-container">
                              <div className="evento-data">
                                <div className="evento-horas-data">
                                  <p>
                                    {format(evento.dataInicio, "H:mm")} -{" "}
                                    {format(evento.dataFim, "H:mm")}
                                  </p>
                                </div>{" "}
                                <div className="evento-calendario-data">
                                  <p>{evento.dataFimStr}</p>
                                </div>{" "}
                              </div>{" "}
                              <div className="button-agenda-expositor">
                                <button className="mr-1 btn-card-event btn btn-info botao-card-evento">
                                  {verificarRotuloBotao(evento)}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Row>
            </div>
          </div>
        </div>

        <EventosConteudosDisponiveis
          eventosDisponiveis={eventosDisponiveis}
          setModalAberto={setModalAberto}
          modalAberto={modalAberto}
          setEvento={setEvento}
        />

        {eventosPassados?.length ? (
          <div className="eventos-passados-container">
            <div className="container">
              <Row className="filtragem-e-info-container">
                <Col>
                <h1>{t("Eventos passados")}</h1>
                </Col>
              </Row>
            
              <div className="eventos-container">
                <div className="eventos-expositor-container">
                  <Row>
                    {eventosPassados?.map((evento) => (
                      <Col xs="12" sm="12" md="6" lg="4" xl="3">
                        <div
                          style={{
                            width: "100%",
                            cursor: "pointer",
                            border: "1px solid #e1e1e1",
                          }}
                          onClick={() => {
                            setModalAberto(!modalAberto);
                            setEvento(evento);
                          }}
                        >
                          <div>
                            <div className="data-evento-carrosel">
                              <span>{format(evento.dataInicio, "dd")}</span>
                              <span>
                                {format(evento.dataInicio, "MMM", {
                                  locale: getDataLocale(),
                                })}
                              </span>
                            </div>
                            {evento.idEventoCategoria === 1 && (
                              <div
                                style={{
                                  borderTop: "3px solid #52C9E8",
                                }}
                              >
                                <div class="card-evento">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time}`}
                                    alt="Imagem do evento"
                                  />
                                </div>
                              </div>
                            )}
                            {evento.idEventoCategoria === 2 && (
                              <div
                                style={{
                                  borderTop: "3px solid #60269E",
                                }}
                              >
                                <div class="card-evento">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time}`}
                                    alt="Imagem do evento"
                                  />
                                </div>
                              </div>
                            )}
                            {evento.idEventoCategoria === 3 && (
                              <div
                                style={{
                                  borderTop: "3px solid #F87FB4",
                                }}
                              >
                                <div class="card-evento">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time}`}
                                    alt="Imagem do evento"
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="card-body">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                justifyContent: "flex-start",
                                alignContent: "center",
                                alignItems: "center",
                                marginBottom: "15px",
                              }}
                            >
                              {evento.idEventoCategoria === 1 && (
                                <div
                                  style={{
                                    backgroundColor: "#52C9E8",
                                    padding: "1px 5px",
                                    marginRight: "4px"
                                  }}
                                >
                                  <p style={{ margin: "0", color: "#fff" }}>
                                    {t("Evento oficial")}
                                  </p>
                                </div>
                              )}

                              {evento.idEventoCategoria === 2 && (
                                <div
                                  style={{
                                    backgroundColor: "#60269E",
                                    padding: "1px 5px",
                                    marginRight: "4px"
                                  }}
                                >
                                  <p style={{ margin: "0", color: "#fff" }}>
                                    {t("Evento parceiro")}
                                  </p>
                                </div>
                              )}

                              {evento.idEventoCategoria === 3 && (
                                <div
                                  style={{
                                    backgroundColor: "#F87FB4",
                                    padding: "1px 5px",
                                    marginRight: "4px"
                                  }}
                                >
                                  <p style={{ margin: "0", color: "#fff" }}>
                                    {t("Evento expositor")}
                                  </p>
                                </div>
                              )}

                              <div className="plataforma-evento">
                                {evento.eventoExterno ? (
                                  <div
                                    style={{
                                      backgroundColor: "#FE391F",
                                      padding: "1px 5px",
                                    }}
                                  >
                                    <p style={{ margin: "0", color: "#fff" }}>
                                      {t("Evento Externo")}
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor: "#93D501",
                                      padding: "1px 5px",
                                    }}
                                  >
                                    <p style={{ margin: "0", color: "#fff" }}>
                                      {t("Plataforma Expo Revestir")}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="text-container">
                              <h3>{evento.titulo}</h3>
                              <p>{evento.descricao}</p>
                            </div>

                            <div className="card-footer-container">
                              <div className="evento-data">
                                <div className="evento-horas-data">
                                  <p>
                                    {format(evento.dataInicio, "H:mm")} -{" "}
                                    {format(evento.dataFim, "H:mm")}
                                  </p>
                                </div>{" "}
                                <div className="evento-calendario-data">
                                  <p>{evento.dataFimStr}</p>
                                </div>{" "}
                              </div>{" "}
                              <div className="button-agenda-expositor">
                                <button className="mr-1 btn-card-event btn btn-info botao-card-evento">
                                  {t("Finalizado")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
