import React from "react";
import { useField } from "formik";

export default function InputCpf({
  label,
  classes = "",
  obrigatorio,
  ...props
}) {
  const [field, meta] = useField(props);

  let value = field.value;
  if (field.value) {
    value = field.value.replace(/[^0-9]/g, "");

    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    }
  }

  return (
    <div className={classes}>
      <label htmlFor={props.id || props.name}>
        {label}{" "}
        {obrigatorio && (
          <>
            <span className="required-hash">*</span>
          </>
        )}
      </label>
      <div className="input-block">
        <input
          className="input-text"
          {...field}
          {...props}
          value={value}
          maxLength="14"
        />
        {props.loading && (
          <i className="pi pi-spin pi-spinner input-loading"></i>
        )}
      </div>
      <div className="error-input">
        {meta.touched && meta.error ? (
          <div className="error ">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}
