import TelefoneTipoSerializer from "./telefone-tipo.serializer";

export default class TelefoneSerialier {
  constructor() {
    this.telefoneTipoSerializer = new TelefoneTipoSerializer();
  }

  fromJson(json) {
    const telefone = {};
    Object.assign(
      telefone,
      json.id_telefone && { id: json.id_telefone },
      json.ddd && { ddd: json.ddd },
      json.ddi && { ddi: json.ddi },
      json.telefone && { telefone: json.telefone },
      json.ramal && { ramal: json.ramal },
      json.telefone_tipo && {
        telefoneTipo: this.telefoneTipoSerializer.fromJson(json.telefone_tipo),
        tipo: json.telefone_tipo.id_telefone_tipo,
      }
    );

    return telefone;
  }

  toJson(telefone) {
    const telefoneToJson = {};

    Object.assign(
      telefoneToJson,
      telefone.id && { id_telefone: telefone.id },
      telefone.ddd && { ddd: telefone.ddd },
      telefone.ddi && { ddi: telefone.ddi },
      telefone.telefone && {
        telefone: telefone.telefone.replace(" ", "").replace("-", ""),
      },
      telefone.ramal && { ramal: telefone.ramal },
      telefone.tipo && { id_telefone_tipo: telefone.tipo }
    );

    return telefoneToJson;
  }
}
