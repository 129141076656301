import api from "./api.service";
import CoreApiService from "./core-api.service";
import TelefoneTipoSerialier from "./serializers/telefone-tipo.serializer";

export default class PhoneTypeService extends CoreApiService {
  constructor() {
    super(undefined, "telefones/tipos", new TelefoneTipoSerialier());
  }

  async listarTelefoneTipoCadastro() {
    this.parentEndpoint = "plataforma";

    const response = await api.get(`${this.parentEndpoint}/${this.endpoint}`);
    const data = response.data;

    return this.convertData(data);
  }
}
