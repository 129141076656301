import BaseSerializer from "./base.serializer";
import EventLanguageSerializer from "./evento-idioma.serializer";

export default class CalendarioEventoAgendadoSerializer extends BaseSerializer {
  fromJson(json) {
    const calendarioEventoAgendado = {};

    Object.assign(
      calendarioEventoAgendado,
      json.data_inicio && { dataInicio: json.data_inicio },
      json.data_fim && { dataFim: json.data_fim },
      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new EventLanguageSerializer()),
      },
      json.id_evento && {
        idEvento: json.id_evento,
      },
      json.id_evento_categoria && {
        idEventoCategoria: json.id_evento_categoria,
      },
      json.uri_unico && {
        uriUnico: json.uri_unico,
      },
      json.url_video && {
        urlVideo: json.url_video,
      },
      json.evento_externo && {
        eventoExterno: json.evento_externo,
      }
    );

    return calendarioEventoAgendado;
  }

  toJson(bannerExpositorLanguage) {
    const bannerExpositorLanguageToJson = {};

    Object.assign(
      bannerExpositorLanguageToJson,
      bannerExpositorLanguage.link && { link: bannerExpositorLanguage.link },
      bannerExpositorLanguage.idIdioma && {
        id_idioma: bannerExpositorLanguage.idIdioma,
      },
      bannerExpositorLanguage.idExpositorBannerIdioma && {
        id_expositor_banner_idioma:
          bannerExpositorLanguage.idExpositorBannerIdioma,
      }
    );
    return bannerExpositorLanguageToJson;
  }
}
