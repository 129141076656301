import React from "react";
import "./style.scss";

function CursoDescription({ title, description }) {
  return (
    <div className="container-card-description">
      <div className="curso-description">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default CursoDescription;
