import ModalMaisInfoSobreEvento from "components/expo-revestir/ModalMaisInfoSobreEvento";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EditionService from "services/edition.service";
import EventService from "services/evento.service";
import { AppContext } from "store";
import getDataLocale from "utils/map-data";
import QueryOptions from "utils/query-options";
import { Container, Row, Col } from "reactstrap";

export default function ListagemEventos() {
  const { idExpositor } = useContext(AppContext);
  const [eventos, setEventos] = useState([]);
  const { t, i18n } = useTranslation();
  const [modalAberto, setModalAberto] = useState(false);
  const [evento, setEvento] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      const _edicaoService = new EditionService();
      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const edicaoAtiva = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );

      const _eventosService = new EventService();
      const queryOptions = new QueryOptions([
        ["id_expositor", idExpositor],
        ["eventos_futuros", ""],
        ["edicao", edicaoAtiva.id],
      ]);
      const eventoResponse = await _eventosService.list(queryOptions);

      setEventos(eventoResponse);
      setLoading(false);
    }

    init();
  }, [i18n.language, idExpositor]);

  return (
    <>
      <div className="produto-loading-container">
        {loading && (
          <lottie-player
            src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px", margin: "0 auto" }}
            loop
            autoplay
          />
        )}
      </div>

      <ModalMaisInfoSobreEvento
        modalAberto={modalAberto}
        setModal={setModalAberto}
        evento={evento}
      />

      <Container hidden={loading}>
        <div className="eventos-expositor-header">
          <h1>{t("Eventos")}</h1>
        </div>

        {eventos.length ? (
          <Row style={{ paddingBottom: "5rem" }}>
            {eventos.map((evento) => (
              <Col xs="12" sm="6" md="6" lg="4" xl="3">
                <div
                  className="evento"
                  onClick={() => {
                    setModalAberto(!modalAberto);
                    setEvento(evento);
                  }}
                >
                  <div className="eventos-container">
                    <div className="data-evento-carrosel">
                      <span>{format(evento.dataInicio, "dd")}</span>
                      <span>
                        {format(evento.dataInicio, "MMM", {
                          locale: getDataLocale(),
                        })}
                      </span>
                    </div>

                    <div
                      className="evento-expositor"
                      style={{
                        width: "100%",
                        height: "259px",
                        backgroundImage: `url(${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display)`,
                        backgroundSize: "cover",
                      }}
                    />
                 

                  <div className="card-body">
                    <div
                      className="categorias-eventos"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        justifyContent: "flex-start",
                        alignContent: "center",
                        alignItems: "center",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "8px",
                          backgroundColor: "#F87FB4",
                          padding: "1px 5px",
                        }}
                      >
                        <p style={{ margin: "0", color: "#fff" }}>
                          {t("Evento expositor")}
                        </p>
                      </div>

                      <div className="plataforma-evento">
                        {evento.eventoExterno ? (
                          <div
                            style={{
                              backgroundColor: "#FE391F",
                              padding: "1px 5px",
                            }}
                          >
                            <p style={{ margin: "0", color: "#fff" }}>
                              {t("Evento externo")}
                            </p>
                          </div>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#93D501",
                              padding: "1px 5px",
                            }}
                          >
                            <p style={{ margin: "0", color: "#fff" }}>
                              {t("Plataforma Expo Revestir")}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="text-container">
                      <h3>{evento.titulo}</h3>
                      <p>{evento.descricao}</p>
                    </div>

                    <div className="card-footer-container">
                      <div className="evento-data">
                        <div className="evento-horas-data">
                          <p>
                            {format(evento.dataInicio, "H:mm")} -{" "}
                            {format(evento.dataFim, "H:mm")}
                          </p>
                        </div>{" "}
                        <div className="evento-calendario-data">
                          <p>{evento.dataFimStr}</p>
                        </div>{" "}
                      </div>{" "}
                      <div className="button-agenda-expositor">
                        <button className="mr-1 btn-card-event btn btn-info botao-card-evento">
                          {t("Ver evento")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="container-sem-eventos">
            <h2>{t("Esse expositor não possui eventos cadastrados")}</h2>
          </div>
        )}
      </Container>
    </>
  );
}
