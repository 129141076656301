import ProgressBar from "react-bootstrap/ProgressBar";
import "./progressBar.scss";

import CursoMonitoramentoService from "services/curso-monitoramento.service";
import IconCertificate from "../../../assets/img/expo-revestir/icons/icon-certificate.svg";

const CustomProgressBar = ({ curso }) => {
  const downloadCertificado = () => {
    const _cursoMonitoramentoService = new CursoMonitoramentoService();
    _cursoMonitoramentoService.downloadCertificadoCurso(
      curso.idCursoMonitoramento,
      curso.titulo
    );
  };

  return (
    <div className="container-progress-bar">
      <h3>Progresso</h3>
      <ProgressBar
        now={curso.progresso}
        label={`${curso.progresso}%`}
        visuallyHidden
        className="custom-progress-bar"
      />

      {curso.id !== 10 ? (
        <>
          {curso.certificado ? (
            <div
              className="certificado-curso click"
              onClick={downloadCertificado}
            >
              <img src={IconCertificate} alt="Download Certificado" />
              Download Certificado
            </div>
          ) : (
            <div className="certificado-curso">
              <img src={IconCertificate} alt="Download Certificado" />
              Conclua o curso para baixar seu certificado.
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default CustomProgressBar;
