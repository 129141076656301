import SelecionarIdioma from "components/SelecionarIdioma";
import TextInput from "components/TextInput";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Col, FormGroup, Row } from "reactstrap";
import Authentication from "services/authentication.service";
import UsuarioService from "services/usuario.service";
import * as Yup from "yup";
import "../../validations/customs.validation";
import ContainerVisitante from "./ContainerVisitante";

export default function MudarSenha() {
  const history = useHistory();
  const [hasError, setHasError] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [validationPass, setValidationPass] = useState({
    numero: false,
    letraMinuscula: false,
    letraMaiuscula: false,
    caracterEspecial: false,
    length: false,
  });

  const isChangePassword =
    Authentication._user && Authentication._user.trocarSenha;

  if (!isChangePassword) {
    history.push("/");
  }

  const usuarioService = new UsuarioService();

  function validPass(event) {
    const value = event.target.value;

    const numero = /^(?=.*\d)/.test(value);
    const letraMinuscula = /(?=.*[a-z])/.test(value);
    const letraMaiuscula = /(?=.*[A-Z])/.test(value);
    const caracterEspecial = /(?=.*[!@#$%<^&*?=])/.test(value);
    const length = value.length >= 6;

    setValidationPass({
      numero,
      letraMinuscula,
      letraMaiuscula,
      caracterEspecial,
      length,
    });
  }

  async function onSubmit(data) {
    setLoading(true);
    setHasError("");

    usuarioService
      .mudarSenha({
        senha: data.senha,
        conf_senha: data.conf_senha,
      })
      .then(() => {
        Authentication._user = {
          ...Authentication._user,
          trocarSenha: false,
        };
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <Formik
      initialValues={{
        senha: "",
        conf_senha: "",
      }}
      validationSchema={Yup.object({
        senha: Yup.string()
          .min(6, t("Mínimo 6 caracteres"))
          .password()
          .max(20, t("Máximo 20 caracteres"))
          .required(t("Obrigatório")),
        conf_senha: Yup.string()
          .required("Obrigatório")
          .test(
            "passwords-match",
            t("'Senha' e 'Confirme a senha' devem ser iguais"),
            function (value) {
              return this.parent.senha === value;
            }
          ),
      })}
      onSubmit={(values) => {
        setTimeout(() => {
          onSubmit(values);
        }, 400);
      }}
    >
      <div className="login_wrapper">
        <ContainerVisitante />

        <Col xl="6" lg="6" md="6" sm="12" xs="12">
          <Row className="row-right">
            <div className="login_text_form">
              <SelecionarIdioma />
              <div className="login_text">
                <h1>{t("Alterar Senha")}</h1>
                <p>{t("Digite uma nova senha antes de acessar o sistema.")}</p>
              </div>
              <Form className="form" autoComplete="off" style={{ zIndex: "1" }}>
                <Row style={{ flexDirection: "column" }}>
                  <Col sm="12">
                    <FormGroup>
                      <TextInput
                        name="senha"
                        placeholder="Senha"
                        type="password"
                        className="form-control"
                        onKeyUp={validPass}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <FormGroup>
                      <TextInput
                        name="conf_senha"
                        placeholder="Confirme a senha"
                        type="password"
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <button
                  loading={loading}
                  type="submit"
                  styles="w-all"
                  className="mr-1 btn-login"
                  style={{ padding: "0.75rem 5rem" }}
                >
                  {t("Atualizar senha")}
                </button>

                {hasError && <div className="error-menssage">{hasError}</div>}

                <div className="info-pass">
                  <div className="title-list">
                    <h6>{t("A senha deve conter:")}</h6>
                  </div>
                  <div div className="title-list">
                    <div className={validationPass.numero && "check-success"}>
                      <span className="check">
                        {!validationPass.numero && (
                          <span className="check-text">-</span>
                        )}
                        {validationPass.numero && (
                          <i class="nc-icon nc-check-2"></i>
                        )}
                      </span>
                      {t("Mínimo 1 número")}
                    </div>
                    <div
                      className={
                        validationPass.letraMinuscula && "check-success"
                      }
                    >
                      <span className="check">
                        {!validationPass.letraMinuscula && (
                          <span className="check-text">-</span>
                        )}
                        {validationPass.letraMinuscula && (
                          <i class="nc-icon nc-check-2"></i>
                        )}
                      </span>
                      {t("Mínimo 1 letra minúscula")}
                    </div>
                    <div
                      className={
                        validationPass.letraMaiuscula && "check-success"
                      }
                    >
                      <span className="check">
                        {!validationPass.letraMaiuscula && (
                          <span className="check-text">-</span>
                        )}
                        {validationPass.letraMaiuscula && (
                          <i class="nc-icon nc-check-2"></i>
                        )}
                      </span>
                      {t("Mínimo 1 letra maiúscula")}
                    </div>
                    <div
                      className={
                        validationPass.caracterEspecial && "check-success"
                      }
                      style={{ fontSize: 14 }}
                    >
                      <span className="check">
                        {!validationPass.caracterEspecial && (
                          <span className="check-text">-</span>
                        )}
                        {validationPass.caracterEspecial && (
                          <i class="nc-icon nc-check-2"></i>
                        )}
                      </span>
                      {t("Mínimo 1 caractere especial")} {"(!@#$%<^&*?=)"}
                    </div>
                    <div className={validationPass.length && "check-success"}>
                      <span className="check">
                        {!validationPass.length && (
                          <span className="check-text">-</span>
                        )}
                        {validationPass.length && (
                          <i class="nc-icon nc-check-2"></i>
                        )}
                      </span>
                      {t("Mínimo 6 caracteres")}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Row>
        </Col>
      </div>
    </Formik>
  );
}
