export default class ModalidadeExpositoresSerializer {
    fromJson(json) {
      const modalidadeExpositores = {};
  
      Object.assign(
        modalidadeExpositores,
        json.modalidade && { label: json.modalidade },
        json.id_expositor_modalidade && { value: json.id_expositor_modalidade }
      );
  
      return modalidadeExpositores;
    }
  }
  