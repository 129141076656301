import React, { useEffect, useState } from "react";
import PhoneTypeService from "../../services/phone-type.service";
import deleteIconColor from "../../assets/img/icons/deleteIconColor.svg";
import TextInput from "../TextInput";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { ErrorMessage } from "formik";
import unMask from "../../utils/unmask";

export const telefoneFields = {
  ddi: "",
  ddd: "",
  telefone: "",
  tipo: "",
  ramal: "",
};

export default function PhoneForm({
  form,
  index,
  parent,
  arrayHelpers,
  phone,
  obrigatorio,
  disabledForm = false,
}) {
  const [telefoneTipos, setTelefoneTipos] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    async function init() {
      if (!localStorage.getItem("token")) {
        const _telefoneTipoService = new PhoneTypeService();
        const data = await _telefoneTipoService.listarTelefoneTipoCadastro();
        setTelefoneTipos(data);
      } else {
        const _telefoneTipoService = new PhoneTypeService();
        const data = await _telefoneTipoService.list();
        setTelefoneTipos(data);
      }
    }

    init();
  }, []);

  return (
    <div className="telefone-form">
      <Col>{index > 0 && <hr />}</Col>
      <Row>
        <Col xs="12" sm="12" md="12" lg="12" xl="3">
          <FormGroup>
            <Label>
              {t("Tipo")}{" "}
              {obrigatorio && (
                <>
                  {" "}
                  <span className="required-hash">*</span>
                </>
              )}
            </Label>
            <Input
              type="select"
              name={`${parent}${index}.tipo`}
              value={form?.values?.telefones[index].tipo}
              onChange={(e) => {
                form.setFieldValue(`${parent}${index}.tipo`, e.target.value);
              }}
              className="input-modal-usuario-phone"
            >
              <option hidden selected value>
                {t("Tipo de telefone")}
              </option>

              {telefoneTipos.map(
                (tipo) =>
                  tipo.label && <option value={tipo.id}>{tipo?.label}</option>
              )}
            </Input>
            <ErrorMessage name={`${parent}${index}.tipo`}>
              {(msg) => <div className="error-input">{msg}</div>}
            </ErrorMessage>
          </FormGroup>
        </Col>

        <Col xs="12" sm="12" md="12" lg="12" xl="1">
          <FormGroup>
            <TextInput
              name={`${parent}${index}.ddi`}
              obrigatorio={obrigatorio}
              disabled={disabledForm}
              mask="999"
              label="DDI"
              className="input-modal-usuario-phone"
            />
          </FormGroup>
        </Col>

        <Col xs="12" sm="12" md="12" lg="12" xl="2">
          <FormGroup>
            <TextInput
              name={`${parent}${index}.ddd`}
              obrigatorio={unMask(form?.values?.telefones[index]?.ddi) === "55"}
              mask="99"
              disabled={disabledForm}
              label="DDD"
              className="input-modal-usuario-phone"
            />
          </FormGroup>
        </Col>
        
        <Col xs="12" sm="12" md="12" lg="12" xl="3">
          <FormGroup>
            <TextInput
              name={`${parent}${index}.telefone`}
              obrigatorio={obrigatorio}
              mask={
                form?.values?.telefones[index].tipo?.id === 1
                  ? "9999-9999"
                  : "99999-9999"
              }
              disabled={disabledForm}
              label={t("Telefone")}
              className="input-modal-usuario-phone"
            />
          </FormGroup>
        </Col>

        <Col xs="12" sm="12" md="12" lg="12" xl="2">
          <FormGroup>
            <TextInput
              label={t("Ramal")}
              name={`${parent}${index}.ramal`}
              disabled={disabledForm}
              className="input-modal-usuario-phone"
            />
          </FormGroup>
        </Col>

        {index > 0 ? (
          <div
            className="p-col-12 p-lg-1 p-md-1 p-sm-6 icon-input-spacedeb icone-lixeira-forms"
            style={{ paddingTop: 30 }}
          >
            <i className="trashIcon" onClick={() => arrayHelpers.remove(index)}>
              <img
                src={deleteIconColor}
                alt="Imagem de um lixeiro; clique nessa imagem para excluir o endereço"
              />
            </i>
          </div>
        ) : null}
      </Row>
    </div>
  );
}
