import { format } from "date-fns";
import BaseSerializer from "./base.serializer";
import CursoIdiomaSerializer from "./curso-idioma.serializer";
import InstrutorSerializer from "./instrutor-serializer";
import CursoModuloSerializer from "./curso-modulo-serializer";

export default class CursoSerializer extends BaseSerializer {
  constructor() {
    super();
    this.instrutorSerializer = new InstrutorSerializer();
    this.cursoModuloSerializer = new CursoModuloSerializer();
  }

  fromJson(json) {
    json = json.data || json;

    const curso = {
      id: json.id_curso || null,
      cargaHoraria: json.carga_horaria || 0,
      dataCriacao: new Date(json.data_criacao).toISOString() || null,
      disabled: json.disabled || false,
      expositor: json.expositor
        ? {
            id: json.expositor.id_expositor || null,
            nomeFantasia: json.expositor.nome_fantasia || null,
            uriUnico: json.expositor.uri_unico || null,
          }
        : null,
      porcentagemConclusao: json.porcentagem_conclusao || 0,
      modulos: json.modulos
        ? json.modulos.map((item) => this.cursoModuloSerializer.fromJson(item))
        : [],
      quantidadeAulas: json.quantidade_aulas,
      progresso: json.progresso_usuario,
      dataUltimaAtualizacao: format(
        new Date(json.data_ultima_atualizacao),
        "dd/MM/yyyy"
      ),
      instrutor: this.instrutorSerializer.fromJson(json.instrutor),
      certificado: json?.certificado,
      idCursoMonitoramento: json?.id_curso_monitoramento,
      destaque: json.hasOwnProperty("destaque") ? json.destaque : false,
    };

    const idiomas = this.idiomaAtual(json.idiomas, new CursoIdiomaSerializer());

    return { ...curso, ...idiomas };
  }
}
