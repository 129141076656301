// import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EditionService from "services/edition.service";
// import getDataLocale from "utils/map-data";

export default function ContainerVisitante() {
  const [edicaoAtiva, setEdicaoAtiva] = useState();
  const { i18n } = useTranslation();

  useEffect(() => {
    async function init() {
      const _editionService = new EditionService();
      const edicaoAtivaResponse = await _editionService.carregarEdicaoAtiva();

      setEdicaoAtiva(edicaoAtivaResponse);
    }

    init();
  }, [i18n.language]);

  return (
    <div className="container-visitante">
      <div className="bg-visitante">
        {edicaoAtiva && (
          <div className="container-info-visitante">
            {/* <p>
              {format(
                new Date(
                  new Date(edicaoAtiva?.data_inicio).valueOf() +
                    new Date(edicaoAtiva?.data_inicio).getTimezoneOffset() *
                      60 *
                      1000
                ),
                "d"
              )}
              –
              {format(
                new Date(
                  new Date(edicaoAtiva?.data_fim).valueOf() +
                    new Date(edicaoAtiva?.data_fim).getTimezoneOffset() *
                      60 *
                      1000
                ),
                "d"
              )}
              <br />
              <span className="mes-edicao">
                {format(new Date(edicaoAtiva?.data_inicio), "MMMM", {
                  locale: getDataLocale(),
                })}
              </span>
              <br />
              2023
            </p>
            <br />
            <p>
              SÃO <br /> PAULO <br />
              BR
            </p> */}
          </div>
        )}
      </div>
    </div>
  );
}
