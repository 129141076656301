import React from "react";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";

export default function LancamentoEdicao({ campoCustomizado }) {
  const history = useHistory();

  return (
    <div className="lancamento-edicao-container">
      <Container>
        <div className="lancamento-itens">
          <div className="texto">{campoCustomizado[10]?.item}</div>

          <div className="botao-container">
            <button
              onClick={() => {
                history.push("/expositores");
              }}
              type="button"
              className="btn-event-dark"
            >
              {campoCustomizado[11]?.item}
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
}
