/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import EditionService from "services/edition.service";
import VideoService from "services/video.service";
import { AppContext } from "store";
import QueryOptions from "utils/query-options";
import VideoEmbed from "utils/video-embed";
import "../../../../assets/scss/expo-revestir.scss";

export function ExpositorVideos() {
  const { idExpositor } = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [videoPrincipal, setVideoPrincipal] = useState();
  const [videos, setVideos] = useState([]);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      const _edicaoService = new EditionService();

      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const edicaoAtiva = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );
      const queryOptions = new QueryOptions([
        ["per_page", 1000],
        ["page", page],
        ["edicao", edicaoAtiva.id],
      ]);

      const _videoService = new VideoService();
      const data = await _videoService.listSub(idExpositor, queryOptions, true);

      if (page === 1 && data.items.length) {
        setVideoPrincipal(data.items.shift());
      }
      setVideos([...videos, ...data.items]);
      setLoading(false);
    }

    init();
  }, [idExpositor, page]);

  useEffect(() => {
    setVideos([]);
    setPage(1);
  }, [i18n.language]);

  return (
    <div>
      <div id="expositor-video" hidden={loading}>
        <div className="produto-loading-container">
          {loading && (
            <lottie-player
              src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px", margin: "0 auto" }}
              loop
              autoplay
            />
          )}
        </div>

        {videoPrincipal ? (
          <Container hidden={loading}>
            {videoPrincipal && (
              <div className="main">
                <div>
                  <h1 className="titulo">{videoPrincipal.titulo}</h1>
                  <div className="desc" style={{ padding: "30px 0 20px 0" }}>
                    {videoPrincipal.descricao}
                  </div>
                </div>
                <div className="box-video">
                  {<VideoEmbed url={videoPrincipal.link} />}
                </div>
              </div>
            )}

            {videos.map((video) => (
              <div key={video.id} className="video">
                <div className="desc">
                  <h2 className="titulo">{video.titulo}</h2>
                  <div>{video.descricao}</div>
                </div>
                <div className="box-video">
                  {<VideoEmbed url={video.link} />}
                </div>
              </div>
            ))}
          </Container>
        ) : (
          <div className="container-sem-videos">
            <h2>{t("Esse expositor não possui vídeos cadastrados")}</h2>
          </div>
        )}
      </div>
    </div>
  );
}
