/* eslint-disable react-hooks/exhaustive-deps */
import CarregarImagem from "components/CarregarImagem";
import React, { useContext, useEffect, useState } from "react";
import ReactElasticCarousel from "react-elastic-carousel";
import { useTranslation } from "react-i18next";
import "react-slideshow-image/dist/styles.css";
import BannerExpositorService from "services/banner-expositor.service";
import EditionService from "services/edition.service";
import { AppContext } from "store";
import useWindowDimensions from "utils/verificar-dimensao-da-tela";
import QueryOptions from "../../utils/query-options";

export default function BannerExpositor({ loading, setLoading }) {
  const { idExpositor } = useContext(AppContext);
  const [bannersExpositorDesktop, setBannersExpositorDesktop] = useState([]);
  const [bannersExpositorMobile, setBannersExpositorMobile] = useState([]);

  const { i18n } = useTranslation();
  const [time] = useState(new Date().getTime());
  const { width } = useWindowDimensions();

  useEffect(() => {
    async function init() {
      const _edicaoService = new EditionService();
      const _bannerExpositorService = new BannerExpositorService();

      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const edicaoAtiva = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );

      const queryOptions = new QueryOptions([["edicao", edicaoAtiva.id]]);

      const bannerExpositorResponse = await _bannerExpositorService.listSub(
        idExpositor,
        queryOptions,
        true
      );

      const bannersExpositorValidosDesktop =
        bannerExpositorResponse.items.filter((item) => item.imageFilename);
      setBannersExpositorDesktop(bannersExpositorValidosDesktop);

      const bannersExpositorValidosMobile =
        bannerExpositorResponse.items.filter(
          (item) => item.imageFilenameMobile
        );
      setBannersExpositorMobile(bannersExpositorValidosMobile);

      setLoading(false);
    }
    init();
  }, [i18n.language, idExpositor]);

  function checarBannerClicavel(banner) {
    if (banner.link) {
      window.open(banner.link, "_blank");
    }
  }

  return (
    <div
      hidden={
        bannersExpositorDesktop?.length <= 0 &&
        bannersExpositorMobile?.length <= 0
      }
    >
      {width <= 768 ? (
        <div hidden={bannersExpositorMobile?.length <= 0}>
          <ReactElasticCarousel
            enableAutoPlay
            autoPlaySpeed={3000}
            showArrows={false}
            style={{ marginBottom: "5rem" }}
          >
            {bannersExpositorMobile?.map((banner) => (
              <div
                key={banner.id}
                className="each-slide"
                style={banner.link && { cursor: "pointer" }}
                onClick={() => {
                  checarBannerClicavel(banner);
                }}
              >
                <div className="container-mobile-img">
                  <CarregarImagem
                    alt="banner do expositor"
                    src={`${process.env.REACT_APP_API_URL}expositores/banners/${banner?.idExpositorBannerIdioma}/display?
                   ${time}&mobile=true`}
                    className="imagem-banners"
                  />
                </div>
              </div>
            ))}
          </ReactElasticCarousel>
        </div>
      ) : (
        <div hidden={bannersExpositorDesktop?.length <= 0}>
          <ReactElasticCarousel
            enableAutoPlay
            autoPlaySpeed={3000}
            showArrows={false}
            style={{ marginBottom: "5rem" }}
          >
            {bannersExpositorDesktop?.map((banner) => (
              <div
                key={banner.id}
                className="each-slide"
                style={banner.link && { cursor: "pointer" }}
                onClick={() => {
                  checarBannerClicavel(banner);
                }}
              >
                <CarregarImagem
                  alt="banner do expositor"
                  src={`${process.env.REACT_APP_API_URL}expositores/banners/${banner?.idExpositorBannerIdioma}/display?
 ${time}`}
                  className="imagem-banners"
                />
              </div>
            ))}
          </ReactElasticCarousel>
        </div>
      )}
    </div>
  );
}
