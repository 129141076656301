import BaseSerializer from "./base.serializer";
import CampoCustomizadoItemIdiomaSerializer from "./campo-customizado-item-idioma.serializer";

export default class CampoCustomizadoItemSerializer extends BaseSerializer {
  fromJson(json) {
    const campoCustomizadoItemFromJson = {};

    Object.assign(
      campoCustomizadoItemFromJson,
      json.id_campo_customizado && {
        idCampoCustomizado: json.id_campo_customizado,
      },
      json.id_campo_item_customizado && {
        idCampoItemCustomizado: json.id_campo_item_customizado,
      },
      json.idiomas && {
        ...this.idiomaAtual(
          json.idiomas,
          new CampoCustomizadoItemIdiomaSerializer()
        ),
      }
    );

    return campoCustomizadoItemFromJson;
  }

  toJson(campoCustomizado) {
    const campoCustomizadoToJson = {};

    Object.assign(
      campoCustomizadoToJson,
      campoCustomizado.idiomas && {
        idiomas: campoCustomizado.idiomas.map((item) =>
          this.campoCustomizadoIdiomasSerializer.toJson(item)
        ),
      }
    );

    return campoCustomizadoToJson;
  }
}
