import axios from "axios";
import { AES, enc } from "crypto-js";
import Authentication from "./authentication.service";
import history from "./history";

const production = process.env.REACT_APP_PRODUCTION;
const encryptKey = process.env.REACT_APP_ENCRYPT_KEY;
const url = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: url,
});

api.interceptors.request.use(async (config) => {
  if (config.url.endsWith("tokens")) {
    return config;
  }

  const token = Authentication.getToken();
  const strTokenExpiration = Authentication.getTokenExpiration();

  if (!strTokenExpiration || !token) {
    return config;
  }

  const tokenExpiration = new Date(strTokenExpiration);

  if (new Date().getTime() > tokenExpiration.getTime()) {
    return config;
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  let data = null;

  if (config.data) {
    if (parseInt(production) >= 1) {
      data = AES.encrypt(JSON.stringify(config.data), encryptKey).toString();
    } else {
      data = config.data;
    }
  }

  config.data = data;

  return config;
});

api.interceptors.response.use(
  (response) => {
    if (parseInt(production) >= 1 && response.data) {
      const { oret } = response.data;
      const bytes = AES.decrypt(oret.substring(2, oret.length - 1), encryptKey);
      const strObject = bytes.toString(enc.Utf8);
      const data = JSON.parse(strObject);

      response.data = data;
    }

    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      Authentication.logout();
      history.push("/entrar");
      const requestConfig = error.config;
      return axios(requestConfig);
    }
    return Promise.reject(error);
  }
);

export default api;
