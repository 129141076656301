/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import CatalogoService from "services/catalogo.service";
import EditionService from "services/edition.service";
import { AppContext } from "store";
import { getLocaleId } from "utils/map-data";
import QueryOptions from "utils/query-options";
import "../../../../assets/scss/expo-revestir.scss";

export function ExpositorCatalogos() {
  const { idExpositor } = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [catalogos, setCatalogos] = useState([]);
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    async function init() {
      const _edicaoService = new EditionService();

      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const edicaoAtiva = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );

      const queryOptions = new QueryOptions([
        ["per_page", 1000],
        ["page", page],
        ["idioma", getLocaleId()],
        ["edicao", edicaoAtiva.id],
      ]);

      const _catalogoService = new CatalogoService();
      const data = await _catalogoService.listSub(
        idExpositor,
        queryOptions,
        true
      );

      setCatalogos([...catalogos, ...data.items]);
      setLoading(false);
    }

    init();
  }, [idExpositor, page]);

  useEffect(() => {
    setCatalogos([]);
    setPage(1);
  }, [i18n.language]);

  function onDownload(catalogo) {
    window.open(
      `${process.env.REACT_APP_API_URL}catalogos/${catalogo}/arquivos/display`,
      "_blank"
    );
  }

  return (
    <div>
      <div className="produto-loading-container">
        {loading && (
          <lottie-player
            src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px", margin: "0 auto" }}
            loop
            autoplay
          />
        )}
      </div>

      <div id="expositor-catalogos" hidden={loading}>
        <Container>
          <h1>{t("Catálogos")}</h1>
          <div className="catalogos">
            {catalogos.length ? (
              <>
                {catalogos.map((catalogo) => (
                  <div
                    key={catalogo.id}
                    className="catalogo"
                    onClick={() => onDownload(catalogo.id)}
                  >
                    <div className="image-card">
                      <img
                        src={`${process.env.REACT_APP_API_URL}catalogos/${catalogo.id}/imagens/display`}
                        alt={`Capa ${catalogo.titulo}`}
                      />
                    </div>
                    <p>{catalogo.titulo}</p>
                  </div>
                ))}
              </>
            ) : (
              <div>
                <h2>{t("Esse expositor não possui catálogos cadastrados")}</h2>
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
}
