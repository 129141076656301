import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router";
import ExpositoresClicadosService from "services/expositores-clicados.service";
import QueryOptions from "utils/query-options";

export default function ExpositoresVisitados() {
  const [ultimosExpositoresVisitados, setUltimosExpositoresVisitados] =
    useState([]);
  const [time] = useState(new Date().getTime());
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    async function init() {
      const _expositoresVisitadosService = new ExpositoresClicadosService([]);
      const queryOptions = new QueryOptions([["quantidade", 12]]);
      const expositoresClicadosResponse =
        await _expositoresVisitadosService.listarUltimosVisitados(queryOptions);

      setUltimosExpositoresVisitados(expositoresClicadosResponse);
    }

    init();
  }, []);

  return (
    <div className="ultimos-visitados-container-background py-3">
      <Container>
          <h1>{t("Últimos visitados")}</h1>

        {ultimosExpositoresVisitados?.length ? (
          <Row>
            {ultimosExpositoresVisitados.map((item) => (
              <Col xs="6" sm="3" md="2" lg="2" xl="1">
                <div
                  className="expositores-clicados-card"
                  onClick={() =>
                    history.push(`/expositor/${item?.uriUnico}/produtos`)
                  }
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL}expositores/${item?.id}/logotipos/display?${time}`}
                    alt={item?.nomeFantasia}
                    title={item?.nomeFantasia}
                  />
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <h5>{t("Você ainda não visitou nenhum expositor")}</h5>
        )}
      </Container>
    </div>
  );
}
