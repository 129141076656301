/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";
import { Col, Container, Row } from "reactstrap";
import EditionService from "services/edition.service";
import ProductService from "services/products.service";
import { AppContext } from "store";
import QueryOptions from "utils/query-options";
import SetaSubir from "../../../../assets/img/expo-revestir/icons/up-icon.svg";
import ProdutoItem from "./ProdutoItem";

export default function ListagemProdutos() {
  const { idExpositor } = useContext(AppContext);
  const [produtoListagem, setProdutoListagem] = useState([]);
  const [meta, setMeta] = useState([]);
  const [perPage, setPerPage] = useState(9);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      const _edicaoService = new EditionService();

      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const edicaoAtiva = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );

      const queryOptions = new QueryOptions([
        ["per_page", perPage],
        ["page", 1],
        ["edicao", edicaoAtiva.id],
      ]);

      const _produtosService = new ProductService();
      const produtosResponse = await _produtosService.listSub(
        idExpositor,
        queryOptions,
        true
      );

      setProdutoListagem(produtosResponse.items);
      setMeta(produtosResponse.meta);
      setLoading(false);
    }

    init();
  }, [idExpositor, perPage, i18n.language]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setPerPage((currentPageInside) => currentPageInside + 9);
      }
    });

    intersectionObserver.observe(document.querySelector("#sentinela-produtos"));

    return () => intersectionObserver.disconnect();
  }, []);

  return (
    <>
      <div hidden={loading}>
        {produtoListagem.length ? (
          <>
            <Container>
              <Row className="container-produto">
                {produtoListagem.map((produto) => (
                  <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="4"
                    key={produto.id}
                    className="produto-col"
                  >
                    <ProdutoItem produto={produto} idExpositor={idExpositor} />
                  </Col>
                ))}
              </Row>

              <div className="mostrando-container">
                <h2>
                  {t("Mostrando")} {produtoListagem.length} {t("de")}{" "}
                  {meta.totalItems}
                </h2>
              </div>
            </Container>

            {perPage > 9 && (
              <div id="subir-button">
                <img
                  src={SetaSubir}
                  onClick={() => scroll.scrollTo(100)}
                  alt="botao para subir para o topo"
                />
              </div>
            )}
          </>
        ) : (
          <div className="container-sem-produtos">
            <h2>{t("Esse expositor não possui produtos cadastrados")}</h2>
          </div>
        )}
      </div>
      <div
        id="sentinela-produtos"
        style={loading ? { marginTop: "1200px" } : { marginTop: "0" }}
      />
    </>
  );
}
