import React from "react";
import EventosDoExpositor from "./EventosDoExpositor";

export default function EventosTela() {
  return (
    <>
      <EventosDoExpositor />
    </>
  );
}
