import VideoCard from "components/CardVideosModulos";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { converterSegundosParaHoras } from "utils/converter-seguntos-para-horas";
import arrowOpen from "../../assets/img/icons/arrowaberto.png";
import arrowClose from "../../assets/img/icons/arrowfechado.png";
import "./style.scss";

export default function AccordionModulos({ modulos }) {
  const [openAccordion, setOpenAccordion] = useState(null);
  const { t } = useTranslation();

  const toggleAccordion = async (moduleId) => {
    setOpenAccordion(openAccordion === moduleId ? null : moduleId);
  };

  return (
    <div className="container-acorddion">
      <h3>{t("Aulas")}</h3>
      {modulos.map((modulo, index) => (
        <div key={modulo.nome} className="accordion-item">
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(modulo.idCursoModulo)}
          >
            <div className="module-info">
              <div className="module-name">
                Módulo {(index + 1).toString().padStart(2, 0)} - {modulo.nome}
              </div>
              <div className="accordion-icon">
                {openAccordion === modulo.idCursoModulo ? (
                  <img className="arrow" src={arrowOpen} alt={t("Aberto")} />
                ) : (
                  <img className="arrow" src={arrowClose} alt={t("Fechado")} />
                )}
              </div>
            </div>
          </div>
          {openAccordion === modulo.idCursoModulo && (
            <div className="accordion-content">
              {modulo.aulas.map((aula, index) => (
                <VideoCard
                  key={aula.descricao}
                  idCurso={aula.idCursoAula}
                  idAula={aula.idCursoAulaIdioma}
                  title={aula.titulo}
                  duracao={converterSegundosParaHoras(aula.duracao)}
                  contagem={index}
                  idCursoAulaIdioma={aula.idCursoAulaIdioma}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
