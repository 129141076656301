import React, { useRef, useState, useEffect } from "react";

import "./styles.scss";

export default function ImageeInputPreview(props) {
  const [image, setImage] = useState("");
  const fileInput = useRef(null);
  const viewImage = useRef(null);

  useEffect(() => {
    if (props.image) {
      setImage(props.image);
    }
  }, [props.image]);

  function handleFileChange(event) {
    const files = event.target.files;
    if (files.length) {
      const file = event.target.files[0];
      setImage(URL.createObjectURL(file));
      props.changeImage(file);
    }
  }

  return (
    <div className="image-input-preview">
      <div className="photo">
        <img
          src={image}
          className="image-profile"
          alt="Perfil"
          ref={viewImage}
        ></img>
        <div className="icon" onClick={(e) => fileInput.current.click()}>
          {/* <img src={require("../../assets/images/gallery-add.svg")} alt="Icone câmera"></img> */}
        </div>

        <input
          id="files"
          type="file"
          accept="image/*"
          ref={fileInput}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}
