import React from "react";
import ListagemEventos from "./ListagemEventos";

export default function ExpositorEventos() {
  return (
    <div>
      <ListagemEventos />
    </div>
  );
}
