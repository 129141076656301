import BannersPublicidade from "components/expo-revestir/BannersPublicidade";
import ExpositoresFavoritos from "./ExpositoresFavoritos";
import ExpositoresVisitados from "./ExpositoresVisitados";
import InfoUsuario from "./InfoUsuario";
import ListagemComprovante from "./ListagemCompravante";
import ProdutosFavoritos from "./ProdutosFavoritos";

export default function PerfilUsuario() {
  return (
    <div>
      <BannersPublicidade idAreaPublicidade={4} bannerHome={false} />

      <InfoUsuario />

      <div>
        <ExpositoresVisitados />
        <ListagemComprovante />
        <ExpositoresFavoritos />
        <ProdutosFavoritos />
      </div>
    </div>
  );
}
