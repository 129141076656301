export default class SegmentosTypeSerializer {
  fromJson(json) {
    const segmentosType = {};

    Object.assign(
      segmentosType,
      json.id_segmento && { value: json.id_segmento },
      json.segmento_idiomas && { label: json.segmento_idiomas[0].nome }
    );

    return segmentosType;
  }
}
