import favoritarProdutoPreenchido from "assets/img/expo-revestir/icons/filled-heart-icons.svg";
import favoritarProduto from "assets/img/expo-revestir/icons/heart-icon.svg";
import React, { useContext, useEffect, useState } from "react";
import ReactElasticCarousel from "react-elastic-carousel";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Col, Container, Row } from "reactstrap";
import ProductService from "services/products.service";
import UsuarioService from "services/usuario.service";
import { AppContext } from "store";
import ProductsImagesService from "../../../../../services/products-images.service";
import Share from "../../../../../assets/img/expo-revestir/icons/share.svg";
import copyClipboard from "utils/copy-clipboard";
import EditionService from "services/edition.service";
import QueryOptions from "utils/query-options";
import setaEsquerda from "../../../../../assets/img/expo-revestir/icons/arrow-left-produtoCard.svg";
import setaDireita from "../../../../../assets/img/expo-revestir/icons/arrow-right-produtoCard.svg";
import ModalVerProdutoExpositor from "components/expo-revestir/ModalVerProdutoExpositor";

export default function ProdutoCard() {
  const { idExpositor, expositorUriUnico } = useContext(AppContext);
  const { user, setUser } = useContext(AppContext);
  const { id } = useParams();
  const [produtoImagem, setProdutoImagem] = useState();
  const [produtoInfo, setProdutoInfo] = useState();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [produtoAtualCarrosel, setProdutoAtualCarrosel] = useState({});
  const [verificarImagemArrow, setVerificarImagemArrow] = useState();
  const [copiarLink, setCopiarLink] = useState(false);
  const [listagemProdutos, setListagemProduto] = useState();
  const [proximoProduto, setProximoProduto] = useState();
  const [produtoAnterior, setProdutoAnterior] = useState();
  const [carregandoProdutos, setCarregandoProdutos] = useState(true);
  const [modalProdutoAberto, setModalProdutoAberto] = useState(false);

  const _usuarioService = new UsuarioService();

  useEffect(() => {
    async function init() {
      setCarregandoProdutos(true);

      const _productsImagesService = new ProductsImagesService();
      const response = await _productsImagesService.listSub(id);
      setProdutoImagem(response);

      if (response.length === 1) {
        setVerificarImagemArrow(false);
      } else if (response.length >= 2) {
        setVerificarImagemArrow(true);
      }

      const _produtoService = new ProductService();
      const responseProduto = await _produtoService.read(id);
      setProdutoInfo(responseProduto);
      setProdutoAtualCarrosel(responseProduto.imagens[0]);

      const _edicaoService = new EditionService();

      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const edicaoAtiva = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );

      const queryOptions = new QueryOptions([
        ["page", 1],
        ["edicao", edicaoAtiva.id],
      ]);

      const _produtosService = new ProductService();
      const produtosResponse = await _produtosService.listSub(
        idExpositor,
        queryOptions,
        true
      );

      setListagemProduto(produtosResponse.items);
      encontrarPosicaoProdutos(produtosResponse.items);
      setCarregandoProdutos(false);
    }

    function encontrarPosicaoProdutos(produtoListagem) {
      function encontrarPosicaoProdutoAtual(element) {
        return element.id === parseInt(id);
      }

      const posicaoAtualDoProduto = produtoListagem?.findIndex(
        encontrarPosicaoProdutoAtual
      );

      setProximoProduto(produtoListagem[posicaoAtualDoProduto + 1]);
      setProdutoAnterior(produtoListagem[posicaoAtualDoProduto - 1]);
    }

    init();
  }, [id, i18n.language, idExpositor]);

  async function onFavoritarProduto() {
    _usuarioService.favoritar({ id_produto: +id }).then((response) => {
      const aux = { ...user };

      if (response.favoritado) {
        const favorito = {
          idProduto: +id,
          idExpositor: idExpositor,
          expositorUriUnico,
        }

        if (produtoImagem?.[0]?.id) {
          favorito.imagens = [{id_produto_imagem: produtoImagem[0].id}]
        }

        aux.produtosFavoritos.push(favorito);
      } else {
        aux.produtosFavoritos = aux.produtosFavoritos.filter(
          (item) => item.idProduto !== +id
        );
      }

      setUser(aux);
    });
  }

  function verificaFavoritoProduto() {
    if (user && user.hasOwnProperty("produtosFavoritos")) {
      return user.produtosFavoritos.find((item) => item.idProduto === +id);
    }
  }

  function copiarUrl() {
    copyClipboard(window.location.href);
    setCopiarLink(true);
    setTimeout(() => {
      setCopiarLink(false);
    }, 5000);
  }

  console.log(verificaFavoritoProduto())

  return (
    <>
      <div className="produto-loading-container">
        {carregandoProdutos && (
          <lottie-player
            src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "900px", margin: "0 auto" }}
            loop
            autoplay
          />
        )}
      </div>

      <Container className="produto-individual-container">
        <ModalVerProdutoExpositor
          modalProdutoAberto={modalProdutoAberto}
          setModalProdutoAberto={setModalProdutoAberto}
          imagens={produtoImagem}
          verificarImagemArrow={verificarImagemArrow}
        />

        <div className="voltar-galeria">
          <p onClick={() => history.push(`/expositor/${idExpositor}/produtos`)}>
            {t("Voltar para galeria")}
          </p>
        </div>

        <Row>
          <Col xs="12" sm="12" md="12" lg="6" xl="6">
            <ReactElasticCarousel
              showArrows={verificarImagemArrow ? true : false}
              onChange={(currentItem) => {
                setProdutoAtualCarrosel(currentItem.item.produtoAtual);
              }}
            >
              {produtoImagem?.map((imagem) => (
                <div
                  className="each-slide modal-ver-produto-imagem-slide"
                  key={imagem.id}
                  produtoAtual={imagem}
                  onClick={() => {
                    setModalProdutoAberto(true);
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL}produto-imagens/${
                      imagem.id
                    }/display?${new Date().getTime()}`}
                    alt={produtoInfo?.nome}
                    className="imagem-banners"
                  />
                </div>
              ))}
            </ReactElasticCarousel>
          </Col>

          <Col xs="12" sm="12" md="12" lg="6" xl="6">
            <Row className="h-100 align-content-between">
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="informacao-produto-header"
              >
                <Row className="titulo-e-favoritar-produto-container">
                  <Col xs="8" sm="8" md="8" lg="8" xl="10">
                    <div className="titulo-produto-container">
                      <h1>{produtoInfo?.nome}</h1>
                    </div>
                  </Col>

                  <Col
                    xs="2"
                    sm="2"
                    md="2"
                    lg="2"
                    xl="1"
                    onClick={onFavoritarProduto}
                    className="favoritar-coracao-col"
                  >
                    <img
                      className="favoritar-coracao-imagem"
                      src={
                        verificaFavoritoProduto()
                          ? favoritarProdutoPreenchido
                          : favoritarProduto
                      }
                      alt="favoritar produto"
                    />
                  </Col>

                  <Col xs="2" sm="2" md="2" lg="2" xl="1">
                    <div title={t("Compartilhar")} onClick={copiarUrl}>
                      <img
                        src={Share}
                        alt="ícone do compartilhar"
                        className="favoritar-pointer-produto"
                      />
                      {copiarLink && (
                        <span className="aviso-link-copiado-produto">
                          {t("Link copiado")}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>

                <p>{produtoInfo?.descricao}</p>

                <div className="informacao-secundaria-container">
                  {produtoInfo?.idsProdutoTipo.length ? (
                    <h5>
                      <span>{t("Tipologia")}: </span>
                      {produtoInfo?.idsProdutoTipo?.map((tipologia, index) => (
                        <>
                          {tipologia.nome}
                          {produtoInfo?.idsProdutoTipo?.length - 1 !== index &&
                            ", "}
                        </>
                      ))}
                    </h5>
                  ) : null}

                  {produtoInfo?.dimensoesProduto && (
                    <h5>
                      <span>{t("Dimensão")}:</span>{" "}
                      {produtoInfo?.dimensoesProduto}
                    </h5>
                  )}

                  {produtoInfo?.codigoProduto && (
                    <h5>
                      <span>
                        {t("Código")}: {""}
                      </span>
                      {produtoInfo?.codigoProduto}
                    </h5>
                  )}

                  {produtoInfo?.linkProduto && (
                    <>
                      <span className="ver-produto-link-container">
                        <button>
                          <a
                            href={produtoInfo?.linkProduto}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>{t("Ver produto no site do expositor")}</span>
                            <span>
                              <img
                                src={setaDireita}
                                alt="Seta direita"
                                id="btn-proximo"
                              />
                            </span>
                          </a>
                        </button>
                      </span>
                    </>
                  )}
                </div>
              </Col>

              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="informacao-imagem-container"
              >
                <h5>{produtoAtualCarrosel?.nome}</h5>
                <p>{produtoAtualCarrosel?.descricao}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="btn-anterior-proximo-container">
              <div>
                {listagemProdutos?.[0]?.id !== parseInt(id) && (
                  <button
                    onClick={() => {
                      history.push(`${produtoAnterior?.id}`);
                    }}
                    className="btn-produto-card"
                  >
                    <span>
                      <img
                        src={setaEsquerda}
                        id="btn-anterior"
                        alt="Seta esquerda"
                      />
                    </span>
                    <span>{t("Anterior")}</span>
                  </button>
                )}
              </div>

              <div>
                {listagemProdutos?.[listagemProdutos?.length - 1]?.id !==
                  parseInt(id) && (
                  <button
                    onClick={() => {
                      history.push(`${proximoProduto?.id}`);
                    }}
                    className="btn-produto-card"
                  >
                    <span>{t("Próximo")}</span>
                    <span>
                      <img
                        src={setaDireita}
                        alt="Seta direita"
                        id="btn-proximo"
                      />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
