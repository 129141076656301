import QueryOptions from "utils/query-options";
import api from "./api.service";
import EditionService from "./edition.service";
import UsuarioSerializer from "./serializers/usuario.serializer";

export const TOKEN_KEY = "token";
export const TOKEN_EXPIRATION = "tokenExpiration";
export const USER_LOGGED = "userLogged";
const LOGGED_USER_URL = "logged-user";

export default class Authentication {
  constructor() {
    this.usuario = null;
    this.usuarioSerializer = new UsuarioSerializer();
  }

  static get _user() {
    return this.usuario;
  }

  static set _user(value) {
    this.usuario = value;
  }

  static getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  async singIn(email, password) {
    const res = await api.post(
      "tokens",
      {},
      {
        headers: {
          Authorization: "Basic " + btoa(`${email}:${password}`),
        },
      }
    );

    localStorage.setItem(TOKEN_KEY, res.data.token);
    localStorage.setItem(TOKEN_EXPIRATION, res.data.token_expiration);

    Authentication._user = this.usuarioSerializer.fromJson(res.data.usuario);
    await this.listarFavoritos();
  }

  async listarFavoritos() {
    const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
    const _edicaoService = new EditionService();

    const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
      queryOptionsEdicaoAtiva
    );

    const response = await api.get(
      `usuarios/favoritos/edicao/${edicaoAtual.id}?`
    );

    const data = this.usuarioSerializer.fromJson(response.data);

    let usuario = { ...Authentication._user };
    if (data.expositoresFavoritos.length) {
      usuario.expositoresFavoritos = data.expositoresFavoritos.map((item) => {
        return {
          id: item.id,
          nomeFantasia: item.nomeFantasia,
          uriUnico: item.uriUnico,
        };
      });
    } else {
      usuario.expositoresFavoritos = [];
    }

    if (data.produtosFavoritos.length) {
      usuario.produtosFavoritos = data.produtosFavoritos.map((item) => {
        return {
          idProduto: item.id,
          idExpositor: item.idExpositor,
          expositorUriUnico: item.expositorUriUnico,
          imagens: item.imagens,
        };
      });
    } else {
      usuario.produtosFavoritos = [];
    }

    Authentication._user = { ...usuario };
  }

  static isAuthenticated() {
    const token = this.getToken();
    const strTokenExpiration = this.getTokenExpiration();
    if (!strTokenExpiration || !token) {
      this.logout();
      return false;
    }

    const tokenExpiration = new Date(strTokenExpiration);

    if (new Date().getTime() > tokenExpiration.getTime()) {
      this.logout();
      return false;
    }

    return true;
  }

  static logout() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_EXPIRATION);
    this._user = null;
  }
  static userLogged() {
    return localStorage.getItem(USER_LOGGED);
  }

  static getTokenExpiration() {
    return localStorage.getItem(TOKEN_EXPIRATION);
  }

  async retrieveLoggedUser() {
    const response = await api.get(LOGGED_USER_URL);
    Authentication._user = this.usuarioSerializer.fromJson(response.data);
    await this.listarFavoritos();
    return Authentication._user;
  }
}
