/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";
import { useHistory } from "react-router";
import { animateScroll as scroll } from "react-scroll";
import { Col, Input, InputGroup, Row } from "reactstrap";
import EditionService from "services/edition.service";
import ExpositoresService from "services/expositores.service";
import SegmentoService from "services/segmento.service";
import { userHasAccess } from "utils/permission-user";
import { userProfiles } from "utils/permission-user";
import QueryOptions from "utils/query-options";
import ProductTypeService from "../../services/product-type.service";
import SetaParaCima from "../../assets/img/expo-revestir/icons/arrow-black.svg";

export default function ExpositoresListagem() {
  const [time] = useState(new Date().getTime());
  const [busca, setBusca] = useState("");
  const [selectedValueProduto, setSelectedValueProduto] = useState([]);
  const [selectedValueSegmento, setSelectedValueSegmento] = useState([]);
  const [produtoListagem, setProdutoListagem] = useState([]);
  const [segmentoListagem, setSegmentoListagem] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState([]);
  const [showBtnMostrarMais, setShowBtnMostrarMais] = useState(true);
  const [showLimparFiltragemBtn, setShowLimparFiltragemBtn] = useState(false);
  const [total, setTotal] = useState(0);
  const history = useHistory();
  const [expositores, setExpositores] = useState();
  const [showLetra, setShowLetra] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingFiltragem, setLoadingFiltragem] = useState();
  const [expositoresTotal, setExpositoresTotal] = useState();
  const [carregandoMaisExpositores, setCarregandoMaisExpositores] =
    useState(false);
  const [limpandoFiltros, setLimpandoFiltros] = useState(false);

  const maxItens = 3;

  const { t, i18n } = useTranslation();

  const ALFABETO = [
    "#",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  function primeiraLetra(texto = "") {
    if (texto) {
      for (let i = 0; i < texto.length; i++) {
        if (!Number.isInteger(parseInt(texto[i]))) {
          return texto[i];
        }
      }
    }

    return texto;
  }

  const _expositoresService = new ExpositoresService();

  useEffect(() => {
    async function init() {
      if (limpandoFiltros) {
        setLimpandoFiltros(false);
      }

      if (page === 1) {
        setLoadingFiltragem(true);
      } else {
        setCarregandoMaisExpositores(true);
      }

      const _edicaoService = new EditionService();
      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const edicaoAtiva = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );

      const queryOptions = new QueryOptions([
        ["nome_fantasia", busca],
        ["page", page],
        ["fields", "nome_fantasia, uri_unico, id_expositor"],
        ["edicao", edicaoAtiva.id],
        ["ativos", true],
      ]);

      if (selectedValueProduto.length) {
        queryOptions.queryKvs.push(["produto_tipo", selectedValueProduto]);

        if (selectedValueProduto.length > 0) {
          setShowLimparFiltragemBtn(true);
        }

        if (showBtnMostrarMais) {
          onMostrarTudo();
        }
      }

      if (selectedValueProduto.length === 0) {
        setShowLimparFiltragemBtn(false);
      }

      if (selectedValueSegmento.length) {
        queryOptions.queryKvs.push(["segmento", selectedValueSegmento]);

        if (selectedValueSegmento.length > 0) {
          setShowLimparFiltragemBtn(true);
        }

        if (showBtnMostrarMais) {
          onMostrarTudo();
        }
      }

      if (busca.length > 0) {
        setShowLimparFiltragemBtn(true);
      }

      const expositoresResponse = await _expositoresService.list(
        queryOptions,
        true
      );

      setExpositoresTotal(expositoresResponse.items.length);

      let max = false;
      const expositoresAux = {};
      let quantidade = 0;
      let letra = "a";

      ALFABETO.forEach((item) => {
        const filtro = expositoresResponse.items.filter(
          (expositor) =>
            primeiraLetra(expositor.nomeFantasia).toLowerCase() === item
        );

        quantidade += filtro.length;

        if (quantidade >= maxItens && !showLetra && !max) {
          max = true;
          setShowLetra(item);
          letra = item;
        }

        if (filtro.length) {
          expositoresAux[item] = filtro;
        }
      });

      if (userHasAccess([userProfiles.expositor])) {
        max = true;
        setShowLetra("z");
        letra = "z";
      }

      if (page > 1) {
        setExpositores({ ...expositores, ...expositoresAux });
      } else {
        setExpositores(expositoresAux);
      }

      setMeta(expositoresResponse.meta);

      totalItensTela(expositoresAux, letra);
      setLoadingFiltragem(false);
      setCarregandoMaisExpositores(false);
      setLoading(false);
    }

    init();
  }, [selectedValueProduto, selectedValueSegmento, busca, page]);

  useEffect(() => {
    async function base() {
      const _produtoService = new ProductTypeService();
      const produtoResponse = await _produtoService.list();
      setProdutoListagem(produtoResponse);

      const _segmentoService = new SegmentoService();
      const segmentoResponse = await _segmentoService.list();
      setSegmentoListagem(segmentoResponse);
    }

    base();
  }, [i18n.language]);

  useEffect(() => {
    if (meta.totalPages !== page) {
      let options = {
        rootMargin: "0px",
        threshold: 1.0,
      };

      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          setPage(page + 1);
        }
      }, options);

      intersectionObserver.observe(document.querySelector("#sentinela"));

      return () => {
        intersectionObserver.disconnect();
      };
    }
  }, [page]);

  const handleChange = (e) => {
    setSelectedValueProduto(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleChangeSeg = (e) => {
    setSelectedValueSegmento(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  function mostrarLetra(letra) {
    if (showBtnMostrarMais) {
      setShowLetra(letra);
    }

    if (letra === "z") {
      setShowBtnMostrarMais(false);
    }

    totalItensTela(expositores, letra);
  }

  function pesquisarPorLetra() {
    if (expositores) {
      const html = ALFABETO.map((letra) => (
        <div
          style={{
            display: "inline-block",
          }}
        >
          <div className="alfabeto-lista-container">
            <li>
              {expositores.hasOwnProperty(letra) ? (
                <a href={`#${letra}`} onClick={() => mostrarLetra(letra)}>
                  {letra.toUpperCase()}
                </a>
              ) : (
                <a
                  style={{
                    color: "#E1E1E1",
                    textDecoration: "none",
                    cursor: "context-menu",
                  }}
                  href={`#${letra}`}
                >
                  {letra.toUpperCase()}
                </a>
              )}
            </li>
          </div>
        </div>
      ));

      return html;
    }
  }

  function filtragem() {
    if (expositores) {
      const html = Object.keys(expositores).map((letra) => {
        return (
          <div id={letra}>
            <Row
              style={{
                marginTop: "3.125rem",
              }}
            >
              <Col xl="1" lg="1" md="1" className="letra-filtrada">
                <h1>{letra.toUpperCase()}</h1>
              </Col>
              <Col xs="12" sm="11" md="11" lg="11" xl="11">
                <Row className="lista-expositores">
                  {expositores[letra].map((expositor) => (
                    <Col
                      xs="6"
                      sm="6"
                      md="4"
                      lg="3"
                      xl="3"
                      className={`item-lista view`}
                    >
                      <div className="expositores-container-card">
                        <div>
                          <h6>{expositor?.nomeFantasia}</h6>
                        </div>
                        <div
                          className="expositores-card"
                          onClick={() =>
                            history.push(
                              `/expositor/${expositor.uriUnico}/produtos`
                            )
                          }
                        >
                          <div className="image-logo">
                            <img
                              src={`${process.env.REACT_APP_API_URL}expositores/${expositor?.id}/logotipos/display?${time}`}
                              alt={expositor?.nomeFantasia}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <div className="voltar-ao-topo">
              <div className="hr1" />
              <button onClick={() => scroll.scrollTo(0)}>
                <span>{t("Voltar ao topo")}</span>
                <span>
                  <img
                    id="seta-para-cima"
                    src={SetaParaCima}
                    alt={t("Voltar ao topo")}
                  />
                </span>
              </button>
              <div className="hr2" />
            </div>
          </div>
        );
      });

      return html;
    }
  }

  function onMostrarTudo() {
    setShowLetra("z");
    setShowBtnMostrarMais(false);
    totalItensTela(expositores, "z");
  }

  function totalItensTela(items, letra) {
    if (items) {
      let total = 0;
      let totalExpositores = 0;
      Object.keys(items).forEach((key) => {
        totalExpositores += items[key].length;

        if (key <= letra) {
          total += items[key].length;
        }
      });

      if (total === totalExpositores) {
        setShowBtnMostrarMais(false);
      }

      if (showBtnMostrarMais) {
        setTotal(total);
      }
    }
  }

  return (
    <>
      <div className="produto-loading-container">
        {loading && (
          <lottie-player
            src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px", margin: "0 auto" }}
            loop
            autoplay
          />
        )}
      </div>

      <div hidden={loading}>
        <Row className="input-container">
          <Col xs="12" sm="12" md="3" lg="3" xl="3" className="input1">
            <InputGroup>
              <Input
                type="text"
                onChange={(ev) => {
                  setBusca(ev.target.value);
                  setPage(1);
                }}
                value={busca}
                placeholder={t("Buscar por")}
              />
              <i
                className="nc-icon nc-zoom-split"
                style={{
                  position: "absolute",
                  zIndex: 10000,
                  right: "15px",
                  top: "16px",
                }}
              />
            </InputGroup>
          </Col>

          <Col xs="12" sm="12" md="3" lg="3" xl="3">
            <div className="input2">
              <MultiSelect
                options={segmentoListagem}
                value={segmentoListagem.filter((obj) =>
                  selectedValueSegmento.includes(obj.value)
                )}
                onChange={(e) => {
                  handleChangeSeg(e);
                  setPage(1);
                }}
                labelledBy={"Segmentos"}
                overrideStrings={{
                  allItemsAreSelected: t("Todos os itens selecionados"),
                  search: t("Pesquisar..."),
                  selectAll: t("Selecionar todos"),
                  selectSomeItems: t("Segmentos"),
                }}
              />
            </div>
          </Col>

          <Col xs="12" sm="12" md="3" lg="3" xl="3">
            <div className="input3">
              <MultiSelect
                options={produtoListagem}
                value={produtoListagem.filter((obj) =>
                  selectedValueProduto.includes(obj.value)
                )}
                onChange={(e) => {
                  handleChange(e);
                  setPage(1);
                }}
                labelledBy={"Produtos"}
                overrideStrings={{
                  allItemsAreSelected: t("Todos os itens selecionados"),
                  search: t("Pesquisar..."),
                  selectAll: t("Selecionar todos"),
                  selectSomeItems: t("Produtos"),
                }}
              />
            </div>
          </Col>

          {showLimparFiltragemBtn && (
            <div>
              <button
                type="button"
                onClick={() => {
                  setShowLimparFiltragemBtn(false);
                  setBusca("");
                  setSelectedValueProduto([]);
                  setSelectedValueSegmento([]);
                  setLimpandoFiltros(true);
                }}
              >
                {t("Limpar filtros")}
              </button>
              <i
                className="nc-icon nc-simple-remove"
                style={{
                  fontSize: "10px",
                }}
              ></i>
            </div>
          )}
        </Row>

        <div className="navegue-pela-letra-container">
          <div className="navegue-h2">
            <h2>{t("Navegue pela letra inicial:")}</h2>
          </div>

          <div className="pesquisa-por-letra-container">
            {pesquisarPorLetra()}
          </div>
        </div>

        {loadingFiltragem && (
          <lottie-player
            src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px", margin: "0 auto" }}
            loop
            autoplay
          />
        )}
        <div className="expositores-container" hidden={loadingFiltragem}>
          <div className="expositores-card-letra-container">{filtragem()}</div>

          <div className="sentinela-container">
            <div
              hidden={
                carregandoMaisExpositores ||
                busca.length ||
                selectedValueProduto.length ||
                selectedValueSegmento.length ||
                limpandoFiltros
              }
            >
              <div id="sentinela" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
