import * as Yup from "yup";
import * as messages from "./messages";
import "./customs.validation";
import unMask from "utils/unmask";
import validaDigitos from "utils/valida-digitos";
import { validaTelefone } from "utils/valida-telefone";

const mensagens = messages.getMessages();

function validaPais(pais, campo) {
  if (pais?.id === 1) {
    return (
      campo !== "" &&
      campo !== null &&
      campo !== undefined &&
      campo !== "________" &&
      campo !== "_____-___"
    );
  } else {
    return true;
  }
}

export const usuarioUpdateSchema = Yup.object({
  nome: Yup.string().required(mensagens.REQUIRED),
  cpf: Yup.string().cpfCnpj().required(mensagens.REQUIRED),
  email: Yup.string().email(mensagens.EMAIL).required(mensagens.REQUIRED),
  confEmail: Yup.string()
    .email(mensagens.EMAIL)
    .required(mensagens.REQUIRED)
    .test("email-match", mensagens.SAME_EMAIL, function (value) {
      return this.parent.email === value;
    }),

  telefones: Yup.array()
    .of(
      Yup.object().shape({
        ddi: Yup.string().test("ddi", mensagens.REQUIRED, function (value) {
          return validaTelefone(this.parent, value);
        }),

        ddd: Yup.string().test("ddd", mensagens.REQUIRED, function (value) {
          if (
            validaDigitos(this.parent.ddi) &&
            unMask(this.parent.ddi) === "55"
          ) {
            return validaDigitos(value);
          }

          return true;
        }),
        telefone: Yup.string()
          .test(
            "is-phone",
            mensagens.REQUIRED,
            (value) => value !== "" && value !== "_ ____-____"
          )
          .test("ddi", mensagens.REQUIRED, function (value) {
            return validaTelefone(this.parent, value);
          }),

        tipo: Yup.string().test("ddi", mensagens.REQUIRED, function (value) {
          return validaTelefone(this.parent, value);
        }),
      })
    )
    .required(),

  enderecos: Yup.array().of(
    Yup.object().shape({
      idPais: Yup.object().required(mensagens.REQUIRED),

      cep: Yup.string().test("zipCode", mensagens.REQUIRED, function (value) {
        return validaPais(this.parent.idPais, value);
      }),

      cidadeExterior: Yup.string().test(
        "cidadeExterior",
        mensagens.REQUIRED,
        function (value) {
          if (this.parent?.idPais?.id !== 1) {
            return value;
          } else {
            return true;
          }
        }
      ),

      estadoExterior: Yup.string().test(
        "estadoExterior",
        mensagens.REQUIRED,
        function (value) {
          if (this.parent?.idPais?.id !== 1) {
            return value;
          } else {
            return true;
          }
        }
      ),

      idEstado: Yup.object()
        .test("idEstado", mensagens.REQUIRED, function (value) {
          if (this.parent?.idPais?.id === 1) {
            return value;
          } else {
            return true;
          }
        })
        .nullable(),

      idCidade: Yup.object()
        .test("idCidade", mensagens.REQUIRED, function (value) {
          if (this.parent?.idPais?.id === 1) {
            return value;
          } else {
            return true;
          }
        })
        .nullable(),

      logradouro: Yup.string().required(mensagens.REQUIRED),

      bairro: Yup.string().required(mensagens.REQUIRED),

      numero: Yup.string().required(mensagens.REQUIRED),
    })
  ),
});
