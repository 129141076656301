import React from "react";
import { Spinner } from "reactstrap";

export default function ButtonSave({
  loading,
  label,
  disabled,
  type,
  styles,
  onClick,
  id = "",
}) {
  const labelStr = label ? label : "Salvar";
  const typeStr = type ? type : "button";

  return (
    <button
      className={"button-save" + styles}
      disabled={loading || disabled}
      type={typeStr}
      onClick={onClick}
    >
      {loading ? (
        <div className="p-button-icon">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="p-button-text">{labelStr}</div>
      )}
    </button>
  );
}
