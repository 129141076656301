import { formataMinutosParaHoras } from "utils/formata-minutos-para-horas";
import relogio from "../../assets/img/icons/relogio.svg";
import "./style.scss";

function CardAulas({ curso, click }) {
  return (
    <div className="container-card" onClick={() => click(curso.id)}>
      <div className="progress-bar-container">
        <progress value={curso?.progresso} max="100"></progress>{" "}
        <span className="text">{curso?.progresso || 0}%</span>
      </div>
      <div className="container-logo-info">
        <div className="logo">
          <img
            src={`${process.env.REACT_APP_API_URL}cursos/idiomas/${curso.idCursoIdioma}/display`}
            alt=""
          />
        </div>
        <div className="container-nome-empresa">
          <h1>{curso.titulo}</h1>
          <p>{curso.expositor.nomeFantasia}</p>
        </div>
      </div>

      <div className="rodape-info">
        <div className="container-horas-aulas">
          <div className="icon-horas">
            <img src={relogio} alt="" />
            <p>{formataMinutosParaHoras(curso.cargaHoraria)}</p>
          </div>
          <p> - {`${curso.quantidadeAulas} aulas`}</p>
        </div>
      </div>
    </div>
  );
}

export default CardAulas;
