/* eslint-disable react-hooks/exhaustive-deps */
import AssentadorFooter from "components/expo-revestir/AssentadorFooter";
import ExpoFooter from "components/expo-revestir/ExpoFooter";
import ExpoNavBar from "components/expo-revestir/ExpoNavBar";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Authentication from "services/authentication.service.js";
import { AppContext } from "store.js";
import { userProfiles } from "utils/permission-user";
import { userHasAccess } from "utils/permission-user";

function Index({ children }) {
  const history = useHistory();
  const { user, setUser } = useContext(AppContext);
  document.documentElement.classList.remove("nav-open");

  useEffect(() => {
    async function getLoggerdUser() {
      let userData = null;
      if (!user.hasOwnProperty("id")) {
        userData = await new Authentication().retrieveLoggedUser();
        Authentication._user = userData;

        setUser(userData);

        if ([4,5].includes(userData.perfil.id)) {
          history.push("/aassentador-todos-cursos");
        }

        if (userData.mudarSenha) {
          // TODO: envidar para pagina de trocar senha
        }
      } else {
        userData = user;
      }

      // const newSocketio = io(`${process.env.REACT_APP_SOCKETIO_URL}`, {
      //   auth: {
      //     token: userData.id,
      //   },
      // });
      // setSocketio(newSocketio);
    }

    getLoggerdUser();

    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  }, []);

  return (
    <div className="container-index">
      <div className="content-index">
        <ExpoNavBar />
        {user?.id ? children : null}
      </div>
      {!userHasAccess([userProfiles.assentador]) ? (
        <ExpoFooter />
      ) : (
        <AssentadorFooter />
      )}
    </div>
  );
}

export default Index;
