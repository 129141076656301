import api from "./api.service";
import CoreApiService from "./core-api.service";
import BannerExpositorSerializer from "./serializers/banner-expositor-serializer";
import BannerExpositorLanguageSerializer from "./serializers/banner-expositor.language.serializer";
export default class BannerExpositorService extends CoreApiService {
  constructor() {
    super("expositores", "banners", new BannerExpositorSerializer());
    this.bannerExpositorLanguageSerializer =
      new BannerExpositorLanguageSerializer();
  }

  async readBannerExpositor(id) {
    const response = await api.get(
      `${this.parentEndpoint}/${this.endpoint}/${id}`
    );
    const data = response.data;
    return this.serializer.fromJson(data);
  }

  async updateBannerExpositor(item, isFormDate = false) {
    const response = await api.put(
      `${this.parentEndpoint}/${this.endpoint}/${item.id}`,
      isFormDate
        ? this.serializer.toFormData(item)
        : this.serializer.toJson(item)
    );

    const data = response.data;
    return this.serializer.fromJson(data.oret);
  }

  async deleteBannerExpositor(id) {
    const response = await api.delete(
      `${this.parentEndpoint}/${this.endpoint}/${id}`
    );
    return response;
  }

  async upload(idPublicidadeIdioma, data, mobile) {
    let query = "";

    if (mobile) {
      query = "?mobile=true";
    }

    const formData = this.serializer.toFormData(data);

    const response = await api.put(
      `${this.parentEndpoint}/${this.endpoint}/${idPublicidadeIdioma}/upload${query}`,
      formData
    );

    if (response.data["oret"]) {
      return this.bannerExpositorLanguageSerializer.fromJson(
        response.data["oret"]
      );
    }

    return null;
  }
}
