import BaseSerializer from "./base.serializer";

export default class InstrutorIdiomaSerializer extends BaseSerializer {
  fromJson(json) {
    const idioma = {};

    Object.assign(
      idioma,
      json.id_curso_instrutor_idioma && { idCursoInstrutorIdioma: json.id_curso_instrutor_idioma },
      json.id_idioma && { idIdioma: json.id_idioma },
      json.biografia && { biografia: json.biografia },
    );

    return idioma;
  }
}
