import React, { useContext, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row,
} from "reactstrap";
import { AppContext } from "store";
import { Form, Formik } from "formik";
import FaleComExpositorService from "services/fale-com-expositor.service";
import TextareaInput from "./TextareaInput";
import { useTranslation } from "react-i18next";

export default function ModalFaleComExpositor({ modalAberto, setModal }) {
  const { t } = useTranslation();
  const { user, idExpositor } = useContext(AppContext);
  const _faleComExpositorService = new FaleComExpositorService();
  const [mensagemEnviada, setMensagemEnviada] = useState(false);
  const [mensagemEnviando, setMensagemEnviando] = useState(false);
  const [primeiroEnvio, setPrimeiroEnvio] = useState(true);

  const initialValues = {};

  async function enviarMensagem(values) {
    const data = {
      idExpositor: idExpositor,
      assunto: values.assunto,
    };

    await _faleComExpositorService
      .createMensagem(data)
      .then(() => {
        setMensagemEnviada(true);
        setMensagemEnviando(false);
      })
      .catch(() => {
        setPrimeiroEnvio(true);
        setMensagemEnviando(false);
      });
  }

  return (
    <Modal
      isOpen={modalAberto}
      toggle={() => {
        setModal(false);
      }}
      size="lg" 
      className="modal-fale-com-o-expositor"
    >
      <ModalHeader>{t("Fale conosco")}</ModalHeader>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          enviarMensagem(values);
        }}
      >
        {(props) => {
          return (
            <Form>
              <ModalBody>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                  <h4>
                    {t("Nome")}: {user.nome}
                  </h4>
                </Col>

                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                  <h4>
                    {t("E-mail")}: {user.email}
                  </h4>
                </Col>

                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  className="container-texto"
                >
                  <TextareaInput
                    placeholder={t("Digite sua mensagem aqui")}
                    name="assunto"
                    className="text-area-fale-com-expositor"
                  />
                </Col>

                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                  <h5
                    id="alerta"
                    style={{
                      textAlign: "center",
                      marginTop: "6px",
                      display: "none",
                    }}
                  >
                    {t("E-mail enviado com sucesso!")}
                  </h5>
                </Col>
              </ModalBody>

              <ModalFooter>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  className="modal-fale-com-expositor-footer"
                >
                  {mensagemEnviada && (
                    <h5>
                      {t("A mensagem foi enviada para o e-mail do expositor.")}
                    </h5>
                  )}

                  <Row>
                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                      <Button
                        className="btn-galeria-dark"
                        style={{ background: "black", color: "white" }}
                        type="submit"
                        onClick={() => {
                          setMensagemEnviando(true);
                          setMensagemEnviada(false);
                          setPrimeiroEnvio(false);
                        }}
                      >
                        {primeiroEnvio && t("Enviar")}
                        {mensagemEnviando && t("Enviando...")}
                        {mensagemEnviada && t("Enviar")}
                      </Button>
                    </Col>

                    <Col
                      xs="6"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                    >
                      <Button
                        className="btn-galeria-dark"
                        style={{ background: "transparent", color: "black" }}
                        type="button"
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        Fechar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
