import CarregarImagem from "components/CarregarImagem";
import React, { useEffect, useState } from "react";
import ReactElasticCarousel from "react-elastic-carousel";
import { useTranslation } from "react-i18next";
import "react-slideshow-image/dist/styles.css";
import EventService from "services/evento.service";
import { incrementarCliqueEmBannerEvento } from "utils/incrementar-clique-em-publicidade";
import useWindowDimensions from "utils/verificar-dimensao-da-tela";

export default function BannerEventosAoVivo({ evento }) {
  const [bannersEventoDesktop, setBannersEventoDesktop] = useState([]);
  const [bannersEventoMobile, setBannersEventoMobile] = useState([]);
  const { i18n } = useTranslation();
  const [time] = useState(new Date().getTime());
  const { width } = useWindowDimensions();

  useEffect(() => {
    async function init() {
      const _bannerEvento = new EventService();
      const bannerEventoResponse = await _bannerEvento.getBanner(evento?.id);

      const bannersValidosEventoDesktop = bannerEventoResponse.filter(
        (item) => item.filename
      );
      setBannersEventoDesktop(bannersValidosEventoDesktop);

      const bannersValidosEventoMobile = bannerEventoResponse.filter(
        (item) => item.filenameMobile
      );
      setBannersEventoMobile(bannersValidosEventoMobile);
    }
    init();
  }, [i18n.language, evento?.id]);

  function checarBannerClicavel(banner) {
    if (banner.link) {
      incrementarCliqueEmBannerEvento(banner);
    }
  }

  return (
    <div
      hidden={
        bannersEventoDesktop?.length <= 0 && bannersEventoMobile?.length <= 0
      }
    >
      {width <= 500 ? (
        <div hidden={bannersEventoMobile?.length <= 0}>
          <ReactElasticCarousel enableAutoPlay autoPlaySpeed={10000}>
            {bannersEventoMobile?.map((banner) => (
              <div
                key={banner.id}
                className="each-slide banner-container"
                onClick={() => {
                  checarBannerClicavel(banner);
                }}
                style={banner.link && { cursor: "pointer" }}
              >
                <div className="container-mobile-img">
                  <CarregarImagem
                    src={`${process.env.REACT_APP_API_URL}eventos/banners/${banner.idEventoBannerIdioma}/display?
              ${time}&mobile=true`}
                    alt={banner.titulo}
                    className="imagem-banners"
                  />
                </div>
              </div>
            ))}
          </ReactElasticCarousel>
        </div>
      ) : (
        <div hidden={bannersEventoDesktop?.length <= 0}>
          <ReactElasticCarousel enableAutoPlay autoPlaySpeed={10000}>
            {bannersEventoDesktop?.map((banner) => (
              <div
                key={banner.id}
                className="each-slide banner-container"
                onClick={() => {
                  checarBannerClicavel(banner);
                }}
                style={banner.link && { cursor: "pointer" }}
              >
                <div style={{ width: "100%" }}>
                  <CarregarImagem
                    src={`${process.env.REACT_APP_API_URL}eventos/banners/${banner.idEventoBannerIdioma}/display?
${time}&desktop=true`}
                    alt={banner.titulo}
                    className="imagem-banners"
                  />
                </div>
                ;
              </div>
            ))}
          </ReactElasticCarousel>
        </div>
      )}
    </div>
  );
}
