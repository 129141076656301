import languagesSerializer from "./idioma.serializer";

export default class ProductImageLanguageSerializer {
  constructor() {
    this.languageSerializer = new languagesSerializer();
  }

  fromJson(json) {
    const productsLanguagesImages = {};

    Object.assign(
      productsLanguagesImages,
      json.id_produto_imagem_idioma && {
        idProdutoImagemIdioma: json.id_produto_imagem_idioma,
      },
      json.nome && { nome: json.nome },
      json.descricao && { descricao: json.descricao },
      json.idioma && { idIdioma: json.idioma.id_idioma }
    );
    return productsLanguagesImages;
  }

  toJson(productImages) {
    return {
      nome: productImages.nome,
      descricao: productImages.descricao,
      id_idioma: productImages.idIdioma,
    };
  }
}
