/* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumb from "components/Breadcamb";
import VimeoPlayer from "components/VimeoPlayer";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import CursoMonitoramentoService from "services/curso-monitoramento.service";
import { AppContext } from "store";
import { formataMinutosParaHoras } from "utils/formata-minutos-para-horas";
import arrow from "../../assets/img/icons/arrow-type-2.png";
import "./styles.scss";

const VideoPage = () => {
  const { idCursoAulaIdioma } = useParams();
  const { i18n } = useTranslation();
  const [aula, setAula] = useState();
  const [curso, setCurso] = useState();
  const history = useHistory();
  const [semIdioma, setSemIdioma] = useState(false);
  const { user } = useContext(AppContext);
  const [hasPrevius, setHasPrevius] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [apagarLuz, setApagarLuz] = useState(false);
  const [porcentagemCurso, setPorcentagemCurso] = useState(0);
  const [cargaHorarioCurso, setCargaHorarioCurso] = useState(0);

  const handleGoBack = () => {
    history.push(`/cursos-modulo/${curso?.id_curso}`);
  };

  const changeClass = (next = false) => {
    const idAula = next ? hasNext : hasPrevius;
    history.push(`/video/${idAula}`);
  };

  const listaRef = useRef(new Set());
  const intervalRef = useRef(null);

  const _cursoMonitoramentoService = new CursoMonitoramentoService();

  useEffect(async () => {
    if (![4,5].includes(user.perfil.id)) {
      history.push("/home");
    }

    const fetchVideoData = async () => {
      try {
        const response = await _cursoMonitoramentoService.getAula(
          idCursoAulaIdioma
        );

        setHasPrevius(response?.has_aula_anterior);
        setHasNext(response?.has_aula_proxima);
        setPorcentagemCurso(response?.progresso_curso || 0);
        setCargaHorarioCurso(formataMinutosParaHoras(response?.curso?.carga_horaria));

        const aulaData = response.idiomas.find(
          (item) =>
            item.idioma.codigo.toLowerCase() === i18n.language.toLowerCase()
        );
        if (aulaData) {
          setAula(aulaData);
          setSemIdioma(false);
        } else {
          setSemIdioma(true);
        }

        const cursoIdioma = response.curso.idiomas.filter(
          (cursoIdioma) =>
            cursoIdioma.idioma.codigo.toLowerCase() ===
            i18n.language.toLowerCase()
        );

        if (cursoIdioma.length) {
          setCurso(cursoIdioma[0]);

          const cursoAux = {
            ...cursoIdioma[0],
            iniciar_tempo:
              response?.monitoramento?.ultimo_minuto_assistido || 0,
            id_aula_idioma: aulaData.id_curso_aula_idioma,
          };

          if (cursoAux) {
            return cursoAux;
          }
        }

        return {
          id_curso: response.curso.id_curso,
          id_aula_idioma: idCursoAulaIdioma,
        };
      } catch {}
    };

    const cursoAux = await fetchVideoData();

    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;

    const handleScriptLoad = () => {
      const iframe = document.getElementById("vimeo-player");
      const player = new window.Vimeo.Player(iframe);

      if (cursoAux?.iniciar_tempo) {
        player.setCurrentTime(cursoAux.iniciar_tempo);
      }

      player.on("timeupdate", function (data) {
        const currentTime = data.seconds;

        listaRef.current.add(parseInt(currentTime));
      });

      intervalRef.current = setInterval(() => {
        if (listaRef.current.size) {
          salvarMonitoramento([...listaRef.current], cursoAux);
        }
      }, 5000);
    };

    script.removeEventListener("load", handleScriptLoad);
    script.addEventListener("load", handleScriptLoad);

    document.body.appendChild(script);
  }, [idCursoAulaIdioma, i18n.language]);

  const salvarMonitoramento = async (dados, curso) => {
    const data = {
      id_curso: curso.id_curso,
      id_curso_aula_idioma: curso.id_aula_idioma,
      progresso_video: dados.join(","),
      ultimo_minuto_assistido: dados[dados.length - 1],
    };

    await _cursoMonitoramentoService.atualizaMonitoramento(data);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const handleApagarLuz = () => {
    setApagarLuz(!apagarLuz);
  };

  return (
    <div className={apagarLuz ? "apagar-luz" : ""}>
      <div className="container ">
        <div className="container-page">
          <div className="cabecalho">
            <Breadcrumb
              nomeCurso={curso?.titulo}
              modulo={`Módulo`}
              arrow={arrow}
              cor={"preto"}
              id={curso?.id_curso}
              tipo="desk"
            />
          </div>

          <div className="box-luz">
            <i
              onClick={handleApagarLuz}
              className="fa fa-lightbulb-o icon-luz"
            />
          </div>

          <div className="modulo-title">
            <h4>Módulo</h4>
            <h1>{aula?.titulo}</h1>
          </div>

          <div className="info-progresso-curso">
            <div>{porcentagemCurso}% concluído</div>
            <div className="icon-clock">
              <i className="fa fa-clock-o"></i> {cargaHorarioCurso}
            </div>
          </div>

          {!semIdioma ? (
            <div className="container-video-vimeo">
              <VimeoPlayer videoLink={aula?.link_video} />
            </div>
          ) : (
            "Não possui aula no idioma selecionado"
          )}

          {curso ? (
            <div className="container-buttons">
              <button onClick={() => changeClass()} disabled={!hasPrevius}>
                Aula Anterior
              </button>
              <button onClick={handleGoBack}>Voltar</button>
              <button onClick={() => changeClass(true)} disabled={!hasNext}>
                Próxima Aula
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VideoPage;
