import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/scss/paper-kit.scss";
// styles
import "bootstrap/scss/bootstrap.scss";
import ScrollParaCima from "components/expo-revestir/ScrollParaCima";
import { Redirect, Switch } from "react-router-dom";
import PaginaContato from "views/Contato/index";
import Home from "views/Home/Index";
import MapaInterativo from "views/MapaInterativo";
import NucleoIcons from "views/NucleoIcons.js";
import PageCurso from "views/PageCurso";
import PageListCursos from "views/PageListCursos/PageListCursos";
import PaginaExpositores from "views/PaginaExpositores";
import ExpositorRoutes from "views/PaginaExpositores/ExpositorRoutes";
import PerfilUsuario from "views/PerfilUsuario";
import Presentation from "views/Presentation.js";
import EventosTela from "views/Programacao/EventosTela";
import ProgramacaoEventos from "views/Programacao/index";
import Sections from "views/Sections.js";
import LoginPage from "views/authentication/LoginPage";
import Logout from "views/authentication/Logout";
import MudarSenha from "views/authentication/MudarSenha";
import RecuperarSenha from "views/authentication/RecuperarSenha";
import Visitante from "views/authentication/Visitante";
import AboutUs from "views/examples/AboutUs.js";
import AddProduct from "views/examples/AddProduct.js";
import BlogPost from "views/examples/BlogPost.js";
import BlogPosts from "views/examples/BlogPosts.js";
import ContactUs from "views/examples/ContactUs.js";
import Discover from "views/examples/Discover.js";
import Ecommerce from "views/examples/Ecommerce.js";
import Error404 from "views/examples/Error404.js";
import Error422 from "views/examples/Error422.js";
import Error500 from "views/examples/Error500.js";
import ProductPage from "views/examples/ProductPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import SearchWithSidebar from "views/examples/SearchWithSidebar.js";
import Settings from "views/examples/Settings.js";
import TwitterRedesign from "views/examples/TwitterRedesign.js";
import PageFullCursos from "views/pageFullCursos";
import VideoPage from "views/pageVideoAula";
import Route from "./Route";

export default function Routes() {
  return (
    <ScrollParaCima>
      <Switch>
        <Route path="/entrar" component={LoginPage} />
        <Route exec path="/recuperar-senha" component={RecuperarSenha} />
        <Route exact path="/visitante" component={Visitante} />
        <Route exact isPrivate path="/mudar-senha" component={MudarSenha} />
        <Route isPrivate path="/index" component={(props) => <Home />} />
        <Route
          exact
          isPrivate
          activeClassName="selected"
          path="/expositores"
          component={(props) => <PaginaExpositores />}
        />
        <Route exact isPrivate path="/" component={Home} />
        <Route exact isPrivate path="/home" component={Home} />
        <Route exact isPrivate path="/sair" component={Logout} />

        <Route exact isPrivate path="/evento/:id" component={EventosTela} />

        <Route
          path="/expositor/:uriUnico"
          component={(props) => <ExpositorRoutes />}
        />

        <Route path="/contato" component={(props) => <PaginaContato />} />

        <Route
          path="/programacao-eventos"
          component={(props) => <ProgramacaoEventos />}
        />

        <Route path="/perfil" component={(props) => <PerfilUsuario />} />

        <Route
          path="/nucleo-icons"
          component={(props) => <NucleoIcons {...props} />}
        />
        <Route
          path="/sections"
          component={(props) => <Sections {...props} />}
        />
        <Route
          path="/presentation"
          component={(props) => <Presentation {...props} />}
        />
        <Route path="/about-us" component={(props) => <AboutUs {...props} />} />
        <Route
          path="/add-product"
          component={(props) => <AddProduct {...props} />}
        />
        <Route
          path="/blog-post"
          component={(props) => <BlogPost {...props} />}
        />
        <Route
          path="/blog-posts"
          component={(props) => <BlogPosts {...props} />}
        />
        <Route
          path="/contact-us"
          component={(props) => <ContactUs {...props} />}
        />
        <Route
          path="/discover"
          component={(props) => <Discover {...props} />}
        />
        <Route
          path="/e-commerce"
          component={(props) => <Ecommerce {...props} />}
        />
        <Route
          path="/error-404"
          component={(props) => <Error404 {...props} />}
        />
        <Route
          path="/error-422"
          component={(props) => <Error422 {...props} />}
        />
        <Route
          path="/error-500"
          component={(props) => <Error500 {...props} />}
        />
        <Route
          path="/product-page"
          component={(props) => <ProductPage {...props} />}
        />
        <Route
          path="/profile-page"
          component={(props) => <ProfilePage {...props} />}
        />
        <Route
          path="/register-page"
          component={(props) => <RegisterPage {...props} />}
        />
        <Route
          path="/search-with-sidebar"
          component={(props) => <SearchWithSidebar {...props} />}
        />
        <Route
          path="/settings"
          component={(props) => <Settings {...props} />}
        />
        <Route
          path="/twitter-redesign"
          component={(props) => <TwitterRedesign {...props} />}
        />
        <Route
          isPublic
          path="/mapa/:slug"
          component={(props) => <MapaInterativo {...props} />}
        />

        <Route path="/assentador-todos-cursos" component={PageFullCursos} />
        <Route path="/assentador-cursos" component={PageListCursos} />
        <Route path="/cursos-modulo/:id" component={PageCurso} />
        <Route path="/video/:idCursoAulaIdioma" component={VideoPage} />

        <Redirect to="/home" />
      </Switch>
    </ScrollParaCima>
  );
}
