import React from "react";

export default function VimeoPlayer({ videoLink }) {
  let video = "";

  if (videoLink) {
    video = videoLink.split("/").pop();
  }

  return (
    <iframe
      id="vimeo-player"
      src={`https://player.vimeo.com/video/${video}`}
      width="500"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      title="Vimeo Video"
    ></iframe>
  );
}
