import unMask from "utils/unmask";
import * as Yup from "yup";
import * as messages from "./messages";

const mensagens = messages.getMessages();

export const phoneSchema = Yup.object({
  ddi: Yup.string().required(mensagens.REQUIRED),
  ddd: Yup.string().test("ddd", mensagens.REQUIRED, function (value) {
    if (unMask(this?.parent?.ddi) === "55" && !unMask(value)) {
      return false;
    } else {
      return true;
    }
  }),
  telefone: Yup.string().required(mensagens.REQUIRED),
  tipo: Yup.string().required(mensagens.REQUIRED),
});
