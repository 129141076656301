export default class TelefoneTipoSerializer {
  fromJson(json) {
    const telefoneTipo = {
      id: json.id_telefone_tipo,
      tipo: json.tipo,
      value: json.id_telefone_tipo,
      label: json.tipo,
    };

    return telefoneTipo;
  }

  toJson(telefoneTipo) {
    return {
      id_telefone_tipo: telefoneTipo.id,
      tipo: telefoneTipo.type,
    };
  }
}
