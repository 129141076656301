import * as Yup from "yup";
import * as messages from "./messages";
import "./customs.validation";
// import { EnderecoTemValor } from "utils/endereco-tem-valor";
import { addressSchema } from "./endereco.validation";
import { phoneSchema } from "./telefone.validation";

const mensagens = messages.getMessages();

function verificaIdiomaSelecionado(value) {
  const idiomaAtual = localStorage.getItem("i18nextLng");
  if (idiomaAtual === "pt-BR" && !value) {
    return false;
  } else {
    return true;
  }
}

export const modalCadatroUsuario = Yup.object({
  nome: Yup.string().required(mensagens.REQUIRED),
  cpf: Yup.string()
    .cpfCnpj()
    .test("cpf", mensagens.REQUIRED, function (value) {
      return verificaIdiomaSelecionado(value);
    }),
  email: Yup.string().email(mensagens.EMAIL).required(mensagens.REQUIRED),
  dataNascimento: Yup.string().required(mensagens.REQUIRED),
  cargo: Yup.object().required(mensagens.REQUIRED),
  sexo: Yup.object().required(mensagens.REQUIRED),
  perfilEmpresa: Yup.object().required(mensagens.REQUIRED),

  emailConfirmado: Yup.string()
    .email(mensagens.EMAIL)
    .required(mensagens.REQUIRED)
    .test(
      "email-match",
      '"E-mail" e "Confirme o e-mail" devem ser iguais',
      function (value) {
        return this.parent.email === value;
      }
    ),

  telefones: Yup.array().of(phoneSchema),
  enderecos: Yup.array().of(addressSchema),

  empresaVisitante: Yup.object().shape({
    nomeFantasia: Yup.string().required(mensagens.REQUIRED),
  }),
});
