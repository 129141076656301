export default class BaseSerializer {
  idiomaAtual(idiomas, serializer, aceitarNull = false) {
    const i18nLng = localStorage.getItem("i18nextLng");
    if (i18nLng) {
      const data = idiomas.filter(
        (item) => item.idioma.codigo.toLowerCase() === i18nLng.toLowerCase()
      );

      if (data.length) {
        return serializer.fromJson(data[0]);
      }
    }

    const data = idiomas.length ? serializer.fromJson(idiomas[0]) : {}
    data.idiomaTrocado = true;

    if (aceitarNull) {
      Object.keys((key) => (data[key] = ""));
    }

    return data;
    
  }
}
