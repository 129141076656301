import api from "./api.service";
import CoreApiService from "./core-api.service";
import ProductImageSerializer from "./serializers/product-image.serializer";

export default class ProductsImagesService extends CoreApiService {
  constructor() {
    super("produtos", "imagens", new ProductImageSerializer());
  }

  async deleteImage(id) {
    const response = await api.delete(`produto-imagens/${id}`);
    return response;
  }

  async updateLanguage(item) {
    const response = await api.put(
      `produto-imagens/${item.id}`,
      this.serializer.toJson(item)
    );

    const data = response.data;
    return this.serializer.fromJson(data.oret);
  }

  async uploadList(idProduto, data) {
    const formData = this.serializer.toFormData(data);

    const response = await api.post(
      `${this.parentEndpoint}/${idProduto}/${this.endpoint}/uploads`,
      formData
    );

    if (response.data["oret"].length) {
      return response.data["oret"].map((item) =>
        this.serializer.fromJson(item)
      );
    }

    return [];
  }
}
