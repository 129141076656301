export default class CitySerializer {
  fromJson(json) {
    const city = {
      id: json.id_cidade,
      value: json.id_cidade,
      city: json.cidade,
      label: json.cidade,
      ibje: json.ibje,
      idState: json.id_estado,
    };

    return city;
  }

  toJson(city) {
    return {
      id_cidade: city.id,
    };
  }
}
