import { formataMinutosParaHoras } from "utils/formata-minutos-para-horas";
import "./infoCard.scss";

const InfoCard = ({ icon, title, info, infoHora }) => {
  return (
    <div className="info-card">
      <div className="info-icon">
        <img src={icon} alt="" />
      </div>
      <div className="info-details">
        <h3 className="info-title">{title}</h3>
        <p className="info-info">
          {info ? info : formataMinutosParaHoras(infoHora)}
        </p>
      </div>
    </div>
  );
};

export default InfoCard;
