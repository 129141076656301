export default class PaisSerializer {
  fromJson(json) {
    const pais = {};
    const idiomaAtual = localStorage.getItem("i18nextLng");

    if (idiomaAtual === "pt-BR") {
      Object.assign(
        pais,
        json.id_pais && { id: json.id_pais },
        json.id_pais && { value: json.id_pais },
        json.nome_pt && { nome: json.nome_pt },
        json.nome_pt && { label: json.nome_pt },
        json.sigla && { sigla: json.sigla },
        json.id_cidade && { idCidade: json.id_cidade },
        json.cidade && { cidade: json.cidade },
        json.id_estado && { idEstado: json.id_estado },
        json.estado && { estado: json.estado }
      );
    } else {
      Object.assign(
        pais,
        json.id_pais && { id: json.id_pais },
        json.id_pais && { value: json.id_pais },
        json.nome && { nome: json.nome },
        json.nome && { label: json.nome },
        json.sigla && { sigla: json.sigla },
        json.id_cidade && { idCidade: json.id_cidade },
        json.cidade && { cidade: json.cidade },
        json.id_estado && { idEstado: json.id_estado },
        json.estado && { estado: json.estado }
      );
    }

    return pais;
  }
}
