import BaseSerializer from "./base.serializer";
import PerfilEmpresaIdiomasSerializer from "./perfil-empresa-idioma.serializer";

export default class PerfilEmpresaSerializer extends BaseSerializer {
  fromJson(json) {
    const perfilEmpresa = {};

    Object.assign(
      perfilEmpresa,
      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new PerfilEmpresaIdiomasSerializer()),
      }
    );
    return perfilEmpresa;
  }
}
