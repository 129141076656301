import Calendar from "@toast-ui/react-calendar";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import UsuarioService from "services/usuario.service";
import "tui-calendar/dist/tui-calendar.css";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import getDataLocale from "utils/map-data";
import setaEsquerda from "../../assets/img/expo-revestir/icons/arrow-left-produtoCard.svg";
import setaDireita from "../../assets/img/expo-revestir/icons/arrow-right-produtoCard.svg";
import useWindowDimensions from "../../utils/verificar-dimensao-da-tela";
import EventoService from "../../services/evento.service";

export default function CalendarioEventosAgendados() {
  const [schedules, setSchedules] = useState([]);
  const { t, i18n } = useTranslation();
  const calendarioRef = useRef();
  const { width } = useWindowDimensions();
  const [visualizacaoCalendario, setVisualizacaoCalendario] = useState("");
  const [tituloCalendario, setTituloCalendario] = useState("");
  const nomesDiaSemanaIdioma = {
    en: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    es: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sáb", "Dom"],
    "pt-BR": ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
  };
  const template = {
    popupDetailDate: function (isAllDay, start, end) {
      return ` ${format(start._date, "HH:mm")} - ${format(end._date, "HH:mm")}`;
    },
    popupDelete: () => {
      return "Deletar";
    },
    popupEdit: () => {
      return "Editar";
    },
  };

  useEffect(() => {
    async function init() {
      mostrarBotoes();

      if (width < 767) {
        setVisualizacaoCalendario("day");
      } else {
        setVisualizacaoCalendario("week");
      }

      const _calendarioEventosAgendados = new UsuarioService();
      const respostaApiEventosAgendados =
        await _calendarioEventosAgendados.agendaEventos();

      const eventosAgendados = [];
      respostaApiEventosAgendados.eventosAgendados.forEach((item) => {
        if (item.eventoExterno) {
          switch (item.idEventoCategoria) {
            case 1:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#52C9E8",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento externo"),
              });
              break;

            case 2:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#60269E",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento externo"),
              });
              break;

            case 3:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#F87FB4",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento externo"),
              });
              break;

            default:
              break;
          }
        } else {
          switch (item.idEventoCategoria) {
            case 1:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#52C9E8",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento interno"),
              });
              break;

            case 2:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#60269E",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento interno"),
              });
              break;

            case 3:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#F87FB4",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento interno"),
              });
              break;

            default:
              break;
          }
        }
      });
      setSchedules(eventosAgendados);
    }

    init();
  }, [i18n.language, t, width]);

  function trocarCalendario(value) {
    const ref = calendarioRef.current.getInstance();

    switch (value) {
      case "prev":
        ref.prev();
        mostrarBotoes();
        break;

      case "next":
        ref.next();
        mostrarBotoes();
        break;

      default:
        break;
    }
  }

  function mostrarBotoes() {
    if (calendarioRef.current) {
      const ref = calendarioRef.current.getInstance();
      let data = ref.getDate()._date;

      setTituloCalendario(
        `${format(data, "MMMM", { locale: getDataLocale() })} - ${format(
          data,
          "yyyy"
        )}`
      );
    }
  }

  function onDetalhes(e) {
    const conteudo = document.getElementsByClassName(
      "tui-full-calendar-popup-container"
    );

    const div = document.createElement("div");
    div.classList.add("tui-full-calendar-section-button");

    const botaoVerEvento = document.createElement("button");
    botaoVerEvento.innerHTML = t("Ver evento");
    botaoVerEvento.classList.add("btn-calendario-ver-evento");
    botaoVerEvento.onclick = async () => {
      const _eventoServico = new EventoService();
      const _eventoServicoResposta = await _eventoServico.read(
        e.schedule.calendarId
      );

      if (_eventoServicoResposta.eventoExterno) {
        window.open(`${_eventoServicoResposta.urlVideo}`);
      } else {
        window.open(`${process.env.REACT_APP_URL}evento/${e.schedule.raw}`);
      }
    };

    const botaoDeletarEvento = document.createElement("button");
    botaoDeletarEvento.innerHTML = t("Remover");
    botaoDeletarEvento.classList.add("btn-calendario-deletar-evento");
    botaoDeletarEvento.onclick = async () => {
      const _usuarioService = new UsuarioService();
      const usuarioServicoResposta = await _usuarioService.agendarEventos({
        id_evento: e.schedule.calendarId,
      });

      const modal = document.getElementsByClassName(
        "tui-full-calendar-popup-container"
      );
      modal[0].hidden = true;

      const eventosAgendados = [];
      usuarioServicoResposta.eventosAgendados.forEach((item) => {
        if (item.eventoExterno) {
          switch (item.idEventoCategoria) {
            case 1:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#52C9E8",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento externo"),
              });
              break;

            case 2:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#60269E",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento externo"),
              });
              break;

            case 3:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#F87FB4",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento externo"),
              });
              break;

            default:
              break;
          }
        } else {
          switch (item.idEventoCategoria) {
            case 1:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#52C9E8",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento interno"),
              });
              break;

            case 2:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#60269E",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento interno"),
              });
              break;

            case 3:
              eventosAgendados.push({
                id: item.idEvento,
                calendarId: item.idEvento,
                title: item.titulo,
                category: "time",
                dueDateClass: "",
                start: new Date(item.dataInicio).toISOString(),
                end: new Date(item.dataFim).toISOString(),
                bgColor: "#F87FB4",
                color: "#ffff",
                raw: item.uriUnico,
                location: t("Evento interno"),
              });
              break;

            default:
              break;
          }
        }
      });
      setSchedules(eventosAgendados);
    };

    div.appendChild(botaoVerEvento);
    div.appendChild(botaoDeletarEvento);
    conteudo[0].appendChild(div);
  }

  return (
    <div>
      <Row>
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          {width > 767 && (
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <Row className="d-flex justify-content-between mt-5">
                <h1 id="titulo-calendario">{t("Meus eventos")}</h1>
                <div>
                  <button
                    className={
                      visualizacaoCalendario === "week"
                        ? "btn-cabecalho-container-full-black"
                        : "btn-cabecalho-container"
                    }
                    onClick={() => {
                      setVisualizacaoCalendario("week");
                    }}
                  >
                    {t("Semanal")}
                  </button>

                  <button
                    className={
                      visualizacaoCalendario === "day"
                        ? "btn-cabecalho-container-full-black"
                        : "btn-cabecalho-container"
                    }
                    onClick={() => {
                      setVisualizacaoCalendario("day");
                    }}
                  >
                    {t("Diário")}
                  </button>
                </div>
              </Row>
            </Col>
          )}

          <Row className="container-cabecalho-calendario">
            <Col
              xs="3"
              sm="3"
              md="3"
              lg="3"
              xl="3"
              className="btn-calendario-anterior"
            >
              <button
                className="botao-proximo-esquerda"
                onClick={() => trocarCalendario("prev")}
              >
                <img
                  src={setaEsquerda}
                  alt="Seta esquerda"
                  id="seta-esquerda-calendario"
                />
              </button>
            </Col>
            <Col xs="6" sm="6" md="6" lg="6" xl="6">
              <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                  <h3 id="data-calendario">{tituloCalendario}</h3>
                </Col>
              </Row>
            </Col>
            <Col
              xs="3"
              sm="3"
              md="3"
              lg="3"
              xl="3"
              className="btn-calendario-proximo"
            >
              <button
                className="botao-proximo-direita"
                onClick={() => trocarCalendario("next")}
              >
                <img
                  src={setaDireita}
                  alt="Seta direita"
                  id="seta-direita-calendario"
                />
              </button>
            </Col>
          </Row>
        </Col>

        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <Calendar
            ref={calendarioRef}
            disableCalendars={true}
            disableDblClick={true}
            usageStatistics={false}
            disableClick={false}
            taskView={false}
            isReadOnly={true}
            scheduleView={["time"]}
            schedules={schedules}
            onClickSchedule={(e) => onDetalhes(e)}
            template={template}
            useDetailPopup
            view={visualizacaoCalendario}
            week={{
              daynames: nomesDiaSemanaIdioma[i18n.language],
              startDayOfWeek: 0,
              narrowWeekend: false,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
