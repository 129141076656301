import { useField } from "formik";
import React from "react";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { useTranslation } from "react-i18next";

export default function SelectMultiInput({
  label,
  classes = "",
  options = [],
  obrigatorio,
  disabled = false,
  ...props
}) {
  const [field, meta] = useField(props);
  const { t } = useTranslation();

  const animatedComponents = makeAnimated();

  return (
    <div className={classes}>
      <label htmlFor={props.id || props.name}>
        {label}{" "}
        {obrigatorio && (
          <>
            <span className="required-hash">*</span>
          </>
        )}
      </label>
      <div className="input-block">
        <Select
          placeholder={t("Selecione")}
          options={options}
          components={animatedComponents}
          isDisabled={disabled}
          {...field}
          {...props}
        />
        {props.loading && (
          <i className="pi pi-spin pi-spinner input-loading"></i>
        )}
      </div>
      <div className="error-input">
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}
