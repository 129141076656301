/* eslint-disable react-hooks/exhaustive-deps */
import AccordionModulos from "components/AccordionModulos";
import CardPalestrante from "components/CardPalestranteCurso";
import CursoDescription from "components/CursoDescription";
import HeaderCursos from "components/SubHerderCursos/HeaderCursos.js/HeaderCursos";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CursosService from "services/cursos.service";
import { AppContext } from "store";
import "./style.scss";

function PageCurso() {
  const { id } = useParams();
  const history = useHistory()
  const [curso, setCurso] = useState();
  const { t, i18n } = useTranslation();
  const { user } = useContext(AppContext);

  const cursosService = new CursosService();

  useEffect(() => {
    const carregarCursos = async () => {
      try {
        const response = await cursosService.read(id);
        setCurso(response);
      } catch (error) {
        console.error("Erro ao carregar cursos:", error);
      }
    };

    if (![4,5].includes(user.perfil.id)) {
      history.push("/home");
    }

    carregarCursos();
  }, [i18n.language]);

  return (
    <div className="container-page-curso">
      {curso ? (
        <>
          <HeaderCursos curso={curso} />
          <div className="curso-descricao">
            <CardPalestrante instrutor={curso.instrutor} />
            <CursoDescription
              title={curso.titulo}
              description={curso.descricao}
            />
          </div>

          <AccordionModulos modulos={curso.modulos} />
        </>
      ) : (
        <p>{t("Carregando...")}</p>
      )}
    </div>
  );
}

export default PageCurso;
