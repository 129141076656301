import React from "react";
import ImagemPerfil from "../../assets/img/user.svg";
import "./style.scss";

function CardPalestrante({ instrutor }) {
  // const image = ${process.env.REACT_APP_API_URL}curso/instrutores/${instrutor.idCursoInstrutor}/display
  let image = ImagemPerfil;

  if (instrutor.imagemUrl) {
    image = `${process.env.REACT_APP_API_URL}curso/instrutores/${instrutor.idCursoInstrutor}/display`;
  }

  return (
    <div className="card-palestrante">
      <div className="imagem-palestrante">
        <img src={image} alt={instrutor.nome} />
      </div>
      <div className="info-palestrante">
        <h3>{instrutor.nome}</h3>
        <p>{instrutor.biografia}</p>
      </div>
    </div>
  );
}

export default CardPalestrante;
