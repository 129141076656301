// cursos.service.js
import CoreApiService from "./core-api.service";
import api from "./api.service";
import CursosSerializer from "./serializers/cursos.serializer";

export default class CursosService extends CoreApiService {
  constructor() {
    super(undefined, "cursos", new CursosSerializer());
  }

  async list(queryOptions = null, isListView = null) {
    const queryString = queryOptions ? queryOptions.toQueryString() : "";

    const response = await api.get(`${this.endpoint}?${queryString}`);
    const { data } = response;

    return isListView && data.items
      ? this.convertData(data, data._meta)
      : this.convertData(data);
  }

  async getCursoId(id) {
    try {
      const response = await api.get(`${this.endpoint}/${id}`);
      const { data } = response;

      if (data) {
        if (Array.isArray(data)) {
          return this.convertData({ items: data }); // Se for um array, envolva em um objeto com a propriedade items
        } else if (data.hasOwnProperty("items")) {
          return this.convertData(data); // Se tiver a propriedade items, use diretamente
        } else {
          return this.convertData({ items: [data] }); // Se for um único objeto, coloque em um array
        }
      } else {
        console.error("Os dados não estão presentes ou são inválidos");
        return null;
      }
    } catch (error) {
      console.error("Erro ao obter curso por ID:", error);
      return null;
    }
  }
}
