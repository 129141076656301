import { ptBR, es, enUS } from "date-fns/locale";

export const mapData = {
  "pt-BR": ptBR,
  en: enUS,
  es: es,
};

export const enumLocale = {
  "pt-BR": 1,
  en: 2,
  es: 3,
};

export default function getDataLocale() {
  const i18nLng = localStorage.getItem("i18nextLng");

  if (i18nLng) {
    return mapData[i18nLng];
  }

  return mapData["pt-BR"];
}

export function getLocaleId() {
  const i18nLng = localStorage.getItem("i18nextLng");

  if (i18nLng) {
    return enumLocale[i18nLng];
  }

  return enumLocale["pt-BR"];
}
