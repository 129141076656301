import BaseSerializer from "./base.serializer";
import VideoIdiomaSerializer from "./video-idioma.serializer";

export default class VideoSerializer extends BaseSerializer {
  fromJson(json) {
    const video = {};

    Object.assign(
      video,
      json.id_moderacao_video && { id: json.id_moderacao_video },
      json.id_expositor && { idExpositor: json.id_expositor },
      { disabled: json.disabled || false },
      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new VideoIdiomaSerializer()),
      }
    );

    return video;
  }
}
