/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { AppContext } from "store";
import ExpositoresService from "../../../../services/expositores.service";

export function VideoContainer({ setLoading }) {
  const { idExpositor } = useContext(AppContext);
  const [expositor, setExpositor] = useState();
  const [videoValido, setVideoValido] = useState(false);
  const [videoSobreExpositorUrl, setVideoSobreExpositorUrl] = useState();
  const { i18n } = useTranslation();
  const _expositoresService = new ExpositoresService();

  function verificarUrlYoutubeValida(url) {
    var id = "";
    let urlEstrutura = url.split("/");
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (
      url[2] !== undefined &&
      urlEstrutura.length === 4 &&
      urlEstrutura[0] === "https:" &&
      urlEstrutura[1] === "" &&
      urlEstrutura[2] === "www.youtube.com" &&
      typeof urlEstrutura[3] === "string"
    ) {
      id = url[2].split(/[^0-9a-z_-]/i);
      id = id[0];
      setVideoValido(true);
      setVideoSobreExpositorUrl(`https://www.youtube.com/embed/${id}`);
      return true;
    } else {
      return false;
    }
  }

  function verificarUrlVimeoValida(url) {
    var id = url.split("/").slice(-1)[0].split("?")[0];
    url = url.split("/");

    if (
      url.length === 4 &&
      url[0] === "https:" &&
      url[1] === "" &&
      url[2] === "vimeo.com" &&
      !isNaN(url[3])
    ) {
      setVideoValido(true);
      setVideoSobreExpositorUrl(`https://player.vimeo.com/video/${id}`);
      return true;
    } else {
      return false;
    }
  }

  function verificarUrlVideoValida(url) {
    if (verificarUrlYoutubeValida(url)) {
      return true;
    } else if (verificarUrlVimeoValida(url)) {
      return true;
    } else {
      setVideoValido(false);
      return false;
    }
  }

  useEffect(() => {
    async function init() {
      const expositores = await _expositoresService.read(idExpositor);
      setExpositor(expositores);

      if (expositores.linkVideo) {
        verificarUrlVideoValida(expositores.linkVideo);
      }
      setLoading(false);
    }

    init();
  }, [idExpositor, i18n.language]);

  return (
    <div style={{ padding: "3.984rem 0" }}>
      {videoValido && (
        <div className="video-container">
          <Container>
            <Row>
              <Col xs="12" sm="12" md="12" lg="7" xl="7">
                <iframe
                  width="90%"
                  height="540px"
                  src={videoSobreExpositorUrl}
                  title={expositor?.tituloVideo}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </Col>

              <Col xs="12" sm="12" md="12" lg="5" xl="5">
                <div className="container-info">
                  <h1>{expositor?.tituloVideo}</h1>
                  <p>{expositor?.descricaoVideo}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
}
