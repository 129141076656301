import api from "./api.service";
import CoreApiService from "./core-api.service";
import CargoSerializer from "./serializers/cargo.serializer";

export default class CargoService extends CoreApiService {
  constructor() {
    super("plataforma", "usuario-cargos", new CargoSerializer());
  }

  async listarCargosCadastro() {
    const response = await api.get(`${this.parentEndpoint}/${this.endpoint}`);

    const data = response.data;
    return this.convertData(data);
  }
}
