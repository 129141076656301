export default class CursoIdiomaSerializer {
  fromJson(json) {
    const idioma = {};

    Object.assign(
      idioma,
      json.id_curso_idioma && { idCursoIdioma: json.id_curso_idioma },
      json.id_idioma && { idIdioma: json.id_idioma },
      json.titulo && { titulo: json.titulo },
      json.descricao && { descricao: json.descricao },
      json.image_filename && { imageFilename: json.image_filename }
    );

    return idioma;
  }
}
