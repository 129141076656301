import SelecionarIdioma from "components/SelecionarIdioma";
import TextInput from "components/TextInput";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Col, FormGroup, Row } from "reactstrap";
import UsuarioService from "services/usuario.service";
import * as Yup from "yup";
import ContainerVisitante from "./ContainerVisitante";

export default function RecuperarSenha() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const _usuarioService = new UsuarioService();

  async function onSubmit(data) {
    setLoading(true);

    _usuarioService
      .recuperarSenha(data)
      .then(() => {
        setEmail(data.email);
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setEmail("");
        setError(true);
        setLoading(false);
      });
  }

  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("Digite um e-mail válido"))
            .required(t("Obrigatório")),
        })}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        <div className="login_wrapper">
          <ContainerVisitante />

          <Col xl="6" lg="6" md="6" sm="12" xs="12">
            <Row className="row-right">
              <div className="login_text_form">
                <SelecionarIdioma />

                {!email ? (
                  <div className="login_text">
                    <h1>{t("Recuperar Senha")}</h1>
                    <p style={{ maxWidth: 342 }}>
                      {t(
                        "Digite seu email cadastrado para receber instruções para recuperar sua senha."
                      )}
                    </p>
                  </div>
                ) : null}

                <Form
                  className="form"
                  autoComplete="off"
                  style={{ zIndex: "1" }}
                >
                  {!email ? (
                    <>
                      <Row style={{ flexDirection: "column" }}>
                        <Col sm="12">
                          <FormGroup>
                            <TextInput
                              className="form-control"
                              name="email"
                              placeholder="E-mail"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <button
                        loading={loading.true}
                        type="submit"
                        styles="w-all"
                        className="mr-1 btn-login"
                      >
                        Enviar
                      </button>
                    </>
                  ) : null}

                  {error && (
                    <div className="login_text " style={{ marginTop: 20 }}>
                      <p style={{ maxWidth: 342 }}>
                        {t("Por favor, tente novamente.")}
                      </p>
                    </div>
                  )}

                  {email && (
                    <div className="login_text " style={{ marginTop: 20 }}>
                      <p style={{ maxWidth: 342 }}>
                        <Trans i18nKey="Recuperar senha sucesso" numero={email}>
                          Caso o email <strong>{{ email }}</strong> esteja
                          cadastrado no sistema, uma mensagem será enviada ao
                          endereço informado com instruções para recuperação da
                          senha.
                        </Trans>
                      </p>
                    </div>
                  )}

                  <div
                    className="text-link"
                    onClick={() => history.push("/entrar")}
                  >
                    {t("Voltar para Login")}
                  </div>
                </Form>
              </div>
            </Row>
          </Col>
        </div>
      </Formik>
    </>
  );
}
