import BaseSerializer from "./base.serializer";
import CursoAulaSerializer from "./curso-aula.serializer";
import CursoModuloIdiomaSerializer from "./curso-modulo-idioma.serializer";

export default class CursoModuloSerializer extends BaseSerializer {
  constructor() {
    super();
    this.cursoModuloIdiomaSerializer = new CursoModuloIdiomaSerializer();
    this.cursoAulaSerializer = new CursoAulaSerializer();
  }

  fromJson(json) {
    const cursoModulo = {};

    Object.assign(
      cursoModulo,
      json.id_curso_modulo && { idCursoModulo: json.id_curso_modulo },
      json.id_curso && { idCurso: json.id_curso },
      {
        aulas: json.aulas
          ? json.aulas.map((item) => this.cursoAulaSerializer.fromJson(item))
          : [],
      },
      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new CursoModuloIdiomaSerializer()),
      }
    );

    return cursoModulo;
  }
}
