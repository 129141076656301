/* eslint-disable react-hooks/exhaustive-deps */
import SelecionarIdioma from "components/SelecionarIdioma";
import { format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import "./styles.scss";

export default function Visitante() {
  const { t } = useTranslation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return (
    <>
      <div className="login_wrapper">
        <div className="container-visitante">
          <div className="bg-visitante">
            <div className="container-info-visitante">
              {/* <p>
                {format(
                  new Date(
                    new Date(params.data_inicio).valueOf() +
                      new Date(params.data_inicio).getTimezoneOffset() *
                        60 *
                        1000
                  ),
                  "d"
                )}
                –{" "}
                {format(
                  new Date(
                    new Date(params.data_fim).valueOf() +
                      new Date(params.data_fim).getTimezoneOffset() * 60 * 1000
                  ),
                  "d"
                )}
                <br />
                <span className="mes-edicao">
                  {format(new Date(params.data_inicio), "MMMM", {
                    locale: getDataLocale(),
                  })}
                </span>
                <br />
                2023
              </p>
              <br />
              <p>
                SÃO <br /> PAULO <br />
                BR
              </p> */}
            </div>
          </div>
        </div>

        <div className="container-login">
          {" "}
          <div className="login_wrapper-visitante">
            <div className="login_text_form login-text-form-visitante">
              <SelecionarIdioma />

              <div>
                <h1 style={{ width: "271.22px" }}>
                  <Trans i18nKey="Visitante Pagina">
                    Nos vemos dia{" "}
                    {format(
                      new Date(
                        new Date(params.data_inicio).valueOf() +
                          new Date(params.data_inicio).getTimezoneOffset() *
                            60 *
                            1000
                      ),
                      "d"
                    )}{" "}
                    a partir das{" "}
                    {format(
                      new Date(
                        new Date(params.data_inicio).valueOf() +
                          new Date(params.data_inicio).getTimezoneOffset() *
                            60 *
                            1000
                      ),
                      "hh:ss"
                    )}
                    !
                  </Trans>
                </h1>
                <div>
                  <span
                    className="text-link"
                    onClick={() => history.push("/sair")}
                  >
                    {t("Sair")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
