import BaseSerializer from "./base.serializer";
import SegmentoIdiomaSerializer from "./segmento-idioma.serializer";

export default class SegmentoSerializer extends BaseSerializer {
  fromJson(json) {
    const segmento = {};

    Object.assign(
      segmento,
      json.id_segmento && { id: json.id_segmento, value: json.id_segmento },
      json.data_criacao && { dataCriacao: json.data_criacao },
      json.segmento_idiomas && {
        ...this.idiomaAtual(
          json.segmento_idiomas,
          new SegmentoIdiomaSerializer()
        ),
      }
    );

    return segmento;
  }
}
