import AvertisingdLanguageSerializer from "./propaganda-idioma.serializer";
import { format } from "date-fns";
import BaseSerializer from "./base.serializer";
import convertDate from "utils/conversor-data";
import { fromDateGmt } from "utils/format-date";

export default class AvertisingdSerializer extends BaseSerializer {
  fromJson(json) {

    const avertisingdLanguageSerializer = {};
    Object.assign(
      avertisingdLanguageSerializer,
      json.id_publicidade && { id: json.id_publicidade },
      json.id_publicidade_area && {
        idPublicidadeArea: json.id_publicidade_area,
      },
      json.areas && {
        areas: json.areas.map((item) => item),
      },
      json.disabled && { disabled: json.disabled },

      json.id_edicao && { idEdicao: json.id_edicao },
      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new AvertisingdLanguageSerializer()),
      },
      json.data_inicio && {
        dataInicio: fromDateGmt(json.data_inicio),
        dataInicioStr: format(fromDateGmt(json.data_inicio), "dd/MM/yyyy"),
      },
      json.data_fim && {
        dataFim: fromDateGmt(json.data_fim),
        dataFimStr: format(fromDateGmt(json.data_fim), "dd/MM/yyyy"),
      }
    );

    if (avertisingdLanguageSerializer.disabled) {
      avertisingdLanguageSerializer.statusStr = "Inativo";
    } else {
      avertisingdLanguageSerializer.statusStr = "Ativo";
    }

    return avertisingdLanguageSerializer;
  }

  toJson(avertisingd) {
    const avertising = {};

    Object.assign(
      avertising,
      avertisingd.idPublicidadeArea && {
        id_publicidade_area: avertisingd.idPublicidadeArea,
      },
      { id_edicao: 1 },
      avertisingd.idiomas && {
        idiomas: avertisingd.idiomas.map((item) =>
          this.avertisingdLanguageSerializer.toJson(item)
        ),
      },

      avertisingd.dataInicio && {
        data_inicio: convertDate(avertisingd.dataInicio),
      },
      avertisingd.dataFim && { data_fim: convertDate(avertisingd.dataFim) },

      avertisingd.disabled ? { disabled: true } : { disabled: false }
    );

    return avertising;
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("file", file[0]);

    return formData;
  }
}
