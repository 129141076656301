import BaseSerializer from "./base.serializer";
import CursoAulaIdiomaSerializer from "./curso-aula-idioma.serializer";

class CursoAulaSerializer extends BaseSerializer {
  fromJson(json) {
    const aula = {};

    Object.assign(
      aula,
      json.id_curso_aula && { idCursoAula: json.id_curso_aula },
      json.data_criacao && { dataCriacao: json.data_criacao },
      json.duracao && { duracao: json.duracao },
      json.id_curso_modulo && { idCursoModulo: json.id_curso_modulo },
      json.data_ultima_alteracao && {
        dataUltimaAlteracao: json.data_ultima_alteracao,
      },
      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new CursoAulaIdiomaSerializer()),
      },
    );

    return aula;
  }
}

export default CursoAulaSerializer;
