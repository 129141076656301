import { format } from "date-fns";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import getDataLocale from "utils/map-data";
import { useTranslation } from "react-i18next";
import UsuarioService from "services/usuario.service";

export default function ModalMaisInfoSobreEvento({
  modalAberto,
  setModal,
  evento,
  eventosAgendados,
  setEventosAgendados,
}) {
  const [time] = useState(new Date().getTime());
  const history = useHistory();
  const { t } = useTranslation();

  function verificarRotuloBotao(evento) {
    if (eventosAgendados?.length) {
      for (let index = 0; index < eventosAgendados?.length; index++) {
        const elemento = eventosAgendados[index];

        if (elemento?.idEvento === evento?.id) {
          return t("Agendado");
        }
      }

      return t("Agendar");
    } else {
      return t("Agendar");
    }
  }

  return (
    <div>
      <Modal
        centered
        fullscreen="sm"
        isOpen={modalAberto}
        toggle={() => {
          setModal(false);
        }}
        className="modal-evento"
      >
        <ModalBody>
          <Container>
            <Row>
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <img
                  className="imagem-banners imagem-evento"
                  src={`${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time}`}
                  alt={evento?.titulo}
                />
              </Col>

              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <div style={{ margin: "5px 0 0 5px" }}>
                  {evento ? (
                    <>
                      <h1>
                        {format(evento?.dataInicio, "dd")} de{" "}
                        {format(evento?.dataInicio, "MMMM", {
                          locale: getDataLocale(),
                        })}
                      </h1>
                    </>
                  ) : null}
                </div>
                <div className="evento-data" style={{ marginLeft: "5px" }}>
                  {evento ? (
                    <p>
                      {format(evento?.dataInicio, "H:mm")} -{" "}
                      {format(evento?.dataFim, "H:mm")}
                    </p>
                  ) : null}
                </div>
                <div className="evento-info-container">
                  <h4 className="evento-categoria">
                    {evento?.eventoCategoria}
                  </h4>
                  <h4 className="evento-palestrante-title">
                    {evento?.palestrante && "Palestrante"}
                  </h4>
                  <h4 className="evento-palestrante">{evento?.palestrante}</h4>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row style={{ padding: "20px 0" }}>
              <Col>
                <h2 className="titulo-evento">{evento?.titulo}</h2>
                <p className="descricao-evento">{evento?.descricao}</p>
              </Col>
            </Row>
          </Container>
        </ModalBody>

        <ModalFooter className="modal-footer">
          <Container>
            <Row>
              <Col xs="12" sm="12" md="12" lg="12" xl="12" className="col-botoes-modal-footer">
                {evento?.eventoExterno && (
                  <Button
                    href={evento?.urlVideo}
                    target="_blank"
                    className="mr-1 btn-event-dark btn btn-info"
                  >
                    {t("Saiba mais")}
                  </Button>
                )}

                {!evento?.eventoExterno && (
                  <Button
                    onClick={() => {
                      history.push(`/evento/${evento.uriUnico}`);
                    }}
                    target="_blank"
                    className="mr-1 btn-event-dark btn btn-info"
                  >
                    {t("Ir para o evento")}
                  </Button>
                )}

                <Button
                  target="_blank"
                  className="mr-1 btn-event-dark btn btn-info"
                  onClick={async () => {
                    const _usuarioService = new UsuarioService();
                    const usuarioServiceResposta =
                      await _usuarioService.agendarEventos({
                        id_evento: evento.id,
                      });

                    setEventosAgendados(
                      usuarioServiceResposta.eventosAgendados
                    );
                  }}
                >
                  {verificarRotuloBotao(evento)}
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      </Modal>
    </div>
  );
}
