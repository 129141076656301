export default function EnviarWhatsapp(texto, telefone = "") {
  if (telefone) {
    telefone = `phone=${telefone}&`;
  }

  const textoParse = window.encodeURIComponent(texto);
  const anchor = document.createElement("a");
  anchor.href = `https://api.whatsapp.com/send?${telefone}text=${textoParse}`;
  anchor.target = "_blank";
  anchor.click();
}
