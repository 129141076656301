import EventLanguageSerializer from "./evento-idioma.serializer";
import { format } from "date-fns";
import BaseSerializer from "./base.serializer";
import convertDate from "utils/conversor-data";
import ExpositoresSerializer from "./expositores.serializer";
import { fromDateGmt } from "../../utils/format-date";

export default class EventSerializer extends BaseSerializer {
  constructor() {
    super();
    this.expositorSerializer = new ExpositoresSerializer();
  }

  fromJson(json) {
    const event = {};
    Object.assign(
      event,
      json.destaque && { destaque: json.destaque },
      json.evento_patrocinado_home && {
        eventoPatrocinadoHome: json.evento_patrocinado_home,
      },
      json.evento_externo && { eventoExterno: json.evento_externo },
      json.id_evento_categoria && {
        idEventoCategoria: json.id_evento_categoria,
      },
      json.url_chat && { urlChat: json.url_chat },
      json.nome_palestrante && { palestrante: json.nome_palestrante },
      json.evento_externo && { eventoExterno: json.evento_externo },
      json.id_expositor && { idExpositor: json.id_expositor },
      json.id_evento && { id: json.id_evento },
      json.uri_unico && { uriUnico: json.uri_unico },
      json.url_video && { urlVideo: json.url_video },
      json.expositor && {
        expositor: this.expositorSerializer.fromJson(json.expositor),
      },
      json.data_inicio && {
        dataInicio: fromDateGmt(json.data_inicio),
        dataInicioStr: format(fromDateGmt(json.data_inicio), "dd/MM/yyyy"),
      },
      json.data_fim && {
        dataFim: fromDateGmt(json.data_fim),
        dataFimStr: format(fromDateGmt(json.data_fim), "dd/MM/yyyy"),
      },
      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new EventLanguageSerializer()),
      },
      json.id_edicao && { idEdicao: json.id_edicao },
      json.filename && { filename: json.filename }
    );

    if (json.id_evento_categoria === 1) {
      event.eventoOficial = true;
      event.eventoCategoria = "Evento oficial";
    } else if (json.id_evento_categoria === 2) {
      event.eventoParceiro = true;
      event.eventoCategoria = "Evento parceiro";
    } else if (json.id_evento_categoria === 3) {
      event.eventoExpositor = true;
      event.eventoCategoria = "Evento expositor";
    }

    return event;
  }

  toJson(event) {
    return {
      data_inicio: convertDate(event.dataInicio),
      data_fim: convertDate(event.dataFim),
      url_video: event.urlVideo,
      id_edicao: event.idEdicao,
      idiomas: event.idiomas.map((item) =>
        this.eventLanguageSerializer.toJson(item)
      ),
      id_expositor: event.idExpositor,
    };
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("files", file[0]);

    return formData;
  }
}
