export default class BannerExpositorLanguageSerializer {
  fromJson(json) {
    return {
      link: json.link,
      idIdioma: json.id_idioma,
      idExpositorBannerIdioma: json.id_expositor_banner_idioma,
      imageFilename: json.image_filename,
      imageFilenameMobile: json.image_filename_mobile
    };
  }

  toJson(bannerExpositorLanguage) {
    const bannerExpositorLanguageToJson = {};

    Object.assign(
      bannerExpositorLanguageToJson,
      bannerExpositorLanguage.link && { link: bannerExpositorLanguage.link },
      bannerExpositorLanguage.idIdioma && {
        id_idioma: bannerExpositorLanguage.idIdioma,
      },
      bannerExpositorLanguage.idExpositorBannerIdioma && {
        id_expositor_banner_idioma:
          bannerExpositorLanguage.idExpositorBannerIdioma,
      }
    );
    return bannerExpositorLanguageToJson;
  }
}
