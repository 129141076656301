/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import BannerEventosAoVivo from "components/expo-revestir/BannerEventosAoVivo";
import { format, isAfter, isBefore, isEqual } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import MonitoramentoService from "services/monitoramento.service";
import getDataLocale from "utils/map-data";
import EventService from "../../../services/evento.service";

export default function EventosDoExpositor() {
  const { id } = useParams();
  const [evento, setEvento] = useState();
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const [logosEvento, setLogosEvento] = useState();
  const [logoPossuiImagem, setLogoPossuiImagem] = useState();

  function permanencia(evento) {
    const _monitoramentoService = new MonitoramentoService();
    setInterval(() => {
      try {
        const data = new Date();

        if (
          (isBefore(evento.dataInicio, data) ||
            isEqual(data, evento.dataInicio)) &&
          (isAfter(evento.dataFim, data) || isEqual(data, evento.dataFim))
        ) {
          _monitoramentoService.permanencia(evento.id);
        }
      } catch (err) {
        console.log(err);
      }
    }, 60000);
  }

  useEffect(() => {
    async function init() {
      const _eventosExpositor = new EventService();

      const eventoDoExpositor = await _eventosExpositor.readUri(id);

      if (!eventoDoExpositor.hasOwnProperty("id")) {
        history.push("/expositores");
      }

      setEvento(eventoDoExpositor);

      const eventoLogos = await _eventosExpositor.getLogo(
        eventoDoExpositor?.id
      );
      setLogosEvento(eventoLogos.logos);

      eventoLogos.logos.forEach((item) => {
        if (item.image_filename) {
          setLogoPossuiImagem(true);
        }
      });

      permanencia(eventoDoExpositor);
    }

    init();
  }, [id, i18n.language]);

  useEffect(() => {
    if (evento?.uriUnico === "diadoarquiteto") {
      function atualizarHorario() {
        var dataAtual = new Date().toLocaleString("pt-br", {
          timeZone: "America/Sao_Paulo",
        });
        var dataAtualFormatada = dataAtual.replace(", ", " - ");

        var dataFimEvento = evento.dataFim.toLocaleString("pt-br", {
          timeZone: "America/Sao_Paulo",
        });
        var dataFimEventoFormatada = dataFimEvento.replace(", ", " - ");

        if (dataFimEventoFormatada === dataAtualFormatada) {
          history.push("/evento/revistaprojeto");
        }
      }
      setInterval(atualizarHorario, 1000);
    }
  }, [evento]);

  function verificarTipoEvento() {
    if (evento?.hasOwnProperty("expositor")) {
      history.push(`/expositor/${evento?.expositor?.uriUnico}/eventos`);
    } else {
      history.push(`/programacao-eventos`);
    }
  }
  
  return (
    <>
      <Container>
        <div className="voltar-eventos">
          <p onClick={() => verificarTipoEvento()}>
            {t("Voltar para programação")}
          </p>
        </div>

        <div className="evento-titulo">
          <h1>{evento?.titulo}</h1>
        </div>

        <div className="evento-data">
          <div className="data-inicio">
            {evento ? (
              <h3>
                {format(evento?.dataInicio, "dd")} de{" "}
                {format(evento?.dataInicio, "MMMM", {
                  locale: getDataLocale(),
                })}
              </h3>
            ) : null}
          </div>
          <div className="horario-evento" id="horario-evento">
            {evento ? (
              <h3>
                {format(evento?.dataInicio, "H:mm")} -{" "}
                {format(evento?.dataFim, "H:mm")}
              </h3>
            ) : null}
          </div>
        </div>

        <div className="evento-descricao-container">
          <h4>{evento?.descricao}</h4>
        </div>

        {!evento?.urlChat && !evento?.urlVideo ? (
          <div
            className={
              evento?.urlChat
                ? "evento-container"
                : "container-video-centralizado"
            }
          >
            <div className="evento-video-container">
              <Row>
                <Col>
                  <h1>{t("O vídeo estará disponível em breve")}</h1>
                </Col>
                <Col>
                  <lottie-player
                    src="https://assets1.lottiefiles.com/packages/lf20_555bmxkx.json"
                    background="transparent"
                    speed="1"
                    style={{ width: "500px", height: "500px" }}
                    loop
                    autoplay
                  />
                </Col>
              </Row>
            </div>
            {evento && <BannerEventosAoVivo evento={evento} />}
          </div>
        ) : (
          <div className="main-container-evento">
            <div
              className={
                evento?.urlChat
                  ? "evento-container"
                  : "container-video-centralizado"
              }
            >
              <div className="evento-video-container">
                <iframe
                  src={evento?.urlVideo}
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  className="iframe-video"
                  width={evento?.urlChat ? "796px" : "100%"}
                  height={evento?.urlChat ? "600px" : "670x"}
                />
              </div>

              {evento?.urlChat && (
                <div className="evento-chat-container">
                  <iframe
                    className="iframe-chat"
                    src={evento?.urlChat}
                    width="396px"
                    height="600px"
                    frameborder="0"
                  />
                </div>
              )}
            </div>
            {evento && <BannerEventosAoVivo evento={evento} />}
          </div>
        )}

        <Row>
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            className="evento-texto-descritivo-container"
          >
            <p>{evento?.textoDescritivo}</p>
          </Col>
        </Row>
      </Container>
      {logosEvento?.length && logoPossuiImagem && (
        <div className="evento-logos-bg">
          <Container>
            <Row className="row-container-logos">
              {logosEvento?.map((logo, index) => (
                <>
                  {logo.image_filename && (
                    <Col
                      key={index}
                      xs="6"
                      sm="6"
                      md="3"
                      lg="2"
                      xl="2"
                      className="col-container-logos"
                    >
                      <h3 className="titulo-logo-container">{logo?.titulo}</h3>
                      <div className="logotipo-container">
                        <img
                          src={`${
                            process.env.REACT_APP_API_URL
                          }eventos/logotipos/${
                            logo.id_evento_logotipo
                          }/display?${new Date().getTime()}`}
                          alt="Banner do evento"
                        />
                      </div>
                    </Col>
                  )}
                </>
              ))}
            </Row>
          </Container>
        </div>
      )}
    </>
  );
}
