import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import EventService from "services/evento.service";
import QueryOptions from "../../utils/query-options";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import getDataLocale from "utils/map-data";
import ModalMaisInfoSobreEvento from "components/expo-revestir/ModalMaisInfoSobreEvento";
import { Col, Container, Row } from "reactstrap";
import history from "services/history";
import EditionService from "../../services/edition.service";

export default function EventoDoDia({ campoCustomizado }) {
  const [eventosDoDia, setEventosDoDia] = useState([]);
  const { t, i18n } = useTranslation();
  const [modalAberto, setModalAberto] = useState(false);
  const [evento, setEvento] = useState();
  const [time] = useState(new Date().getTime());

  useEffect(() => {
    async function init() {
      const _eventosService = new EventService();

      const _edicaoService = new EditionService();
      const queryOptionEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
        queryOptionEdicaoAtiva
      );

      const queryOptions = new QueryOptions([
        ["destaque_do_dia", ""],
        ["paginacao", 1],
        ["per_page", 2],
        ["edicao", edicaoAtual.id],
      ]);

      const eventoResponse = await _eventosService.list(queryOptions);
      setEventosDoDia(eventoResponse);
    }
    init();
  }, [i18n.language]);

  return (
    <>
      {eventosDoDia.length ? (
        <div className="sessao-eventos-do-dia">
          <Container>
            <div className="eventos-do-dia-title-container">
              <h1>{campoCustomizado[12]?.item}</h1>
            </div>

            <ModalMaisInfoSobreEvento
              modalAberto={modalAberto}
              setModal={setModalAberto}
              evento={evento}
            />

            <Row className="card-eventos-container">
              {eventosDoDia.map((evento) => (
                <Col xs="12" sm="12" md="8" lg="6" xl="6">
                  <Card className="card-eventos">
                    <div
                      className="borda-evento"
                      onClick={() => {
                        setModalAberto(!modalAberto);

                        setEvento(evento);
                      }}
                    >
                      <div className="imagem-evento">
                        <div className="data-evento">
                          <p>{format(evento.dataInicio, "dd")}</p>
                          <p>
                            {format(evento.dataInicio, "MMM", {
                              locale: getDataLocale(),
                            })}
                          </p>
                        </div>

                        <div>
                          <CardMedia
                            component="img"
                            image={`${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time}`}
                          />
                        </div>
                      </div>

                      <div className="evento-info">
                        <Container>
                          <div className="evento-do-dia-header">
                            {evento.idEventoCategoria === 1 && (
                              <div
                                style={{
                                  backgroundColor: "#52C9E8",
                                  padding: "4px 4px",
                                  marginRight: '8px',
                                }}
                              >
                                <p style={{ margin: "0", color: "#fff" }}>
                                  {t("Evento oficial")}
                                </p>
                              </div>
                            )}

                            {evento.idEventoCategoria === 2 && (
                              <div>
                                <h6>{t("Evento parceiro")}</h6>
                              </div>
                            )}

                            {evento.idEventoCategoria === 3 && (
                              <div>
                                <h6>{t("Evento expositor")}</h6>
                              </div>
                            )}

                            <div>
                              <p
                                style={{
                                  margin: "0",
                                  color: "#fff",
                                  backgroundColor: "#93D501",
                                  padding: "4px 4px",
                                }}
                              >
                                {t("Plataforma Expo Revestir")}
                              </p>
                            </div>
                          </div>
                        <div>
                          <Typography
                            component="h5"
                            variant="h5"
                            className="tipografia-container"
                          >
                            {evento.titulo}
                          </Typography>
                        </div>

                        <div>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="p"
                          >
                            {evento.descricao}
                          </Typography>
                        </div>
                        </Container>
                        <Container>

                        <div className="evento-do-dia-footer">
                          <div className="evento-data">
                            <div className="evento-horas-data">
                              <p>
                                {format(evento.dataInicio, "H:mm")} -{" "}
                                {format(evento.dataFim, "H:mm")}
                              </p>
                            </div>
                            <div className="evento-calendario-data">
                              <p>
                                {evento.dataFimStr}
                              </p>
                            </div>
                          </div>
                          
                          <div className="button-agenda">
                            <button className="mr-1 btn-event-dark btn btn-info">
                              {t("Ver evento")}
                            </button>
                          </div>
                        </div>
                        </Container>

                      </div>
                    </div>
                    <div></div>
                  </Card>
                </Col>
              ))}
            </Row>

            <div className="calendario-eventos-do-dia-container">
              <h2
                onClick={() => {
                  history.push("/programacao-eventos");
                }}
              >
                {campoCustomizado[13]?.item}
              </h2>
            </div>
          </Container>
        </div>
      ) : null}
    </>
  );
}
