export default class CampoCustomizadoItemIdiomaSerializer {
  fromJson(json) {
    const CampoCustomizadoItemIdiomas = {
      idCampoItemCustomizadoIdioma: json.id_campo_item_customizado_idioma,
      item: json.item,
      idIdioma: json.id_idioma,
    };

    return CampoCustomizadoItemIdiomas;
  }

  toJson(campoCustomizadoIdioma) {
    return {
      id_segmento_idioma: campoCustomizadoIdioma.idCampoItemCustomizadoIdioma,
      id_idioma: campoCustomizadoIdioma.idIdioma,
      item: campoCustomizadoIdioma.item,
    };
  }
}
