import api from "./api.service";
import CoreApiService from "./core-api.service";
import ExpositoresSerializer from "./serializers/expositores.serializer";

export default class ExpositoresService extends CoreApiService {
  constructor() {
    super(undefined, "expositores", new ExpositoresSerializer());
  }

  async readPorUri(uri) {
    const response = await api.get(`${this.endpoint}/${uri}/uri`);
    const data = response.data;
    return this.serializer.fromJson(data);
  }

  async validaUri(uri, id_expositor) {
    const response = await api.get(
      `${this.endpoint}/${id_expositor}/valida-uri/${uri}`
    );
    return response.data;
  }

  async listExpositoresEdicao(
    parentId,
    queryOptions = null,
    isListView = null
  ) {
    const response = await api.get(
      `${this.endpoint}/edicoes/1?${
        (queryOptions && queryOptions.toQueryString()) || ""
      }`
    );

    const data = response.data;

    if (data.hasOwnProperty("items") && isListView) {
      return this.convertData(data, data._meta);
    } else if (data.hasOwnProperty("items")) {
      return this.convertData(data.items);
    } else {
      return this.convertData(data);
    }
  }

  async listExpositoresCurso(queryOptions = null, isListView = null) {
    const response = await api.get(
      `${this.endpoint}/cursos?${(queryOptions && queryOptions.toQueryString()) || ""}`
    );
    const data = response.data;

    if (data.items && isListView) {
      return this.convertData(data, data._meta);
    } else {
      return this.convertData(data);
    }
  }
}
