import React from "react";
import CardIcone1 from "../../assets/img/expo-revestir/images/conhecimento.svg";
import CardIcone2 from "../../assets/img/expo-revestir/images/inovacao.svg";
import CardIcone3 from "../../assets/img/expo-revestir/images/inspiracao.svg";
import { Container } from "reactstrap";

export default function Card({ campoCustomizado }) {
  return (
    <Container>
      <div className="title-container">
        <h1>
          {campoCustomizado[0]?.item}
          <br />
          {campoCustomizado[1]?.item}
        </h1>
      </div>

      <div className="paragrafo-container">
        <h3>
          {campoCustomizado[2]?.item} — {campoCustomizado[3]?.item}
        </h3>
      </div>

      <div className="cards-container">
        <div className="bg-card">
          <img src={CardIcone1} alt=""></img>
          <h3>{campoCustomizado[4]?.item}</h3>
          <p>{campoCustomizado[5]?.item}</p>
        </div>

        <div className="bg-card">
          <img src={CardIcone2} alt=""></img>
          <h3>{campoCustomizado[6]?.item}</h3>

          <p>{campoCustomizado[7]?.item}</p>
        </div>

        <div className="bg-card">
          <img src={CardIcone3} alt=""></img>
          <h3>{campoCustomizado[8]?.item}</h3>

          <p>{campoCustomizado[9]?.item}</p>
        </div>
      </div>
    </Container>
  );
}
