import BaseSerializer from "./base.serializer";
import ProductTypeSerializer from "./product-type.serializer";
import ProductImageSerializer from "./product-image.serializer";
import ProductLanguageSerializer from "./product-language.serializer";

export default class ProductsSerializer extends BaseSerializer {
  constructor() {
    super();
    this.produtoTipoSerializer = new ProductTypeSerializer();
    this.produtoImagemSerializer = new ProductImageSerializer();
  }

  fromJson(json) {
    const product = {};
    Object.assign(
      product,
      json.id_produto && { id: json.id_produto },
      json.codigo && { codigoProduto: json.codigo },
      json.link && { linkProduto: json.link },
      json.data_criacao && { dataCriacaoProduto: json.data_criacao },
      json.dimensoes && { dimensoesProduto: json.dimensoes },
      json.disabled && { disabled: json.disabled },
      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new ProductLanguageSerializer()),
      },
      json.produto_tipos && {
        idsProdutoTipo: json.produto_tipos.map((item) =>
          this.produtoTipoSerializer.fromJson(item)
        ),
      },
      json.imagens && {
        imagens: json.imagens.map((item) =>
          this.produtoImagemSerializer.fromJson(item)
        ),
      }
    );

    return product;
  }

  toJson(product) {
    return {
      codigo: product.codigoProduto,
      link: product.linkProduto,
      dimensoes: product.dimensoesProduto,
      idiomas: product.idiomas.map((item) =>
        this.productsLanguagesSerializer.toJson(item)
      ),
      ids_produto_tipo: product.idsProdutoTipo
        ? product.idsProdutoTipo.map((item) => item.value)
        : [],
    };
  }
}
