import ModalMaisInfoSobreEvento from "components/expo-revestir/ModalMaisInfoSobreEvento";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import { useTranslation } from "react-i18next";
import EventService from "services/evento.service";
import getDataLocale from "utils/map-data";
import QueryOptions from "utils/query-options";
import EditionService from "services/edition.service";

export default function CarrouselEventosNaoOficiais({ campoCustomizado }) {
  const [eventos, setEventos] = useState([]);
  const { t, i18n } = useTranslation();
  const [time] = useState(new Date().getTime());
  const [modalAberto, setModalAberto] = useState(false);
  const [evento, setEvento] = useState();

  useEffect(() => {
    async function init() {
      const _eventosService = new EventService();

      const _edicaoService = new EditionService();
      const queryOptionEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
        queryOptionEdicaoAtiva
      );
      const queryOptions = new QueryOptions([
        ["evento_patrocinado_home", "1"],
        ["edicao", edicaoAtual.id],
      ]);

      const eventoResponse = await _eventosService.list(queryOptions);
      setEventos(eventoResponse);
    }
    init();
  }, [i18n.language]);


  return (
    <>
      {eventos?.length && (
        <div className="carrosel-evento-destaque">
          <ModalMaisInfoSobreEvento
            modalAberto={modalAberto}
            setModal={setModalAberto}
            evento={evento}
          />

          <Carousel
            itemPadding={[0, 20]}
            breakPoints={[
              { width: 1, itemsToShow: 1, pagination: false },
              {
                width: 550,
                itemsToShow: 1,
                itemsToScroll: 1,
                pagination: false,
              },
              { width: 850, itemsToShow: 2 },
              { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
            ]}
            itemsToShow={4}
            outerSpacing={50}
            enableAutoPlay
            autoPlaySpeed={8000}
          >
            {eventos.map(
              (evento) =>
                ((evento.idEventoCategoria === 2 &&
                  evento.eventoPatrocinadoHome) ||
                  (evento.idEventoCategoria === 3 &&
                    evento.eventoPatrocinadoHome)) && (
                  <div
                    style={{
                      maxWidth: "410px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setModalAberto(!modalAberto);
                      setEvento(evento);
                    }}
                  >
                    <div>
                      <div className="data-evento-carrosel">
                        <span>{format(evento.dataInicio, "dd")}</span>
                        <span>
                          {format(evento.dataInicio, "MMM", {
                            locale: getDataLocale(),
                          })}
                        </span>
                      </div>

                      {evento.idEventoCategoria === 1 && (
                        <div
                          className="evento-categoria-1"
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time})`,
                          }}
                        />
                      )}

                      {evento.idEventoCategoria === 2 && (
                        <div
                          className="evento-categoria-2"
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time})`,
                          }}
                        />
                      )}

                      {evento.idEventoCategoria === 3 && (
                        <div
                          className="evento-categoria-3"
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time})`,
                          }}
                        />
                      )}
                    </div>

                    <div className="card-body">
                      <div className="tags-evento">
                        {evento.idEventoCategoria === 1 && (
                          <div className="tag-evento-categoria-1">
                            <p>{t("Evento oficial")}</p>
                          </div>
                        )}

                        {evento.idEventoCategoria === 2 && (
                          <div className="tag-evento-categoria-2">
                            <p>{t("Evento parceiro")}</p>
                          </div>
                        )}

                        {evento.idEventoCategoria === 3 && (
                          <div className="tag-evento-categoria-3">
                            <p>{t("Evento expositor")}</p>
                          </div>
                        )}

                        <div className="plataforma-evento">
                          {evento.eventoExterno ? (
                            <div className="plataforma-evento-externa">
                              <p>{t("Evento Externo")}</p>
                            </div>
                          ) : (
                            <div className="plataforma-evento-expo">
                              <p>{t("Plataforma Expo Revestir")}</p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="text-container">
                        <h3>{evento.titulo}</h3>
                        <p>{evento.descricao}</p>
                      </div>

                      <div className="card-footer-container">
                            <div className="evento-data">
                              <div className="evento-horas-data">
                                <p>
                                  {format(evento.dataInicio, "H:mm")} -{" "}
                                  {format(evento.dataFim, "H:mm")}
                                </p>
                              </div>{" "}
                              <div className="evento-calendario-data">
                                <p>{evento.dataFimStr}</p>
                              </div>{" "}
                            </div>{" "}
                            <div className="button-agenda-expositor">
                              <button className="mr-1 btn-card-event btn btn-info botao-card-evento">
                                {t("Ver evento")}
                              </button>
                            </div>
                          </div>
                    </div>
                  </div>
                )
            )}
          </Carousel>
        </div>
      )}
    </>
  );
}
