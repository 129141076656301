import React from "react";

import { Card, CardHeader, CardBody, Collapse } from "reactstrap";

export default function DuvidasFrequentes({ campoCustomizado }) {
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  return (
    <div id="acordeon">
      <div aria-multiselectable={true} id="accordion" role="tablist">
        <Card className="no-transition">
          <CardHeader className="card-collapse" id="headingOne" role="tab">
            <h5 className="mb-0 panel-title">
              <a
                aria-expanded={collapses.includes(1)}
                className="collapsed"
                data-parent="#accordion"
                href="#pablo"
                id="collapseOne"
                onClick={(e) => {
                  e.preventDefault();
                  changeCollapse(1);
                }}
              >
                {campoCustomizado[4]?.item}
                <i className="nc-icon nc-minimal-down" />
              </a>
            </h5>
          </CardHeader>

          <Collapse isOpen={collapses.includes(1)}>
            <CardBody>
              <p>{campoCustomizado[5]?.item}</p>
            </CardBody>
          </Collapse>

          <CardHeader className="card-collapse" id="headingTwo" role="tab">
            <h5 className="mb-0 panel-title">
              <a
                aria-controls="collapseTwo"
                aria-expanded={collapses.includes(2)}
                className="collapsed"
                data-parent="#accordion"
                href="#pablo"
                id="collapseTwo"
                onClick={(e) => {
                  e.preventDefault();
                  changeCollapse(2);
                }}
              >
                {campoCustomizado[6]?.item}
                <i className="nc-icon nc-minimal-down" />
              </a>
            </h5>
          </CardHeader>

          <Collapse isOpen={collapses.includes(2)}>
            <CardBody>
              <p>{campoCustomizado[7]?.item}</p>
            </CardBody>
          </Collapse>

          <CardHeader className="card-collapse" id="headingThree" role="tab">
            <h5 className="mb-0 panel-title">
              <a
                aria-controls="collapseThree"
                aria-expanded={collapses.includes(3)}
                className="collapsed"
                data-parent="#accordion"
                href="#pablo"
                id="collapseThree"
                onClick={(e) => {
                  e.preventDefault();
                  changeCollapse(3);
                }}
              >
                {campoCustomizado[8]?.item}
                <i className="nc-icon nc-minimal-down" />
              </a>
            </h5>
          </CardHeader>

          <Collapse isOpen={collapses.includes(3)}>
            <CardBody>
              <p>{campoCustomizado[9]?.item}</p>
            </CardBody>
          </Collapse>

          <CardHeader className="card-collapse" id="headingOne" role="tab">
            <h5 className="mb-0 panel-title">
              <a
                aria-expanded={collapses.includes(4)}
                className="collapsed"
                data-parent="#accordion"
                href="#pablo"
                id="collapseOne"
                onClick={(e) => {
                  e.preventDefault();
                  changeCollapse(4);
                }}
              >
                {campoCustomizado[10]?.item}
                <i className="nc-icon nc-minimal-down" />
              </a>
            </h5>
          </CardHeader>

          <Collapse isOpen={collapses.includes(4)}>
            <CardBody>
              <p>{campoCustomizado[11]?.item}</p>
            </CardBody>
          </Collapse>
        </Card>
      </div>
    </div>
  );
}
