import api from "./api.service";
import CoreApiService from "./core-api.service";
import CitySerializer from "./serializers/city.serializer";

export default class CityService extends CoreApiService {
  constructor() {
    super("estados", "cidades", new CitySerializer());
  }

  async listarCidadesCadastro(parentId) {
    const response = await api.get(
      `plataforma/${this.parentEndpoint}/${parentId}/${this.endpoint}`
    );

    const data = response.data;
    return this.convertData(data);
  }
}
