import { useState } from "react";

export default function CarregarImagem({ src, alt, ...rest }) {
  const [loading, setLoading] = useState(true);

  function onLoad() {
    setLoading(false);
  }

  return (
    <>
      <img hidden={loading} src={src} onLoad={onLoad} alt={alt} {...rest} />
    </>
  );
}
