import maskCpfCnpj from "../../utils/mask-cpf-cnpj";
import unMask from "../../utils/unmask";
import PhoneSerializer from "./telefone.serializer";
import AddressSerializer from "./endereco.serializer";
import SegmentosTypeSerializer from "./segmentos-type.serializer";
import ModalidadeExpositoresSerializer from "./modalidade-expositores.serializer";
import ProductTypeSerializer from "./product-type.serializer";
import BaseSerializer from "./base.serializer";
import ExpositorIdiomaSerializer from "./expositor-idiomas.serializer";
import ExpositoreslanguageSerializer from "./expositores-language.serializer";

export default class ExpositoresSerializer extends BaseSerializer {
  constructor() {
    super();
    this.phoneSerializer = new PhoneSerializer();
    this.addressSerializer = new AddressSerializer();
    this.segmentosTypeSerializer = new SegmentosTypeSerializer();
    this.productsTypeSerializer = new ProductTypeSerializer();
    this.modalidadeExpositoresSerializer =
      new ModalidadeExpositoresSerializer();
  }

  fromJson(json) {
    const expositores = {};
    Object.assign(
      expositores,
      json.logotipo_filename && { logotipoFileName: json.logotipo_filename },
      json.id_expositor && { id: json.id_expositor, value: json.id_expositor },
      json.razao_social && {
        razaoSocial: json.razao_social,
        label: json.razao_social,
      },
      json.image_filename && { imageFileName: json.image_filename },
      json.uri_unico && { uriUnico: json.uri_unico },
      json.nome_fantasia && { nomeFantasia: json.nome_fantasia },
      json.cnpj && { cnpj: maskCpfCnpj(json.cnpj) },
      json.insc_estadual && { inscEstadual: json.insc_estadual },
      json.disabled && { disabled: json.disabled },
      json.id_expositor && { value: json.id_expositor },
      json.nome_fantasia && { label: json.nome_fantasia },
      json.email && { email: json.email },
      json.site && { site: json.site },
      json.enderecos
        ? {
            adresses: json.enderecos.map((item) =>
              this.addressSerializer.fromJson(item)
            ),
          }
        : { adresses: [] },
      json.telefones
        ? {
            phones: json.telefones.map((item) =>
              this.phoneSerializer.fromJson(item)
            ),
          }
        : { phones: [] },
      json.segmentos && {
        segmentos: json.segmentos.map((item) =>
          this.segmentosTypeSerializer.fromJson(item)
        ),
      },

      json.produto_tipos && {
        idsProdutoTipo: json.produto_tipos.map((item) =>
          this.productsTypeSerializer.fromJson(item)
        ),
      },

      json.redes_sociais && {
        redesSociais: json.redes_sociais.map((item) => item),
      },

      json.id_expositor_modalidade && {
        idModalidade: json.id_expositor_modalidade,
      },
      json.numero_estande && { numeroEstande: json.numero_estande },

      json.redes_sociais && {
        redesSociais: json.redes_sociais.map((item) => item),
      },

      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new ExpositorIdiomaSerializer()),
      },

      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new ExpositoreslanguageSerializer()),
      },

      json.habilita_banners && {
        bannerRotativos: json.habilita_banners,
      },

      json.habilita_catalogos && {
        modulosCatalogos: json.habilita_catalogos,
      },

      json.habilita_eventos && {
        modulosEventos: json.habilita_eventos,
      },

      json.habilita_coletor_dados && {
        coletorDeDadosDigital: json.habilita_coletor_dados,
      },

      json.habilita_contato && {
        contatoDoExpositor: json.habilita_contato,
      },

      json.habilita_videos && {
        moduloGaleriaVideos: json.habilita_videos,
      }
    );

    if (expositores.disabled) {
      expositores.statusStr = "Inativo";
    } else {
      expositores.statusStr = "Ativo";
    }

    return expositores;
  }

  toJson(expositor) {
    return {
      razao_social: expositor.razaoSocial,
      nome_fantasia: expositor.nomeFantasia,
      image_filename: expositor.imageFileName,
      uri_unico: expositor.uriUnico,
      cnpj: unMask(expositor.cnpj),
      insc_estadual: expositor.inscEstadual,
      disabled: expositor.disabled,
      enderecos: expositor.adresses.map((item) =>
        this.addressSerializer.toJson(item)
      ),
      telefones: expositor.phones.map((item) =>
        this.phoneSerializer.toJson(item)
      ),
      ids_segmento: expositor.segmentos
        ? expositor.segmentos.map((item) => item.value)
        : [],

      ids_produto_tipo: expositor.idsProdutoTipo
        ? expositor.idsProdutoTipo.map((item) => item.value)
        : [],

      id_expositor_modalidade: expositor.idModalidade,
      numero_estande: expositor.numeroEstande,
    };
  }
}
