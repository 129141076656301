import api from "./api.service";
import CoreApiService from "./core-api.service";
import FaleComExpositorSerializer from "./serializers/fale-com-expositor.serializer";

export default class FaleComExpositorService extends CoreApiService {
  constructor() {
    super("contatos", "email", new FaleComExpositorSerializer());
  }
  
  async createMensagem(item) {
    await api.post(
      `${this.parentEndpoint}/${this.endpoint}`,
      this.serializer.toJson(item)
    );
  }
}
