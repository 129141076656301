import BaseSerializer from "./base.serializer";
import SexoIdiomasSerializer from "./sexo-idioma.serializer";

export default class SexoSerializer extends BaseSerializer {
  fromJson(json) {
    const sexo = {};

    Object.assign(
      sexo,
      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new SexoIdiomasSerializer()),
      }
    );

    return sexo;
  }
}
