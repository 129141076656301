import api from "./api.service";
import CoreApiService from "./core-api.service";
import StateSerializer from "./serializers/state.serializer";

export default class StateService extends CoreApiService {
  constructor() {
    super(undefined, "estados", new StateSerializer());
  }

  async listarEstadosCadastro(queryOptions) {
    this.parentEndpoint = "plataforma";
    const response = await api.get(
      `${this.parentEndpoint}/${this.endpoint}?${
        (queryOptions && queryOptions.toQueryString()) || ""
      }`
    );

    const data = response.data;
    return this.convertData(data);
  }
}
