import api from "./api.service";
import CoreApiService from "./core-api.service";
import AvertisingdSerializer from "./serializers/propaganda.serializer";

export default class AvertisingdService extends CoreApiService {
  constructor() {
    super(undefined, "publicidades", new AvertisingdSerializer());
  }

  async upload(idPublicidadeIdioma, data) {
    const formData = this.serializer.toFormData(data);

    const response = await api.put(
      `${this.endpoint}/${idPublicidadeIdioma}/upload`,
      formData
    );

    if (response.data["oret"]) {
      return this.serializer.fromJson(response.data["oret"]);
    }

    return null;
  }

  async incrementarClique(idPublicidade) {
    const response = await api.put(`${this.endpoint}/${idPublicidade}/cliques`);

    const data = response.data;
    return this.serializer.fromJson(data.oret);
  }
}
