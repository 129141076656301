export default class ProductsTypeIdiomaSerializer {
  fromJson(json) {
    const produtoIdioma = {
      id: json.id_produto_tipo_idioma,
      nome: json.tipo,
      label: json.tipo,
      codigoIdioma: json.idioma.codigosegmentosIdiomas,
    };

    return produtoIdioma;
  }
}
