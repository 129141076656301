/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AlertService from "../../services/alert.service";
import CityService from "../../services/city.service";
import FetchAddressService from "../../services/fetch-address.service";
import StateService from "../../services/state.service";
import TextInput from "../TextInput";
import deleteIconColor from "../../assets/img/icons/deleteIconColor.svg";
import { Col, Row } from "reactstrap";
import SelectMultiInput from "components/SelectMultiInput";
import { useTranslation } from "react-i18next";
import QueryOptions from "utils/query-options";
import PaisService from "services/pais.service";

export const enderecoFields = {
  cidadeExterior: "",
  estadoExterior: "",
  idPais: "",
  idEstado: "",
  idCidade: "",
  logradouro: "",
  bairro: "",
  numero: "",
  complemento: "",
  cep: "",
};

export default function EnderecoForm({
  form,
  address,
  arrayHelpers,
  obrigatorio,
  index,
  disabledForm = false,
}) {
  const [estados, setEstados] = useState([]);
  const [paises, setPaises] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const _fetchAddressService = new FetchAddressService();

  async function listaCidades(idEstado) {
    if (!localStorage.getItem("token")) {
      const _cidadeService = new CityService();
      let data = await _cidadeService.listarCidadesCadastro(idEstado);
      return data;
    } else {
      const _cidadeService = new CityService();
      let data = await _cidadeService.listSub(idEstado);
      return data;
    }
  }

  async function onAtualizaCidades(idEstado) {
    const data = await listaCidades(idEstado);
    setCidades(data);
  }

  async function listaEstados(pais = false) {
    const _estadoService = new StateService();
    let data = await _estadoService.listarEstadosCadastro(
      pais
        ? new QueryOptions([
            ["id_pais", pais.id],
            ["brasil", "brasil"],
          ])
        : null
    );

    if (form.values.enderecos[index].idEstado) {
      const estado = data.find(
        (item) => item.id === form.values.enderecos[index].idEstado
      );

      if (estado) {
        form.setFieldValue(`enderecos.${index}.idEstado`, estado);
      }
    }

    setEstados([...data]);
    return data;
  }

  async function onTrocaPais(pais) {
    await listaEstados(pais);
    setCidades([]);

    if (pais.id !== 1) {
      form.setFieldValue(`enderecos.${index}.cep`, "");
      form.setFieldValue(`enderecos.${index}.idEstado`, "");
      form.setFieldValue(`enderecos.${index}.idCidade`, "");
      form.setFieldValue(`enderecos.${index}.cidade`, "");
      form.setFieldValue(`enderecos.${index}.uf`, null);

      let paisSelecionado = paises.find((item) => item.id === pais.id);

      form.setFieldValue(`enderecos.${index}.pais`, paisSelecionado.nome);
      form.setFieldValue(`enderecos.${index}.idEstado`, paisSelecionado);
      form.setFieldValue(`enderecos.${index}.idCidade`, paisSelecionado);
    } else {
      form.setFieldValue(`enderecos.${index}.idEstado`, "");
      form.setFieldValue(`enderecos.${index}.idCidade`, "");
      form.setFieldValue(`enderecos.${index}.cidadeExterior`, "");
      form.setFieldValue(`enderecos.${index}.estadoExterior`, "");
    }
  }

  async function enderecoPorCep(cep) {
    try {
      setLoading(true);
      const cepQuery = await _fetchAddressService.queryCep(cep);
      if (!cepQuery.erro) {
        const estadoFilter = estados.find(
          (item) => item.label.toLowerCase() === cepQuery.uf.toLowerCase()
        );

        if (estadoFilter) {
          form.setFieldValue(`enderecos.${index}.idEstado`, estadoFilter);
          const cidadeData = await listaCidades(estadoFilter.id);
          setCidades(cidadeData);
          const cidadeFilter = cidadeData.find(
            (item) => item.label === cepQuery.localidade
          );
          if (cidadeFilter) {
            form.setFieldValue(`enderecos.${index}.idCidade`, cidadeFilter);

            form.setFieldValue(
              `enderecos.${index}.logradouro`,
              cepQuery.logradouro
            );
            form.setFieldValue(`enderecos.${index}.bairro`, cepQuery.bairro);
          }
        }
      }
    } catch {
      AlertService.warn("Erro ao buscar CEP");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function listaPaises() {
      const _paisesService = new PaisService();

      let data = await _paisesService.list();
      setPaises(data);

      return data;
    }

    async function listaEstados() {
      if (!localStorage.getItem("token")) {
        const _estadoService = new StateService();
        let data = await _estadoService.listarEstadosCadastro();
        setEstados(data);
        return data;
      } else {
        const _estadoService = new StateService();
        let data = await _estadoService.list();
        setEstados(data);
        return data;
      }
    }

    async function init() {
      const paises = await listaPaises();

      if (form.values?.enderecos[index]?.idPais) {
        const pais = paises.find(
          (item) => item.id === form.values?.enderecos[index]?.idPais?.id
        );
        form.setFieldValue(`enderecos.${index}.idPais`, pais);

        await listaEstados(pais);

        if (form.values?.enderecos[index]?.idEstado) {
          const cidadeData = await listaCidades(
            form.values?.enderecos[index]?.idEstado
          );
          setCidades(cidadeData);

          if (form.values?.enderecos[index]?.idCidade) {
            const cidade = cidadeData.find(
              (item) => item.id === form.values?.enderecos[index]?.idCidade
            );
            form.setFieldValue(`enderecos.${index}.idCidade`, cidade);
          }
        }

        const _estadoService = new StateService();
        let data = await _estadoService.listarEstadosCadastro(
          pais
            ? new QueryOptions([
                ["id_pais", pais.id],
                ["brasil", "brasil"],
              ])
            : null
        );

        if (form.values.enderecos[index].idEstado) {
          const estado = data.find(
            (item) => item.id === form.values.enderecos[index].idEstado
          );

          if (estado) {
            form.setFieldValue(`enderecos.${index}.idEstado`, estado);
          }
        }

        setEstados([...data]);
      }
    }

    init();
  }, [index]);

  return (
    <>
      <Col>{index > 0 && <hr />}</Col>
      <Row className="endereco-individual-container">
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="3"
          className="col-dados-endereco"
        >
          <SelectMultiInput
            label={t("País")}
            name={`enderecos.${index}.idPais`}
            obrigatorio={true}
            options={paises}
            onChange={(e) => {
              form.setFieldValue(`enderecos.${index}.idPais`, e);
              onTrocaPais(e);
            }}
            value={form.values?.enderecos[index]?.idPais}
          />
        </Col>{" "}
        {form.values?.enderecos[index]?.idPais?.id === 1 && (
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="3"
            className="col-dados-endereco"
          >
            <TextInput
              label="CEP"
              obrigatorio={true}
              name={`enderecos.${index}.cep`}
              onBlur={(e) => enderecoPorCep(e.target.value)}
              mask="99999-999"
              disabled={form.values?.enderecos[index]?.idPais?.id !== 1}
              loading={loading}
              className="input-modal-usuario-endereco input-modal-usuario-phone "
            />
          </Col>
        )}
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="3"
          className="col-dados-endereco"
        >
          {form?.values?.enderecos[index]?.idPais?.id === 1 ? (
            <>
              <SelectMultiInput
                label={t("Estado")}
                name={`enderecos.${index}.idEstado`}
                options={estados}
                onChange={(e) => {
                  form.setFieldValue(`enderecos.${index}.idEstado`, e);
                  onAtualizaCidades(e.value);
                }}
                value={form.values?.enderecos[index]?.idEstado}
                obrigatorio={true}
              />
            </>
          ) : (
            <>
              <TextInput
                label={t("Estado")}
                obrigatorio={true}
                name={`enderecos.${index}.estadoExterior`}
                className="input-modal-usuario-endereco input-modal-usuario-phone "
                loading={loading}
              />
            </>
          )}
        </Col>
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="3"
          className="col-dados-endereco"
        >
          {form.values?.enderecos[index]?.idPais?.id === 1 ? (
            <>
              <SelectMultiInput
                label={t("Cidade")}
                name={`enderecos.${index}.idCidade`}
                options={cidades}
                obrigatorio={true}
                onChange={(e) => {
                  form.setFieldValue(`enderecos.${index}.idCidade`, e);
                }}
                value={form.values.enderecos[index]?.idCidade}
              />
            </>
          ) : (
            <>
              <TextInput
                label={t("Cidade")}
                name={`enderecos.${index}.cidadeExterior`}
                obrigatorio={true}
                className="input-modal-usuario-endereco input-modal-usuario-phone"
                loading={loading}
              />
            </>
          )}
        </Col>
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="3"
          className="col-dados-endereco"
        >
          <TextInput
            label={t("Logradouro")}
            obrigatorio={true}
            placeholder="Avenida Paulista"
            name={`enderecos.${index}.logradouro`}
            loading={loading}
            className="input-modal-usuario-endereco input-modal-usuario-phone "
          />
        </Col>
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="3"
          className="col-dados-endereco"
        >
          <TextInput
            label={t("Número")}
            obrigatorio={true}
            className="input-modal-usuario-endereco input-modal-usuario-phone "
            name={`enderecos.${index}.numero`}
            disabled={disabledForm}
          />
        </Col>
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="3"
          className="col-dados-endereco"
        >
          <TextInput
            label={t("Complemento")}
            className="input-modal-usuario-endereco input-modal-usuario-phone "
            name={`enderecos.${index}.complemento`}
            disabled={disabledForm}
          />
        </Col>
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="3"
          className="col-dados-endereco"
        >
          <TextInput
            label={t("Bairro")}
            obrigatorio={true}
            className="input-modal-usuario-endereco input-modal-usuario-phone "
            name={`enderecos.${index}.bairro`}
            disabled={disabledForm}
            loading={loading}
          />
        </Col>
        {index > 0 ? (
          <div
            className="p-col-12 p-lg-1 p-md-1 p-sm-6 icon-input-spacedeb icone-lixeira-forms"
            style={{ paddingTop: 30 }}
          >
            <i className="trashIcon" onClick={() => arrayHelpers.remove(index)}>
              <img
                src={deleteIconColor}
                alt="Imagem de um lixeiro; clique nessa imagem para excluir o endereço"
              />
            </i>
          </div>
        ) : null}
      </Row>
    </>
  );
}
