import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import TextInput from "components/TextInput";
import InputCpf from "components/InputCpf";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import PerfilEmpresaService from "services/perfil-empresa.service";
import CargoService from "services/cargo.service";
import SexoService from "services/sexo.service";
import EnderecoForm from "components/form/EnderecoForm";
import { enderecoFields } from "components/form/EnderecoForm";
import { telefoneFields } from "components/form/PhoneForm";
import PhoneForm from "components/form/PhoneForm";

import UsuarioService from "services/usuario.service";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Authentication from "services/authentication.service";
import { AppContext } from "store";
import AlertService from "services/alert.service";
import AlertMenssage from "components/AlertMenssage";
import unMask from "../../utils/unmask";
import { modalCadatroUsuario } from "validations/modal-cadastro-usuario.validation";

export default function ModalCadastroUsuario({
  modalCadastrarNovoUsuario,
  setModalCadastrarNovoUsuario,
}) {
  const animatedComponents = makeAnimated();
  const [opcoesCargo, setOpcoesCargo] = useState([]);
  const [opcoesSexo, setOpcoesSexo] = useState([]);
  const [opcoesPerfisEmpresa, setOpcoesPerfisEmpresa] = useState([]);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { setUser } = useContext(AppContext);
  const [hasError, setHasError] = useState("");

  const _usuarioService = new UsuarioService();

  useEffect(() => {
    async function init() {
      const _cargoService = new CargoService();
      const cargoResponse = await _cargoService.listarCargosCadastro();
      setOpcoesCargo(cargoResponse);

      const _sexoService = new SexoService();
      const sexoResponse = await _sexoService.listarSexoCadastro();
      setOpcoesSexo(sexoResponse);

      const _perfilEmpresaService = new PerfilEmpresaService();
      const perfilEmpresaResponse =
        await _perfilEmpresaService.listarPerfilEmpresaCadastro();
      setOpcoesPerfisEmpresa(perfilEmpresaResponse);
    }

    init();
  }, [i18n.language]);

  async function autenticarUsuarioCadastrado(data) {
    const senha = data.cpf.replace(/[^\d]+/g, "");

    try {
      await new Authentication().singIn(data.email, senha);
      setUser(Authentication._user);
      setHasError(false);
      history.push("/home");
    } catch ({ response }) {
      if (response.data.msg === "Unauthorized") {
        setHasError(true);
      } else {
        setHasError(false);
      }
    }
  }

  async function cadastrarUsuario(values) {
    const data = { ...values };

    const telefonesValidosUsuario = data.telefones.filter(
      (item) => unMask(item.ddi) && unMask(item.telefone)
    );
    data.telefones = telefonesValidosUsuario;

    const enderecosValidosUsuarios = data.enderecos.filter(
      (item) => item.logradouro
    );
    data.enderecos = enderecosValidosUsuarios;

    await _usuarioService
      .CadastrarUsuario(data)
      .then(() => {
        autenticarUsuarioCadastrado(data);
      })
      .catch(({ response }) => {
        AlertService.warn(response.data.msg);
      });
  }

  return (
    <div>
      <Modal
        centered
        size="xl"
        isOpen={modalCadastrarNovoUsuario}
        toggle={() => {
          setModalCadastrarNovoUsuario(false);
        }}
      >
        <ModalBody className="modal-body-container">
          <Formik
            enableReinitialize={true}
            onSubmit={(values) => {
              cadastrarUsuario(values);
            }}
            validationSchema={modalCadatroUsuario}
            initialValues={{
              nome: "",
              cpf: "",
              email: "",
              emailConfirmado: "",
              perfilEmpresa: "",
              empresa: "",
              cargo: "",
              dataNascimento: "",
              sexo: "",
              telefones: [telefoneFields],
              enderecos: [enderecoFields],
              empresaVisitante: {
                nomeFantasia: "",
              },
            }}
          >
            {(props) => {
              return (
                <Form>
                  <>
                    <div className="container-cadastro-usuarios">
                      <div className="imagem-background-cadastro">
                        <div className="background-esquerdo"></div>
                      </div>

                      <div className="dados-background">
                        <Row>
                          <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <TextInput
                              label={t("Nome Completo")}
                              name="nome"
                              className="input-modal-usuario-phone"
                              placeholder={t("Nome Completo")}
                              obrigatorio={true}
                            />
                          </Col>

                          <Col xs="12" sm="12" md="12" lg="6" xl="6">
                            <TextInput
                              label={t("E-mail")}
                              name="email"
                              placeholder={t("E-mail")}
                              autoComplete="234"
                              className="input-modal-usuario-phone"
                              obrigatorio={true}
                            />
                          </Col>

                          <Col xs="12" sm="12" md="12" lg="6" xl="6">
                            <TextInput
                              label={t("Confirme o e-mail")}
                              placeholder={t("Confirme o e-mail")}
                              name="emailConfirmado"
                              className="input-modal-usuario-phone"
                              obrigatorio={true}
                            />
                          </Col>

                          <Col xs="12" sm="12" md="12" lg="6" xl="4">
                            <InputCpf
                              label="CPF"
                              placeholder="___.___.___-__"
                              name="cpf"
                              className="input-modal-usuario-phone"
                              obrigatorio={
                                i18n.language === "pt-BR" ? true : false
                              }
                            />
                          </Col>

                          <Col xs="12" sm="12" md="12" lg="6" xl="4">
                            <label>
                              {t("Data de Nascimento")}{" "}
                              <span className="required-hash">*</span>
                            </label>
                            <input
                              type="date"
                              className="data-nascimento-input"
                              name="dataNascimento"
                              translate="yes"
                              placeholder="dd-mm-yyyy"
                              max="2022-12-12"
                              lang="pt-BR"
                              maxLength="8"
                              style={{
                                marginBottom: "-1px",
                              }}
                              onChange={(e) => {
                                const novaData = new Date(e.target.value);

                                props.setFieldValue("dataNascimento", novaData);
                              }}
                            />
                            <ErrorMessage name="dataNascimento">
                              {(msg) => (
                                <div className="error-input">{msg}</div>
                              )}
                            </ErrorMessage>
                          </Col>

                          <Col xs="12" sm="12" md="12" lg="12" xl="4">
                            <TextInput
                              label={t("Empresa")}
                              name="empresaVisitante.nomeFantasia"
                              placeholder={t("Empresa")}
                              obrigatorio={true}
                              className="input-modal-usuario-phone"
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label>
                              {t("Perfil da Empresa")}{" "}
                              <span className="required-hash">*</span>
                            </label>

                            <Select
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              name="perfilEmpresa"
                              options={opcoesPerfisEmpresa}
                              placeholder={t("Selecione")}
                              value={props.values?.perfilEmpresa}
                              onChange={(event) =>
                                props.setFieldValue("perfilEmpresa", event)
                              }
                            />
                            <ErrorMessage name="perfilEmpresa">
                              {(msg) => (
                                <div className="error-input">{msg}</div>
                              )}
                            </ErrorMessage>
                          </Col>
                          <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label>
                              {t("Cargo")}{" "}
                              <span className="required-hash">*</span>
                            </label>
                            <Select
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              name="cargo"
                              options={opcoesCargo}
                              placeholder={t("Selecione")}
                              value={props.values?.cargo}
                              onChange={(event) =>
                                props.setFieldValue("cargo", event)
                              }
                            />
                            <ErrorMessage name="cargo">
                              {(msg) => (
                                <div className="error-input">{msg}</div>
                              )}
                            </ErrorMessage>
                          </Col>

                          <Col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label>
                              {t("Gênero")}{" "}
                              <span className="required-hash">*</span>
                            </label>
                            <Select
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              name="sexo"
                              options={opcoesSexo}
                              placeholder={t("Selecione")}
                              value={props.values?.sexo}
                              onChange={(event) =>
                                props.setFieldValue("sexo", event)
                              }
                            />
                            <ErrorMessage name="sexo">
                              {(msg) => (
                                <div className="error-input">{msg}</div>
                              )}
                            </ErrorMessage>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            className="col-modal-endereco"
                          >
                            <div>
                              <h2>{t("Endereço")}</h2>
                            </div>
                            <FieldArray
                              name="enderecos"
                              render={(arrayHelpers) => (
                                <div>
                                  {props?.values?.enderecos?.map(
                                    (endereco, index) => (
                                      <div key={index}>
                                        <EnderecoForm
                                          form={props}
                                          index={index}
                                          arrayHelpers={arrayHelpers}
                                          address={endereco}
                                          obrigatorio={true}
                                        />
                                      </div>
                                    )
                                  )}
                                  <div
                                    className="add-item"
                                    onClick={() =>
                                      arrayHelpers.insert(
                                        props?.values?.enderecos?.length + 1,
                                        enderecoFields
                                      )
                                    }
                                  >
                                    <span>
                                      {t("Adicionar um novo endereço")}
                                    </span>
                                  </div>
                                </div>
                              )}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            className="col-modal-contato"
                          >
                            <div>
                              <h2>{t("Contatos")}</h2>
                            </div>
                            <FieldArray
                              name="telefones"
                              render={(arrayHelpers) => (
                                <div className="p-col-12">
                                  {props?.values?.telefones?.map(
                                    (phone, index) => (
                                      <div key={index}>
                                        <PhoneForm
                                          parent="telefones."
                                          arrayHelpers={arrayHelpers}
                                          index={index}
                                          phones={props.values.telefones}
                                          form={props}
                                          obrigatorio={true}
                                        />
                                      </div>
                                    )
                                  )}
                                  <div
                                    className="add-item"
                                    onClick={() =>
                                      arrayHelpers.insert(
                                        props?.values?.telefones?.length + 1,
                                        telefoneFields
                                      )
                                    }
                                  >
                                    {t("Adicionar um novo telefone")}
                                  </div>
                                </div>
                              )}
                            />
                          </Col>
                        </Row>

                        {hasError && (
                          <p className="modal-cadastro-erro">
                            {t(
                              "Ops! Ocorreu um problema. Tente novamente mais tarde."
                            )}
                          </p>
                        )}

                        <div className="div-botao-cadastro-novo-usuario">
                          <Button
                            type="submit"
                            styles="w-all"
                            className="mr-1 btn-login"
                          >
                            {t("Credenciar")}
                          </Button>
                        </div>
                        <AlertMenssage />
                      </div>
                    </div>
                  </>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
}
