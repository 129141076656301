export default class CertificadoSerializer {
  fromJson(json) {
    return {
      idEvento: json.id_evento,
      idUsuario: json.id_usuario,
      evento: json.evento,
      filename: json.filenam,
    };
  }
}
