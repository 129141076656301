import BaseSerializer from "./base.serializer";
import InstrutorIdiomaSerializer from "./instrutor-idioma.serializer";

export default class InstrutorSerializer extends BaseSerializer {
  fromJson(json) {
    const instrutor = {};
    
    Object.assign(
      instrutor,
      json.id_curso_instrutor && { idCursoInstrutor: json.id_curso_instrutor },
      json.nome_instrutor && { nome: json.nome_instrutor },
      json.biografia_instrutor && { biografia: json.biografia_instrutor },
      json.image_filename && { imagemUrl: json.image_filename }
    );

    const idiomas = this.idiomaAtual(json.idiomas, new InstrutorIdiomaSerializer());

    return {...instrutor, ...idiomas};
  }
}
