import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import ExpositoresService from "services/expositores.service";
import { AppContext } from "store";

export function Header() {
  const { idExpositor } = useContext(AppContext);
  const [ExpositorTelefone, setExpositorTelefone] = useState();
  const [ExpositorEmail, setExpositorEmail] = useState();
  const [ExpositorSite, setExpositorSite] = useState();
  const [descricaoExpositor, setDescricaoExpositor] = useState();
  const [telefoneDDD, setTelefoneDDD] = useState();
  const [redesSociais, setRedesSociais] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    async function init() {
      const _expositorService = new ExpositoresService();
      const expositorResponse = await _expositorService.read(idExpositor);
      setExpositorTelefone(expositorResponse?.phones[0]?.telefone);
      setExpositorEmail(expositorResponse.email);
      setExpositorSite(expositorResponse.site);
      setDescricaoExpositor(expositorResponse.descricao);
      setTelefoneDDD(expositorResponse?.phones[0]?.ddd);
      setRedesSociais(expositorResponse.redesSociais);
    }

    init();
  }, [idExpositor, i18n.language]);

  return (
    <div className="expositor-contato">
      <Container>
        <Row>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="8"
            className="sobre-expositor-container"
          >
            <div className="sobre-expositor">
              {descricaoExpositor && (
                <>
                  <h1>{t("Sobre o expositor")}</h1>
                  <p>{descricaoExpositor}</p>
                </>
              )}
            </div>
          </Col>

          <Col xs="12" sm="12" md="6" lg="5" xl="4">
            <div className="contato-expositor">
              <h1>{t("Contato")}</h1>

              <ul>
                <li>
                  ({telefoneDDD}) {ExpositorTelefone}
                </li>
                <li>{ExpositorEmail}</li>
                <li>{ExpositorSite}</li>
              </ul>

              <Row className="social-medias">
                <Col xs="12" sm="12" md="6" lg="5" xl="7">
                  <ul
                    className="social-media-ul"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    {redesSociais?.map((redeSocial) => (
                      <>
                        <li>
                          {redeSocial.id_rede_social_tipo === 1 &&
                            redeSocial.link !== "" && (
                              <a
                                href={redeSocial.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-facebook" />
                              </a>
                            )}
                        </li>

                        <li>
                          {redeSocial.id_rede_social_tipo === 2 &&
                            redeSocial.link !== "" && (
                              <a
                                href={redeSocial.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-instagram" />
                              </a>
                            )}
                        </li>

                        <li>
                          {redeSocial.id_rede_social_tipo === 3 &&
                            redeSocial.link !== "" && (
                              <a
                                href={redeSocial.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-twitter" />
                              </a>
                            )}
                        </li>

                        <li>
                          {redeSocial.id_rede_social_tipo === 4 &&
                            redeSocial.link !== "" && (
                              <a
                                href={redeSocial.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-youtube" />
                              </a>
                            )}
                        </li>
                      </>
                    ))}
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
