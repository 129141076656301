export const messagePT = {
  CPF: "CPF inválido",
  REQUIRED: "Obrigatório",
  CNPJ: "CNPJ inválido",
  EMAIL: "Digite um e-mail válido",
  SAME_EMAIL: '"E-mail" e "Confirme o e-mail" devem ser iguais',
  MIN_LENGTH: "Mínimo 6 caracteres",
  MAX_LENGTH: "Máximo 20 caracteres",
  DOCUMENT_INVALID: "Documento inválido",
  PASSWORD_SAFE: "Digite uma senha válida",
  SAME_PASSWORD: '"Senha" e "Confirme a senha" devem ser iguais',
};

export const messageEN = {
  CPF: "Invalid CPF",
  REQUIRED: "Mandatory",
  CNPJ: "Invalid CNPJ",
  EMAIL: "Enter a valid email address",
  SAME_EMAIL: '"Email" and "Confirm email" must be the same',
  MIN_LENGTH: "Minimum 6 characters",
  MAX_LENGTH: "Maximum 20 characters",
  DOCUMENT_INVALID: "Invalid document",
  PASSWORD_SAFE: "Enter a valid password",
  SAME_PASSWORD: '"Password" and "Confirm password" must be the same',
};

export const messageES = {
  CPF: "CPF invalid",
  REQUIRED: "Obligatorio",
  CNPJ: "CNPJ no válido",
  EMAIL: "Introduzca una dirección de correo electrónico válida",
  SAME_EMAIL:
    '"Correo electrónico" y "Confirmar correo electrónico" deben ser iguales',
  MIN_LENGTH: "Mínimo 6 caracteres",
  MAX_LENGTH: "Máximo 20 caracteres",
  DOCUMENT_INVALID: "Documento inválido",
  PASSWORD_SAFE: "Introduce una contraseña válida",
  SAME_PASSWORD: '"Contraseña" y "Confirmar contraseña" deben ser iguales',
};

export function getMessages() {
  if (localStorage.getItem("i18nextLng") === "en") {
    return messageEN;
  } else if (localStorage.getItem("i18nextLng") === "es") {
    return messageES;
  }

  return messagePT;
}
