/* eslint-disable react-hooks/exhaustive-deps */
import CardAulas from "components/CardAulas";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { animateScroll as scroll } from "react-scroll";
import CursosService from "services/cursos.service";
import ExpositoresService from "services/expositores.service";
import { ordenarCursoPorDestaque } from "utils/ordenar-curso-por-destaque";
import QueryOptions from "utils/query-options";
import "./style.scss";

export default function PageFullCursos() {
  const quantidadeShowCurso = 25;
  const [cursos, setCursos] = useState([]);
  const [meta, setMeta] = useState({});
  const [totalShowCurso, setTotalShowCurso] = useState(quantidadeShowCurso);
  const [expositores, setExpositores] = useState([]);
  const [filtroTexto, setFiltroTexto] = useState("");
  const [expositorSelecionado, setExpositorSelecionado] = useState("");
  const [filtro, setFiltro] = useState([]);
  const [totalCursoTela, setTotalCursoTela] = useState();
  const [letrasExpositores, setLetrasExpositores] = useState([]);

  const cursosService = new CursosService();
  const expositoresService = new ExpositoresService();
  const history = useHistory();
  const { t } = useTranslation();

  const ALFABETO = [
    "#",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  useEffect(() => {
    const carregarCursos = async () => {
      try {
        const queryOptions = new QueryOptions();

        if (filtro.length) {
          queryOptions.queryKvs.push(...filtro);
        } else {
          queryOptions.queryKvs.push(["per_page", totalShowCurso]);
        }

        const response = await cursosService.list(queryOptions, true);

        if (queryOptions.queryKvs.length === 1) {
          const cursos = response.items.sort(ordenarCursoPorDestaque);
          setTotalCursoTela(cursos.length);

          const ordem = new Map([["#", []]]);
          cursos.forEach((curso, index) => {
            let inicialAux = curso.expositor.nomeFantasia
              .charAt()
              .toLowerCase();

            if (isNotAlfabeto(inicialAux)) {
              inicialAux = "#";
            }

            if (index === 0 || !ordem.has(inicialAux)) {
              ordem.set(inicialAux, [curso]);
            } else {
              ordem.get(inicialAux).push(curso);
            }
          });

          if (!ordem.get("#").length) {
            ordem.delete("#");
          }

          setCursos([...ordem]);
        } else {
          setCursos(response.items);
        }

        scrollLetra();

        setMeta(response.meta);
      } catch (error) {
        console.error("Erro ao carregar cursos:", error);
      }
    };

    carregarCursos();
  }, [totalShowCurso, filtro]);

  useEffect(() => {
    const carregarExpositores = async () => {
      try {
        const queryOptions = new QueryOptions([
          ["per_page", 9999],
          ["fields", "nome_fantasia, uri_unico, id_expositor"],
        ]);

        const response = await expositoresService.listExpositoresCurso(
          queryOptions
        );

        setIniciaisExpoistoresCurso(response);
        setExpositores(response);
      } catch (error) {
        console.error("Erro ao carregar expositores:", error);
      }
    };

    carregarExpositores();
  }, []);

  const handleMostrarMais = () => {
    setTotalShowCurso(totalShowCurso + quantidadeShowCurso);
  };

  const handleCardClick = (id) => {
    history.push(`/cursos-modulo/${id}`);
  };

  const limparFiltros = () => {
    setFiltroTexto("");
    setExpositorSelecionado("");
    setFiltro([]);
  };

  const filtrarCursos = (data) => {
    let listaFiltros = [];

    if (data.inicial) {
      setFiltroTexto("");
      setExpositorSelecionado("");

      listaFiltros = [];
      listaFiltros.push(["inicial", data.inicial]);
      setFiltro([...listaFiltros]);
      return;
    }

    const titulo = data.titulo || filtroTexto;
    if (titulo) {
      listaFiltros.push(["titulo", titulo]);
    }

    const expositor = data.expositor || expositorSelecionado;
    if (expositor) {
      listaFiltros.push(["id_expositor", expositor]);
    }

    setFiltro([...listaFiltros]);
  };

  const possuiInicial = (value) => {
    return letrasExpositores.includes(value);
  };

  const setIniciaisExpoistoresCurso = (expositores) => {
    const inicial = new Set();
    expositores.forEach((expositor) => {
      const char = expositor.nomeFantasia.charAt().toLowerCase();

      if (isNotAlfabeto(char)) {
        inicial.add("#");
      } else {
        inicial.add(char);
      }
    });

    setLetrasExpositores([...inicial]);
  };

  const scrollLetra = () => {
    const filtroInicial = filtro.find((item) => item[0] === "inicial");

    if (filtroInicial) {
      const divInicial = document.getElementById(`inicial-${filtroInicial[1]}`);
      scroll.scrollTo(divInicial.offsetTop);
    }
  };

  const isNotAlfabeto = (char) => {
    return /[^a-zA-Z]/.test(char);
  };

  return (
    <div className="page-full">
      <div className="title">
        <h1>{t("Todos os cursos")}</h1>
      </div>
      <div className="container-page">
        <div className="filtros-container">
          <input
            type="text"
            placeholder={t("Buscar...")}
            value={filtroTexto}
            onChange={(e) => {
              setFiltroTexto(e.target.value);
              filtrarCursos({ titulo: e.target.value });
            }}
          />
          <select
            value={expositorSelecionado}
            onChange={(e) => {
              setExpositorSelecionado(e.target.value);
              filtrarCursos({ expositor: e.target.value });
            }}
          >
            <option value="">{t("Selecione um expositor")}</option>
            {expositores.map((expositor) => (
              <option key={expositor.id} value={expositor.id}>
                {expositor.nomeFantasia}
              </option>
            ))}
          </select>
          <button onClick={limparFiltros}>{t("Limpar filtros")}</button>
        </div>
      </div>

      <div className="box-alfabeto">
        <div className="title">{t("Navegue pela letra inicial")}:</div>
        <div className="alfabeto">
          {ALFABETO.map((letra) => (
            <>
              {possuiInicial(letra) ? (
                <div
                  key={letra}
                  className="letra destaque pointer"
                  onClick={() => filtrarCursos({ inicial: letra })}
                >
                  {letra.toUpperCase()}
                </div>
              ) : (
                <div key={letra} className="letra">
                  {letra.toUpperCase()}
                </div>
              )}
            </>
          ))}
        </div>
      </div>

      <div class="inicial-curso">
        {cursos.map((ordemCurso) => (
          <div
            id={`inicial-${ordemCurso[0]}`}
            key={ordemCurso[0]}
            className="box"
          >
            <div className="inicial">{ordemCurso[0]}</div>
            <div className="card-aula">
              {ordemCurso[1].map((curso) => (
                <CardAulas
                  curso={curso}
                  click={() => handleCardClick(curso.id)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      {totalCursoTela < meta?.totalItems ? (
        <div className="show-more-button">
          <button onClick={handleMostrarMais}>{t("Mostrar mais")}</button>
        </div>
      ) : null}
    </div>
  );
}
