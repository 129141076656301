import React, { useState } from "react";

const estadoInicial = {
  user: {},
};

export const AppContext = React.createContext(estadoInicial);

export default function Store({ children }) {
  const [state, setState] = useState(estadoInicial);
  // const [socketio, setSocketio] = useState(null);
  const [idExpositor, setIdExpositor] = useState("");
  const [expositorUriUnico, setExpositorUriUnico] = useState("");

  function atualizarUsuario(value) {
    setState({ ...state, user: value });
  }

  return (
    <AppContext.Provider
      value={{
        user: state.user,
        setUser: (value) => atualizarUsuario(value),
        idExpositor: idExpositor,
        setIdExpositor: (value) => setIdExpositor(value),
        // socketio: socketio,
        // setSocketio: (value) => setSocketio(value),
        expositorUriUnico: expositorUriUnico,
        setExpositorUriUnico: (value) => setExpositorUriUnico(value),
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
