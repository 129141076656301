import BannersPublicidade from "components/expo-revestir/BannersPublicidade";
import React from "react";
import { useTranslation } from "react-i18next";
import EventosFuturos from "./EventosFuturos";

export default function ProgramacaoEventos() {
  const { t } = useTranslation();

  return (
    <div>
      <BannersPublicidade idAreaPublicidade={2} bannerHome={false} />

      <div className="header-container">
        <h1>{t("Eventos")}</h1>
      </div>

      <EventosFuturos />
    </div>
  );
}
