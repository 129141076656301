/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Col, Container, Row } from "reactstrap";
import { AppContext } from "store";

export default function ExpositoresFavoritos() {
  const { user } = useContext(AppContext);
  const history = useHistory();
  const time = new Date().getTime();
  const { t } = useTranslation();

  return (
    <div className="expositores-favoritos-container-background">
      <Container>
        <div className="favoritos-title-container">
          <h1>{t("Expositores favoritos")}</h1>
        </div>

        {user?.expositoresFavoritos?.length ? (
          <Row>
            {user?.expositoresFavoritos.map((item) => (
              <Col xs="12" sm="6" md="4" lg="3" xl="2">
                <div className="img-container-background">
                  <img
                    src={`${process.env.REACT_APP_API_URL}expositores/${item.id}/logotipos/display?${time}`}
                    onClick={() =>
                      history.push(`/expositor/${item.uriUnico}/produtos`)
                    }
                    alt={item?.nomeFantasia}
                    title={item?.nomeFantasia}
                  />
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <h4>{t("Você ainda não possui um expositor favorito")}</h4>
        )}
      </Container>
    </div>
  );
}
