export default class EnderecoSerializer {
  fromJson(json) {
    const endereco = {};

    Object.assign(
      endereco,
      json.id_endereco && { id: json.id_endereco },
      json.logradouro && { logradouro: json.logradouro },
      json.logradouro_numero && { numero: json.logradouro_numero },
      json.complemento && { complemento: json.complemento },
      json.bairro && { bairro: json.bairro },
      json.id_pais && {
        idPais: {
          label: json.pais,
          value: json.id_pais,
          id: json.id_pais,
        },
      },
      json.cidade && {
        idCidade: {
          label: json.cidade,
          value: json.id_cidade,
          id: json.id_cidade,
        },
      },
      json.id_estado && {
        idEstado: {
          label: json.uf,
          value: json.id_estado,
          id: json.id_estado,
        },
      },
      json.cep && { cep: json.cep },
      json.uf && { uf: json.uf },

      json.cidade_exterior && {
        cidadeExterior: json.cidade_exterior,
      },
      json.estado_exterior && {
        estadoExterior: json.estado_exterior,
      }
    );

    return endereco;
  }

  toJson(endereco) {
    const enderecoToJson = {};

    Object.assign(
      enderecoToJson,
      endereco.id && { id_endereco: endereco.id },
      endereco.logradouro && { logradouro: endereco.logradouro },
      endereco.numero && { logradouro_numero: endereco.numero },
      endereco.complemento && { complemento: endereco.complemento },
      endereco.bairro && { bairro: endereco.bairro },
      endereco.cep && { cep: endereco.cep.replace("-", "") },

      endereco.idCidade.idCidade &&
        endereco.idPais !== 1 && { id_cidade: endereco.idCidade.idCidade },
      endereco.idCidade.id &&
        endereco.idPais.id === 1 && { id_cidade: endereco.idCidade.id },

      endereco.cidadeExterior && {
        cidade_exterior: endereco.cidadeExterior,
      },
      endereco.estadoExterior && {
        estado_exterior: endereco.estadoExterior,
      }
    );

    return enderecoToJson;
  }
}
