import React from "react";
import { Col, Container, Row } from "reactstrap";
import DuvidasPage from "../Home/DuvidasPage";
import AnfacerContato from "assets/img/expo-revestir/images/anfacer.svg";
import ForumContato from "assets/img/expo-revestir/images/FIER+.svg";
import HausDecor from "assets/img/expo-revestir/images/Haus Decor.svg";
import Nurnberg from "assets/img/expo-revestir/images/NMB.svg";
import HG from "assets/img/expo-revestir/images/hgturismo.svg";

import { useTranslation } from "react-i18next";

export default function PaginaContato() {
  const { t } = useTranslation();

  return (
    <div>
      <DuvidasPage />

      <Container className="promocao">
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="3">
            <div className="promocao-itens">
              <h1>{t("Promovido por")}</h1>
              <img src={AnfacerContato} alt="Anfacer" />
            </div>
          </Col>

          <Col xs="12" sm="12" md="12" lg="12" xl="3">
            <div className="promocao-itens">
              <h1>{t("Evento conjunto")}</h1>
              <img src={ForumContato} alt="Forum" />
            </div>
          </Col>

          <Col xs="12" sm="12" md="12" lg="12" xl="3">
            <div className="promocao-itens">
              <h1>{t("Evento Simultâneo")}</h1>
              <img src={HausDecor} alt="HausDecor" />
            </div>
          </Col>

          <Col xs="12" sm="12" md="12" lg="12" xl="3">
            <div className="promocao-itens">
              <h1>{t("Organização")}</h1>
              <img src={Nurnberg} alt="Nurnberg" />
            </div>
          </Col>
          <Col xs="12" sm="12" md="12" lg="12" xl="3">
            <div className="promocao-itens">
              <h1>{t("Agência Oficial")}</h1>
              <img className="hgturismo" src={HG} alt="HG Turismo" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
