import BaseSerializer from "./base.serializer";

export default class ExpositoresEstatisticasLanguageSerializer extends BaseSerializer {
  fromJson(json) {
    const expositoresEstatisticasLanguage = {};
    Object.assign(
      expositoresEstatisticasLanguage,
      json.descricao && { descricao: json.descricao },
      json.id_idioma && { id_idioma: json.id_idioma },
      json.titulo && { titulo: json.titulo },
      json.titulo_grupo && { titulo_grupo: json.titulo_grupo },
      json.idiomas && {
        ...this.idiomaAtual(
          json.idiomas,
          new ExpositoresEstatisticasLanguageSerializer()
        ),
      },
      json.id_expositor_estatistica_idioma && {
        id_expositor_estatistica_idioma: json.id_expositor_estatistica_idioma,
      }
    );

    return expositoresEstatisticasLanguage;
  }

  toJson(expositor) {
    const expositoresEstatisticasLanguageToJson = {};

    Object.assign(
      expositoresEstatisticasLanguageToJson,
      expositor.titulo && {
        titulo: expositor.titulo,
      },
      expositor.idIdioma && {
        id_idioma: expositor.idIdioma,
      }
    );

    return expositoresEstatisticasLanguageToJson;
  }
}
