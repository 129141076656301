import api from "./api.service";
import CoreApiService from "./core-api.service";
import PerfisEmpresaSerializer from "./serializers/perfil-empresa.serializer";

export default class PerfilEmpresaService extends CoreApiService {
  constructor() {
    super(undefined, "perfis-empresa", new PerfisEmpresaSerializer());
  }

  async listarPerfilEmpresaCadastro() {
    this.parentEndpoint = "plataforma";

    const response = await api.get(`${this.parentEndpoint}/${this.endpoint}`);
    const data = response.data;

    return this.convertData(data)
  }
}
