import { paraDataNascimento } from "utils/format-date";
import maskCpfCnpj from "utils/mask-cpf-cnpj";
import unMask from "utils/unmask";
import CalendarioEventoAgendadoSerializer from "./calendario-evento-agendado-serializer";
import EnderecoSerializer from "./endereco.serializer";
import TelefoneSerialier from "./telefone.serializer";
import UsuarioPerfilSerializer from "./usuario-perfil.serializer";

export default class UsuarioSerializer {
  constructor() {
    this.telefoneSerializer = new TelefoneSerialier();
    this.enderecoSerializer = new EnderecoSerializer();
    this.usuarioPerfilSerializer = new UsuarioPerfilSerializer();
    this.calendarioEventoAgendado = new CalendarioEventoAgendadoSerializer();
  }

  fromJson(json) {
    const user = {};

    Object.assign(
      user,
      json.id_usuario && { id: json.id_usuario },
      json.email && {
        email: json.email,
        confEmail: json.email,
      },
      json.disabled && { disabled: json.disabled },
      json.login && { login: json.login },
      json.nome && { nome: json.nome },
      json.cpf && { cpf: maskCpfCnpj(json.cpf) },
      json.image_filename && { imageFileName: json.image_filename },
      json.usuario_perfil && {
        perfil: this.usuarioPerfilSerializer.fromJson(json.usuario_perfil),
      },
      json.trocar_senha && { trocarSenha: json.trocar_senha },
      json.senha_tentativas && { senhaTentativas: json.senha_tentativas },
      json.enderecos
        ? {
            enderecos: json.enderecos.map((item) =>
              this.enderecoSerializer.fromJson(item)
            ),
          }
        : { enderecos: [] },
      json.telefones
        ? {
            telefones: json.telefones.map((item) =>
              this.telefoneSerializer.fromJson(item)
            ),
          }
        : { telefones: [] },

      json.expositores_favoritados
        ? {
            expositoresFavoritos: json.expositores_favoritados.map((item) => {
              return {
                id: item.id_expositor,
                nomeFantasia: item.nome_fantasia,
                uriUnico: item.uri_unico,
              };
            }),
          }
        : { expositoresFavoritos: [] },

      json.produtos_favoritados
        ? {
            produtosFavoritos: json.produtos_favoritados.map((item) => {
              return {
                id: item.id_produto,
                idExpositor: item.id_expositor,
                expositorUriUnico: item.expositor_uri_unico,
                imagens: item.imagens,
              };
            }),
          }
        : { produtosFavoritos: [] },

      json.hasOwnProperty("libera_acesso_visitante") && {
        liberarVisitante: json.libera_acesso_visitante,
      },

      json.eventosAgendados && {
        eventosAgendados: json.eventosAgendados.map((item) =>
          this.calendarioEventoAgendado.fromJson(item)
        ),
      }
    );

    if (user.disabled) {
      user.statusStr = "Inativo";
    } else {
      user.statusStr = "Ativo";
    }

    return user;
  }

  toJson(user) {
    const userToJson = {};

    Object.assign(
      userToJson,
      user.nome && { nome: user.nome },
      user.cpf && { cpf: unMask(user.cpf) },
      user.email && { email: user.email },
      user.confEmail && { conf_email: user.confEmail },
      user.emailConfirmado && { conf_email: user.emailConfirmado },
      user.disabled !== undefined && { disabled: user.disabled },
      user.senha && { senha: user.senha },
      user.confSenha && { conf_senha: user.confSenha },
      user.telefones
        ? {
            telefones: user.telefones.map((item) =>
              this.telefoneSerializer.toJson(item)
            ),
          }
        : { telefones: [] },
      user.enderecos
        ? {
            enderecos: user.enderecos.map((item) =>
              this.enderecoSerializer.toJson(item)
            ),
          }
        : { enderecos: [] },

      user.perfilEmpresa
        ? { id_perfil_empresa: user.perfilEmpresa.value }
        : { id_perfil_empresa: null },

      user.cargo
        ? { id_usuario_cargo: user.cargo.value }
        : { id_usuario_cargo: null },

      user.sexo
        ? { id_usuario_sexo: user.sexo.value }
        : { id_usuario_sexo: null },

      user.dataNascimento && {
        data_nascimento: paraDataNascimento(user.dataNascimento),
      },

      user.empresaVisitante && {
        empresa_visitante: {
          nome_fantasia: user?.empresaVisitante.nomeFantasia || null,
        },
      }
    );
    return userToJson;
  }
}
