import BannerExpositorLanguageSerializer from "./banner-expositor.language.serializer";
import BaseSerializer from "./base.serializer";

export default class BannerExpositorSerializer extends BaseSerializer {
  fromJson(json) {
    const bannerExpositorFromJson = {};
    Object.assign(
      bannerExpositorFromJson,
      json.titulo && { titulo: json.titulo },
      json.idiomas && {
        ...this.idiomaAtual(
          json.idiomas,
          new BannerExpositorLanguageSerializer()
        ),
      },
      json.id_expositor_banner && { id: json.id_expositor_banner },
      json.disabled && { disabled: json.disabled }
    );

    return bannerExpositorFromJson;
  }

  toJson(bannerExpositor) {
    const bannerExpositorToJson = {};

    Object.assign(
      bannerExpositorToJson,
      bannerExpositor.titulo && { titulo: bannerExpositor.titulo },
      bannerExpositor.idiomas && {
        idiomas: bannerExpositor.idiomas.map((item) =>
          this.bannerExpositorLanguageSerializer.toJson(item)
        ),
      }
    );

    if (bannerExpositor.disabled) {
      bannerExpositorToJson.disabled = true;
    } else {
      bannerExpositorToJson.disabled = false;
    }

    return bannerExpositorToJson;
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("file", file[0]);

    return formData;
  }
}
