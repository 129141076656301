import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

export default function DialogRemoverFoto({
  dialogAberto,
  setDialogAberto,
  removerFotoPerfil,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={dialogAberto} style={{ width: "27%" }}>
        <ModalBody>
          {t(
            "Confirmar remoção de foto de perfil? Após a remoção sua foto sumirá de forma instantânea e não poderá ser recuperada."
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            className="dialog-botao-confirmar"
            onClick={() => {
              removerFotoPerfil();
            }}
          >
            {t("Confirmar")}
          </Button>

          <Button
            className="dialog-botao-cancelar"
            onClick={() => setDialogAberto(false)}
          >
            {t("Cancelar")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
