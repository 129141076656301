import React, { useState } from "react";
import { Header } from "./Header";
import { VideoContainer } from "./VideoContainer";
import { NumerosNaoMentem } from "./NumerosNaoMentem";

export function ExpositorSobre() {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <div className="produto-loading-container">
        {loading && (
          <lottie-player
            src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px", margin: "0 auto" }}
            loop
            autoplay
          />
        )}
      </div>

      <div hidden={loading}>
        <Header />
        <VideoContainer setLoading={setLoading} />
        <NumerosNaoMentem />
      </div>
    </>
  );
}
