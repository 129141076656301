import BaseSerializer from "./base.serializer";
import ProductImageLanguageSerializer from "./product-image-language.serializer";

export default class ProductImageSerializer extends BaseSerializer {
  fromJson(json) {
    const productsImage = {};

    Object.assign(
      productsImage,
      json.id_produto_imagem && { id: json.id_produto_imagem },
      json.id_produto && { idProduto: json.id_produto },
      json.image_filename && { imageFile: json.image_filename },
      json.idiomas && {
        ...this.idiomaAtual(
          json.idiomas,
          new ProductImageLanguageSerializer()
        ),
      }
    );

    return productsImage;
  }

  toJson(productImage) {
    const productImagesJson = {};

    Object.assign(
      productImagesJson,
      productImage.idiomas && {
        idiomas: productImage.idiomas.map((item) =>
          this.productsLanguageImages.toJson(item)
        ),
      },
      productImage.imageFile && { image_filename: productImage.imageFile }
    );

    return productImagesJson;
  }

  toFormData(files) {
    let formData = new FormData();
    files.forEach((item) => formData.append("files", item));

    return formData;
  }
}
