import React from "react";
import { Link } from "react-router-dom"; // Importe o Link do react-router-dom
import "./style.scss";

const Breadcrumb = ({ title, nomeCurso, modulo, arrow, cor, id, tipo }) => {
  return (
    <div className={`cabecalho-${cor}-${tipo}`}>
      <Link to="/assentador-cursos">
        <p>Cursos</p>
      </Link>
      {nomeCurso && (
        <>
          <img src={arrow} alt="" />
          <Link to={`/cursos-modulo/${id}`}>
            <p>{nomeCurso}</p>
          </Link>
        </>
      )}
      {modulo && (
        <>
          <img src={arrow} alt="" />
          <p>{modulo}</p>
        </>
      )}
      {title && (
        <>
          <img src={arrow} alt="" />
          <p>{title}</p>
        </>
      )}
    </div>
  );
};

export default Breadcrumb;
