/* eslint-disable react-hooks/exhaustive-deps */
import CarregarImagem from "components/CarregarImagem";
import React, { useEffect, useState } from "react";
import ReactElasticCarousel from "react-elastic-carousel";
import { useTranslation } from "react-i18next";
import EditionService from "services/edition.service";
import { incrementarCliqueEmPublicidade } from "utils/incrementar-clique-em-publicidade";
import QueryOptions from "utils/query-options";
import useWindowDimensions from "utils/verificar-dimensao-da-tela";
import PropagandadService from "../../services/propagandad.service";

export default function BannersPublicidade({ idAreaPublicidade, bannerHome }) {
  const [publicidadesDesktop, setPublicidadesDesktop] = useState();
  const [publicidadesMobile, setPublicidadesMobile] = useState();
  const _publicidadeService = new PropagandadService();
  const { i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const [time] = useState(new Date().getTime());

  function verificarBannersValidosDesktop(banner) {
    for (let index = 0; index < banner.areas.length; index++) {
      if (
        banner.areas[index].id_publicidade_area === idAreaPublicidade &&
        !banner.disabled
      ) {
        if (
          banner.areas[index].id_publicidade_area === 1 &&
          banner.hasOwnProperty("filenameHome")
        ) {
          return banner;
        } else if (
          banner.areas[index].id_publicidade_area !== 1 &&
          banner.hasOwnProperty("filename")
        ) {
          return banner;
        }
      }
    }
  }

  function verificarBannersValidosMobile(banner) {
    for (let index = 0; index < banner.areas.length; index++) {
      if (
        banner.areas[index].id_publicidade_area === idAreaPublicidade &&
        !banner.disabled
      ) {
        if (
          banner.areas[index].id_publicidade_area === 1 &&
          banner.hasOwnProperty("filenameHomeMobile")
        ) {
          return banner;
        } else if (
          banner.areas[index].id_publicidade_area !== 1 &&
          banner.hasOwnProperty("filenameMobile")
        ) {
          return banner;
        }
      }
    }
  }

  useEffect(async () => {
    const _edicaoService = new EditionService();
    const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
    const edicaoAtiva = await _edicaoService.carregarEdicaoAtual(
      queryOptionsEdicaoAtiva
    );

    const queryOptions = new QueryOptions([["edicao", edicaoAtiva.id]]);

    const publicidadeResponse = await _publicidadeService.list(queryOptions);

    setPublicidadesDesktop(
      publicidadeResponse.filter(verificarBannersValidosDesktop)
    );
    setPublicidadesMobile(
      publicidadeResponse.filter(verificarBannersValidosMobile)
    );
  }, [i18n.language]);

  function checarBannerClicavel(publicidade) {
    if (publicidade.link) {
      incrementarCliqueEmPublicidade(publicidade);
    }
  }

  return (
    <>
      {width >= 768 ? (
        <div hidden={publicidadesDesktop?.length <= 0}>
          <ReactElasticCarousel
            enableAutoPlay
            autoPlaySpeed={3000}
            showArrows={false}
          >
            {publicidadesDesktop?.map((publicidade) => (
              <div
                key={publicidade.id}
                className="each-slide"
                style={publicidade.link && { cursor: "pointer" }}
                onClick={() => {
                  checarBannerClicavel(publicidade);
                }}
              >
                <>
                  <div>
                    <CarregarImagem
                      alt="banner do expositor"
                      src={`${process.env.REACT_APP_API_URL}publicidades/${
                        publicidade?.idPublicidadeIdioma
                      }/display?${
                        bannerHome ? "home_desktop=true" : "desktop"
                      }&${time}`}
                      className="imagem-banners"
                    />
                  </div>
                </>
              </div>
            ))}
          </ReactElasticCarousel>
        </div>
      ) : (
        <div>
          <ReactElasticCarousel
            enableAutoPlay
            autoPlaySpeed={3000}
            showArrows={false}
          >
            {publicidadesMobile?.map((publicidade) => (
              <div
                key={publicidade.id}
                className="each-slide"
                style={publicidade.link && { cursor: "pointer" }}
                onClick={() => {
                  incrementarCliqueEmPublicidade(publicidade);
                }}
              >
                <>
                  <div className="container-mobile-img">
                    <CarregarImagem
                      alt="banner do expositor"
                      src={`${process.env.REACT_APP_API_URL}publicidades/${
                        publicidade?.idPublicidadeIdioma
                      }/display?${
                        bannerHome ? "home_mobile=true" : "mobile"
                      }&${time}`}
                      className="imagem-banners"
                    />
                  </div>
                </>
              </div>
            ))}
          </ReactElasticCarousel>
        </div>
      )}
    </>
  );
}
