import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import CertificadoService from "services/certificado.service";
import CalendarioEventosAgendados from "components/expo-revestir/CalendarioEventosAgendados";
import EventService from "services/evento.service";
export default function ListagemComprovante() {
  const [certificados, setCertificados] = useState([]);
  const [dataCertificados, setDataCertificados] = useState([]);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    async function init() {
      try {
        const _certificadoService = new CertificadoService();
        const data = await _certificadoService.list();
        const _eventoService = new EventService();
        const dataEventos = await _eventoService.list();
        setCertificados(data);
        setDataCertificados(dataEventos);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }

    init();
  }, []);

  function onDownload(certificado) {
    const _certificadoService = new CertificadoService();
    _certificadoService.download(certificado.idUsuario, certificado.idEvento);
  }

  return (
    <>
      <Container>
        <Row>
          <Col xl="12" lg="12" md="12" sm="12" xs="12">
            <CalendarioEventosAgendados />
          </Col>
        </Row>
      </Container>
      <div className="meus-comprovantes-container">
        <Container>
          <div className="meus-comprovantes-title">
            <h1>{t("Meus Certificados")}</h1>
          </div>
          {loading && (
            <lottie-player
              src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px", margin: "0 auto" }}
              loop
              autoplay
            />
          )}

          {!loading &&
            certificados.map((item) => (
              <Row className="meus-comprovantes">
                <Col
                  className="evento-coluna-tabela d-flex"
                  xl="6"
                  lg="7"
                  md="6"
                  sm="6"
                  xs="12"
                >
                  {item.evento}
                </Col>
                {dataCertificados.map(
                  (data) =>
                    item.idEvento === data.id && (
                      <Col
                        className="evento-coluna-tabela"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        xs="12"
                      >
                        {data.dataInicioStr}
                      </Col>
                    )
                )}

                <Col
                  className="evento-coluna-tabela"
                  xl="2"
                  lg="2"
                  md="2"
                  sm="2"
                  xs="12"
                >
                  <div
                    onClick={() => onDownload(item)}
                    style={{ textAlign: "center", cursor: "pointer" }}
                  >
                    {t("Baixar")}
                  </div>
                </Col>
              </Row>
            ))}

          {!loading && !certificados.length && (
            <h4 className="text-center">
              {t("Você ainda não possui Certificados")}
            </h4>
          )}
        </Container>
      </div>
    </>
  );
}
