import BaseSerializer from "./base.serializer";
import CargoIdiomaSerializer from "./cargo-idioma.serializer";

export default class CargoSerializer extends BaseSerializer {
  fromJson(json) {
    const cargo = {};

    Object.assign(
      cargo,
      json.idiomas && {
        ...this.idiomaAtual(json.idiomas, new CargoIdiomaSerializer()),
      }
    );

    return cargo;
  }
}
