import { useTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

export default function SelecionarIdioma() {
  const { i18n } = useTranslation();

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle
        nav
        caret
        aria-expanded={false}
        aria-haspopup={true}
        color="default"
        data-toggle="dropdown"
        role="button"
      >
        {i18n.language === "pt-BR" && "Português"}
        {i18n.language === "en" && "English"}
        {i18n.language === "es" && "Spanish"}
      </DropdownToggle>
      <DropdownMenu className="dropdown-warning" right>
        <DropdownItem onClick={() => i18n.changeLanguage("pt-BR")}>
          Português
        </DropdownItem>
        <DropdownItem onClick={() => i18n.changeLanguage("en")}>
          English
        </DropdownItem>
        <DropdownItem onClick={() => i18n.changeLanguage("es")}>
          Spanish
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
