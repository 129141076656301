import React from "react";
import ReactElasticCarousel from "react-elastic-carousel";
import { Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";

export default function ModalVerProdutoExpositor({
  modalProdutoAberto,
  setModalProdutoAberto,
  imagens,
  verificarImagemArrow,
}) {
  return (
    <Modal
      className="modal-ver-produto"
      size="xl"
      toggle={() => {
        setModalProdutoAberto(false);
      }}
      isOpen={modalProdutoAberto}
    >
      <ModalBody>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="12">
            <ReactElasticCarousel
              showArrows={verificarImagemArrow ? true : false}
            >
              {imagens?.map((imagem) => (
                <div
                  className="each-slide"
                  key={imagem.id}
                  produtoAtual={imagem}
                  onClick={() => {
                    setModalProdutoAberto(true);
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL}produto-imagens/${
                      imagem.id
                    }/display?${new Date().getTime()}`}
                    alt="Imagem de um produto"
                    className="imagem-banners"
                  />
                </div>
              ))}
            </ReactElasticCarousel>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
}
