import languagesSerializer from "./idioma.serializer";

export default class CatalogoSerializer {
  constructor() {
    this.idiomaSerializer = new languagesSerializer();
  }

  fromJson(json) {
    const catalogo = {};

    Object.assign(
      catalogo,
      json.id_catalogo && { id: json.id_catalogo },
      json.titulo && { titulo: json.titulo },
      json.arquivo_filename && { arquivo: json.arquivo_filename },
      json.image_filename && { imagem: json.image_filename },
      json.data_criacao && { dataCriacao: json.data_criacao },
      json.hasOwnProperty("disabled") && { disabled: json.disabled },
      json.idioma && { idioma: this.idiomaSerializer.fromJson(json.idioma) }
    );

    return catalogo;
  }
}
