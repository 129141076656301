import api from "./api.service";
import CoreApiService from "./core-api.service";
import SexoSerializer from "./serializers/sexo.serializer";

export default class SexoService extends CoreApiService {
  constructor() {
    super(undefined, "usuario-sexos", new SexoSerializer());
  }

  async listarSexoCadastro() {
    this.parentEndpoint = "plataforma";

    const response = await api.get(`${this.parentEndpoint}/${this.endpoint}`);
    const data = response.data;

    return this.convertData(data);
  }
}
