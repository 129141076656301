import DuvidasFrequentes from "components/expo-revestir/DuvidaArea";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalFaleConosco from "../../components/expo-revestir/ModalFaleConosco";
import CampoCustomizadoItem from "services/campo-customizado-item.service";

export default function DuvidasPage() {
  const { i18n } = useTranslation();
  const [modalAberto, setModalAberto] = useState(false);
  const [campoCustomizado, setCampoCustomizado] = useState([]);

  useEffect(() => {
    async function init() {
      const _campoCustomizadoService = new CampoCustomizadoItem();
      const campoCustomizadoResponse = await _campoCustomizadoService.listSub(
        3
      );
      setCampoCustomizado(campoCustomizadoResponse);
    }

    init();
  }, [i18n.language]);

  return (
    <div className="background-duvidas-container">
      <ModalFaleConosco
        modalAberto={modalAberto}
        setModalAberto={setModalAberto}
      />

      <div className="body-duvidas">
        <div className="duvidas-header">
          <div className="h1-container">
          <h1>{campoCustomizado[0]?.item}</h1>

            </div>
          <span>{campoCustomizado[1]?.item}</span>
        </div>

        <DuvidasFrequentes campoCustomizado={campoCustomizado} />

        <div className="duvidas-footer">
          <p>{campoCustomizado[2]?.item}</p>

          <div>
            <button type="button" onClick={() => setModalAberto(true)}>
              {campoCustomizado[3]?.item}
            </button>
          </div>
        </div>
      </div>

      <div className="espaco-background"></div>
    </div>
  );
}
