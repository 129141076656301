import { Container } from "react-bootstrap";
import LogoAnfacer  from "../../assets/img/assentador/footer/logo-anfacer.svg";
import LogoCCB  from "../../assets/img/assentador/footer/logo-ccb.svg";
import LogoCentralAssentador  from "../../assets/img/assentador/footer/logo-central-do-assentador.svg";
import LogoCortag  from "../../assets/img/assentador/footer/logo-cortag.svg";
import LogoSouCunheiro  from "../../assets/img/assentador/footer/logo-sou-cunheiro.svg";

export default function AssentadorFooter() {

  return (
    <footer className="footer footer-black footer-assentador">
      <Container>
        <div className="logos">
          <div className="realizacao">
            <p>Realização</p>
            <div>
              <img src={LogoCentralAssentador} alt="" />
              <img src={LogoAnfacer} alt="" />
            </div>
          </div>

          <div className="realizacao">
            <p>Apoio</p>
            <div>
              <img src={LogoSouCunheiro} alt="" />
              <img src={LogoCortag} alt="" />
              <img src={LogoCCB} alt="" />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
