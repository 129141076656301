import api from "./api.service";
import CoreApiService from "./core-api.service";
import EventLogosSerializer from "./serializers/event-logos.serializer";
import EventosBannersSerializer from "./serializers/evento-banner.serializer";
import EventSerializer from "./serializers/evento.serializer";

export default class EventService extends CoreApiService {
  constructor() {
    super("expositores", "eventos", new EventSerializer());
  }

  async readUri(uri) {
    const response = await api.get(`evento/${uri}`);
    const data = response.data;
    return this.serializer.fromJson(data);
  }

  async getLogo(id) {
    this.parentEndpoint = "eventos";
    this.endpoint = "logotipos";
    this.serializer = new EventLogosSerializer();

    const response = await api.get(
      `${this.parentEndpoint}/${id}/${this.endpoint}`
    );
    const data = response.data;
    return this.serializer.fromJson(data);
  }

  async getBanner(id) {
    this.parentEndpoint = "eventos";
    this.serializer = new EventosBannersSerializer();

    const response = await api.get(`${this.parentEndpoint}/${id}/banners`);
    const data = response.data;

    return data.items.map((item) => this.serializer.fromJson(item));
  }

  async incrementarClique(idBanner) {
    this.endpoint = "evento-banners";
    const response = await api.put(`${this.endpoint}/${idBanner}/cliques`);

    const data = response.data;
    return this.serializer.fromJson(data.oret);
  }
}
