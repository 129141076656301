export default class EventLogosSerializer {
  fromJson(json) {
    const eventLogo = {};
    Object.assign(
      eventLogo,
      json.items && { logos: json.items.map((item) => item) }
    );

    return eventLogo;
  }

  toJson(eventLogo) {
    const eventLogoToJson = {};

    Object.assign(
      eventLogoToJson,
      eventLogo.titulo && { titulo: eventLogo.titulo }
    );

    return eventLogoToJson;
  }
}
