import { Link } from "react-router-dom";
import "./style.scss";

function VideoCard({
  duracao,
  title,
  watchedMinutes,
  totalMinutes,
  isWatched,
  contagem,
  idCursoAulaIdioma,
}) {
  const progress = (watchedMinutes / totalMinutes) * 100;

  let circleColor = "red";
  if (progress === 100) {
    circleColor = "#93D501";
  } else if (progress > 0) {
    circleColor = "#52C9E8";
  }

  const progressCircleClass = isWatched
    ? "progress-circle-watched"
    : "progress-circle-not-watched";

  return (
    <Link to={`/video/${idCursoAulaIdioma}`}>
      <div className="video-card">
        <div className="video-info">
          <div className="video-name">Aula - {contagem + 1}</div>
          <div className="video-title">{title}</div>
          <div className="video-duration">{`${duracao}`}</div>
        </div>
        <div
          className={`progress-circle ${progressCircleClass}`}
          style={{ backgroundColor: circleColor }}
        />
      </div>
    </Link>
  );
}

export default VideoCard;
