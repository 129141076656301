import api from "./api.service";
import CoreApiService from "./core-api.service";
import ExpositoresEstatisticasSerializer from "./serializers/expositores-estatisticas.serializer";

export default class ExpositoresEstatisticasService extends CoreApiService {
  constructor() {
    super(
      "expositores",
      "estatisticas",
      new ExpositoresEstatisticasSerializer()
    );
  }

  async readSub(id) {
    const response = await api.get(
      `${this.parentEndpoint}/${id}/${this.endpoint}`
    );
    const data = response.data;
    return this.serializer.fromJson(data);
  }

  async updateExpositorEstatistica(item, isFormDate = false) {
    const response = await api.put(
      `expositor-estatistica/${item.id}`,
      isFormDate
        ? this.serializer.toFormData(item)
        : this.serializer.toJson(item)
    );

    const data = response.data;
    return this.serializer.fromJson(data.oret);
  }
}
