export default class EventoBannerIdiomasSerializer {
  fromJson(json) {
    const eventosBannerIdiomaSerializer = {};

    Object.assign(
      eventosBannerIdiomaSerializer,
      json.link && {
        link: json.link,
      },
      json.id_idioma && {
        idIdioma: json.id_idioma,
      },
      json.filename_mobile && {
        filenameMobile: json.filename_mobile,
      },
      json.id_evento_banner && {
        idEventoBanner: json.id_evento_banner,
      },
      json.filename && {
        filename: json.filename,
      },
      json.id_evento_banner_idioma && {
        idEventoBannerIdioma: json.id_evento_banner_idioma,
      }
    );

    return eventosBannerIdiomaSerializer;
  }

  toJson(eventoBannerIdiomasSerializer) {
    const eventoBannerIdiomasSerializerToJson = {};

    Object.assign(
      eventoBannerIdiomasSerializerToJson,
      eventoBannerIdiomasSerializer.link && {
        link: eventoBannerIdiomasSerializer.link,
      },
      eventoBannerIdiomasSerializer.idIdioma && {
        id_idioma: eventoBannerIdiomasSerializer.idIdioma,
      },
      eventoBannerIdiomasSerializer.idEventoBannerIdioma && {
        id_evento_banner_idioma:
          eventoBannerIdiomasSerializer.idEventoBannerIdioma,
      }
    );

    return eventoBannerIdiomasSerializerToJson;
  }
}
