export default class MapaInterativoSerializer {
  fromJson(json) {
    const map = {};

    Object.assign(
      map,
      json.id_mapa && { id: json.id_mapa },
      json.filename && { filename: json.filename },
      json.id_edicao && { idEdicao: json.id_edicao },
      json.html && { html: json.html },
      json.json && { json: json.json },
      json.nome && { nome: json.nome }
    );

    return map;
  }
}
