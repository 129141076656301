import { format } from "date-fns";
import convertDate from "utils/conversor-data";
import { fromDateGmt } from "utils/format-date";


export default class EditionSerializer {
  fromJson(json) {
    const edition = {};

    Object.assign(
      edition,
      json.id_edicao && { id: json.id_edicao, value: json.id_edicao },
      json.nome && { nome: json.nome, label: json.nome },

      json.data_inicio && {
        dataInicio: new Date(json.data_inicio),
        dataInicioTeste: json.data_inicio,
        dataInicioStr: format(fromDateGmt(json.data_inicio), "dd/MM/yyyy"),
      },
      json.data_fim && {
        dataFim: new Date(json.data_fim),
        dataFimStr: format(fromDateGmt(json.data_fim), "dd/MM/yyyy"),
      }
    );

    return edition;
  }

  toJson(edition) {
    return {
      data_fim: convertDate(edition.dataFim),
      data_inicio: convertDate(edition.dataInicio),
      nome: edition.nome,
    };
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("file", file[0]);

    return formData;
  }
}
