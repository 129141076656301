import { saveAs } from "file-saver";
import api from "./api.service";
import CoreApiService from "./core-api.service";
import CursosSerializer from "./serializers/cursos.serializer";

export default class CursoMonitoramentoService extends CoreApiService {
  constructor() {
    super(undefined, "cursos/monitoramentos", new CursosSerializer());
  }

  async getAula(idCursoAulaIdioma) {
    const response = await api.get(
      `${this.endpoint}/aulas/${idCursoAulaIdioma}`
    );
    
    return response.data;
  }

  async atualizaMonitoramento(data) {
    const response = await api.post(this.endpoint, data);
    return response;
  }

  async downloadCertificadoCurso(idCursoMonitoramento, name) {
    const response = await api.get(
      `${this.endpoint}/certificados/${idCursoMonitoramento}/display`,
      {
        responseType: "blob",
      }
    );

    const filename = name.split(' ').join('-')

    saveAs(response.data, `${filename}.pdf`);
  }
}
