export function ordenarCursoPorDestaque(a, b) {
  if (a.destaque && !b.destaque) {
    return -1;
  }

  if (!a.destaque && b.destaque) {
    return 1;
  }

  return 0;
}
