/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import ExpositoresEstatisticasService from "services/expositor-estatistica.service";
import { AppContext } from "store";

export function NumerosNaoMentem() {
  const { idExpositor } = useContext(AppContext);
  const _expositoresEstatisticaService = new ExpositoresEstatisticasService();
  const [estatisticasExpositor, setEstatisticasExpositor] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    async function init() {
      const estatisticasExpositor =
        await _expositoresEstatisticaService.readSub(idExpositor);
      setEstatisticasExpositor(estatisticasExpositor);
    }

    init();
  }, [idExpositor, i18n.language]);
  return (
    <div className="numeros-nao-mentem d-flex justify-content-center">
      {estatisticasExpositor && (
        <Container>
          <Row>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="numeros-nao-mentem-h1"
              >
                <h1>{t("Nossos números")}</h1>
              </Col>

              <Container>
                <Row>
                  {estatisticasExpositor?.estatisticas[1] && (
                    <Col xs="12" sm="12" md="6" lg="3" xl="3" className="box">
                      <div className="box-background">
                        <h1>
                          {estatisticasExpositor?.estatisticas[1]?.titulo}
                        </h1>
                        <p>
                          {estatisticasExpositor?.estatisticas[1]?.descricao}
                        </p>
                      </div>
                    </Col>
                  )}

                  {estatisticasExpositor?.estatisticas[2] && (
                    <Col xs="12" sm="12" md="6" lg="3" xl="3" className="box">
                      <div className="box-background">
                        <h1>
                          {estatisticasExpositor?.estatisticas[2]?.titulo}
                        </h1>
                        <p>
                          {estatisticasExpositor?.estatisticas[2]?.descricao}
                        </p>
                      </div>
                    </Col>
                  )}

                  {estatisticasExpositor?.estatisticas[3] && (
                    <Col xs="12" sm="12" md="6" lg="3" xl="3" className="box">
                      <div className="box-background">
                        <h1>
                          {estatisticasExpositor?.estatisticas[3]?.titulo}
                        </h1>
                        <p>
                          {estatisticasExpositor?.estatisticas[3]?.descricao}
                        </p>
                      </div>
                    </Col>
                  )}

                  {estatisticasExpositor?.estatisticas[4] && (
                    <Col xs="12" sm="12" md="6" lg="3" xl="3" className="box">
                      <div className="box-background">
                        <h1>
                          {estatisticasExpositor?.estatisticas[4]?.titulo}
                        </h1>
                        <p>
                          {estatisticasExpositor?.estatisticas[4]?.descricao}
                        </p>
                      </div>
                    </Col>
                  )}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
