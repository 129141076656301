/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Headroom from "headroom.js";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { Col, Container, Nav, Navbar, NavItem, Row } from "reactstrap";
import UsuarioService from "services/usuario.service";
import likesIcon from "../../assets/img/expo-revestir/icons/likes-icons.svg";
import likesIconFilled from "../../assets/img/expo-revestir/icons/likes-icons-filled.svg";
import Share from "../../assets/img/expo-revestir/icons/share.svg";
import whats from "../../assets/img/expo-revestir/icons/whats.svg";
import { AppContext } from "store";
import ExpositoresService from "services/expositores.service";
import EnviarWhatsapp from "utils/enviar-whatsapp";
import copyClipboard from "utils/copy-clipboard";
import ModalFaleComExpositor from "components/modalFaleComExpositor";
import RedeSocialService from "services/rede-social.service";
import { useParams } from "react-router";

function ExpositorNav() {
  const { uriUnico } = useParams();
  const { user, setUser, idExpositor } = useContext(AppContext);
  const [bodyClick, setBodyClick] = useState(false);
  const [expositor, setExpositor] = useState();
  const { t } = useTranslation();
  const [modalAberto, setModalAberto] = useState(false);
  const [redesSociais, setRedesSociais] = useState({});
  const [time] = useState(new Date().getTime());
  const [loading, setLoading] = useState(true);
  const [copiarLink, setCopiarLink] = useState(false);

  const _usuarioService = new UsuarioService();

  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    headroom.init();

    async function init() {
      const _expositorService = new ExpositoresService();
      const data = await _expositorService.read(idExpositor);

      const redes = {};
      if (data.hasOwnProperty("redesSociais") && data.redesSociais.length) {
        data.redesSociais.forEach((item) => {
          redes[item.id_rede_social_tipo.toString()] = { ...item };
        });
      }

      setRedesSociais(redes);
      setExpositor(data);
      setLoading(false);
    }

    init();
  }, [idExpositor]);

  function paginaAtual(pagina) {
    return window.location.pathname.includes(pagina);
  }

  async function onFavoritar() {
    _usuarioService
      .favoritar({ id_expositor: expositor.id })
      .then((response) => {
        const aux = { ...user };

        if (response.favoritado) {
          aux.expositoresFavoritos.push({
            id: idExpositor,
            nomeFantasia: expositor.nomeFantasia,
            uriUnico: uriUnico,
          });
        } else {
          aux.expositoresFavoritos = aux.expositoresFavoritos.filter(
            (item) => item.id !== idExpositor
          );
        }

        setUser(aux);
      });
  }

  function verificaFavorito() {
    if (user && user.hasOwnProperty("expositoresFavoritos")) {
      return user.expositoresFavoritos.find((item) => item.id === idExpositor);
    }
  }

  function compartilharWhatsapp(rede) {
    const texto = `Olá, ${expositor.nomeFantasia}\n`;
    EnviarWhatsapp(texto, rede.link);
  }

  function copiarUrl() {
    copyClipboard(window.location.href);
    setCopiarLink(true);
    setTimeout(() => {
      setCopiarLink(false);
    }, 5000);
  }

  async function adicionarClique(idRedeSocial) {
    const _redeSocialService = new RedeSocialService();
    await _redeSocialService.incrementarClique(idRedeSocial);
  }

  return (
    <>
      <ModalFaleComExpositor
        modalAberto={modalAberto}
        setModal={setModalAberto}
      />

      <div className="produto-loading-container">
        {loading && (
          <lottie-player
            src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px", margin: "0 auto" }}
            loop
            autoplay
          />
        )}
      </div>

      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
          }}
        />
      ) : null}

      <Container hidden={loading}>
        <Row className="nav-bar-container">
          <Col
            xl="2"
            lg="3"
            md="4"
            sm="6"
            xs="4"
            className="logo-expositor-container"
          >
            <div className="logo-expositor">
              <img
                src={`${process.env.REACT_APP_API_URL}expositores/${idExpositor}/logotipos/display?${time}`}
                alt={expositor?.nomeFantasia}
              />
            </div>
          </Col>
          <Col xl="10" lg="9" md="8" sm="6" xs="8">
            <Row>
              <Col xl="1" lg="1" md="2" sm="3" xs="2">
                <div
                  title={t("Favoritar")}
                  className="icon-favoritar"
                  onClick={onFavoritar}
                >
                  <img
                    src={verificaFavorito() ? likesIconFilled : likesIcon}
                    alt="ícone para favoritar elemento"
                    className="favoritar-pointer"
                  />
                </div>
              </Col>

              <Col xl="1" lg="1" md="2" sm="3" xs="2">
                <div title={t("Compartilhar")} onClick={copiarUrl}>
                  <img
                    src={Share}
                    alt="ícone do compartilhar"
                    className="favoritar-pointer compartilhar-icone"
                  />

                  {copiarLink && (
                    <span className="aviso-link-copiado">
                      {t("Link copiado")}
                    </span>
                  )}
                </div>
              </Col>

              <Col xl="3" lg="3" md="4" sm="4" xs="4">
                {redesSociais[5] && expositor?.contatoDoExpositor && (
                  <div
                    title={t("Fale com o expositor")}
                    onClick={() => {
                      compartilharWhatsapp(redesSociais[5]);
                      adicionarClique(redesSociais[5].id_rede_social);
                    }}
                  >
                    <img
                      src={whats}
                      alt="ícone para entrar em contato"
                      className="favoritar-pointer"
                    />
                  </div>
                )}
              </Col>

              <Col xl="7" lg="7" md="12" sm="10" xs="8">
                <Row className="social-media-nav-expositor-container">
                  {expositor?.redesSociais?.map((redeSocial) => (
                    <>
                      {redeSocial.id_rede_social_tipo === 1 &&
                        redeSocial.link !== "" && (
                          <Col xl="1" lg="1" md="2" sm="2" xs="2">
                            <a
                              href={redeSocial.link}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => {
                                adicionarClique(redeSocial.id_rede_social);
                              }}
                            >
                              <i className="fa fa-facebook" />
                            </a>
                          </Col>
                        )}

                      {redeSocial.id_rede_social_tipo === 2 &&
                        redeSocial.link !== "" && (
                          <Col xl="1" lg="1" md="2" sm="2" xs="2">
                            <a
                              href={redeSocial.link}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => {
                                adicionarClique(redeSocial.id_rede_social);
                              }}
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </Col>
                        )}

                      {redeSocial.id_rede_social_tipo === 3 &&
                        redeSocial.link !== "" && (
                          <Col xl="1" lg="1" md="2" sm="2" xs="2">
                            <a
                              href={redeSocial.link}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => {
                                adicionarClique(redeSocial.id_rede_social);
                              }}
                            >
                              <i className="fa fa-pinterest" />
                            </a>
                          </Col>
                        )}

                      {redeSocial.id_rede_social_tipo === 4 &&
                        redeSocial.link !== "" && (
                          <Col xl="1" lg="1" md="2" sm="2" xs="2">
                            <a
                              href={redeSocial.link}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => {
                                adicionarClique(redeSocial.id_rede_social);
                              }}
                            >
                              <i className="fa fa-youtube" />
                            </a>
                          </Col>
                        )}

                      {redeSocial.id_rede_social_tipo === 6 &&
                        redeSocial.link !== "" && (
                          <Col xl="1" lg="1" md="2" sm="2" xs="2">
                            <a
                              href={redeSocial.link}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => {
                                adicionarClique(redeSocial.id_rede_social);
                              }}
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </Col>
                        )}
                    </>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="nav-bar-menu">
          <Col xl="2"></Col>
          <Col xl="10" lg="12" md="12" sm="12">
            <Navbar
              className="fixed-top nav-bar-central-container"
              expand="lg"
              id="navbar-main"
            >
              <Container className="nav-items">
                <Nav className="ml-auto" navbar>
                  <NavItem active={paginaAtual("produtos")}>
                    <NavLink to={`/expositor/${uriUnico}/produtos`} tag={Link}>
                      {t("Produtos")}
                    </NavLink>
                  </NavItem>
                  {expositor?.moduloGaleriaVideos && (
                    <NavItem active={paginaAtual("videos")}>
                      <NavLink to={`/expositor/${uriUnico}/videos`} tag={Link}>
                        {t("Vídeos")}
                      </NavLink>
                    </NavItem>
                  )}

                  {expositor?.modulosCatalogos && (
                    <NavItem active={paginaAtual("catalogos")}>
                      <NavLink
                        to={`/expositor/${uriUnico}/catalogos`}
                        tag={Link}
                      >
                        {t("Catálogos")}
                      </NavLink>
                    </NavItem>
                  )}

                  {expositor?.modulosEventos && (
                    <NavItem active={paginaAtual("eventos")}>
                      <NavLink to={`/expositor/${uriUnico}/eventos`} tag={Link}>
                        {t("Eventos")}
                      </NavLink>
                    </NavItem>
                  )}

                  <NavItem active={paginaAtual("sobre")}>
                    <NavLink to={`/expositor/${uriUnico}/sobre`} tag={Link}>
                      {t("Sobre")}
                    </NavLink>
                  </NavItem>

                  {expositor?.contatoDoExpositor && (
                    <NavItem>
                      <a onClick={() => setModalAberto(!modalAberto)}>
                        {t("Fale com o expositor")}
                      </a>
                    </NavItem>
                  )}
                </Nav>
              </Container>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ExpositorNav;
