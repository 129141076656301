import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/scss/paper-kit.scss";

// others
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/expo-revestir.scss";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import Routes from "routes";
import history from "services/history";
import Store from "store";
import "./i18n";

ReactDOM.render(
  <Suspense fallback="loading">
    <Store>
      <Router history={history}>
        <Routes />
      </Router>
    </Store>
  </Suspense>,
  document.getElementById("root")
);
