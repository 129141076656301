/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react plugin used to create DropdownMenu for selecting items
import ExpoLogo from "../../assets/img/expo-revestir/logo-250x250px.png";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

function FooterAboutUs() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <footer className="footer footer-big footer-black bg-footer">
        <Container>
          <Row className="row-footer">
            <Col xl="2" lg="2" md="3" sm="4" xs="4">
              <img src={ExpoLogo} alt="Logo da Expo Revestir" />
            </Col>
            <Col xl="6" lg="6" md="9">
              <Row>
                <Col xl="4" lg="5" md="5">
                  <div className="links">
                    <ul className="uppercase-links stacked-links">
                      <li>
                        <a>Expo Revestir</a>
                      </li>
                      <li>
                        <a onClick={() => history.push("/expositores")}>
                          {t("Expositores")}
                        </a>
                      </li>
                      <li>
                        <a onClick={() => history.push("/programacao-eventos")}>
                          {t("Programação")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xl="4" lg="5" md="5">
                  <div className="links">
                    <ul className="uppercase-links stacked-links">
                      <li>
                        <a>{t("Sobre")}</a>
                      </li>
                      <li>
                        <a onClick={() => history.push("/contato")}>
                          {t("FAQ de visitantes")}
                        </a>
                      </li>
                      <li>
                        <a onClick={() => history.push("/contato")}>
                          {t("Parceria e apoio")}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.exporevestir.com.br/sobre/termos-de-uso-e-politica-de-privacidade"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("Termos e serviços")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl="4" lg="4" md="9">
              <div className="links social-media-footer">
                <Row>
                  <Col>
                    <ul className="uppercase-links stacked-links">
                      <li>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          {t("Fale conosco")}
                        </a>
                      </li>
                      <li>
                        <a
                          href="info@exporevestir.com.br"
                          onClick={(e) => e.preventDefault()}
                        >
                          info@exporevestir.com.br
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ul className="social-media-footer-container">
                      <li>
                        <a
                          href="https://www.facebook.com/exporevestir"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/exporevestir_oficial/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <i className="fa fa-instagram" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://br.pinterest.com/exporevestir/_created/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <i className="fa fa-pinterest" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/user/ANFACER"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <i className="fa fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterAboutUs;
