import React from "react";
import { Messages } from "primereact/messages";
import { Message } from "primereact/message";
import { animateScroll } from "react-scroll";

export default function AlertMenssage() {
  return (
    <Messages
      ref={(el) => {
        Message.messages = el;
        if (el && el.state && el.state.messages.length)
          animateScroll.scrollToBottom();
      }}
    ></Messages>
  );
}
