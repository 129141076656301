import BannersPublicidade from "components/expo-revestir/BannersPublicidade";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import ExpositoresListagem from "./ExpositoresListagem";

export default function PaginaExpositores() {
  const { t } = useTranslation();

  return (
    <div>
      <BannersPublicidade idAreaPublicidade={3} bannerHome={false} />

      <div className="header-container">
        <h1>{t("Expositores")}</h1>
      </div>
      <Container>
        <ExpositoresListagem />
      </Container>
    </div>
  );
}
