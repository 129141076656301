import api from "./api.service";
import CoreApiService from "./core-api.service";
import CertificadoSerializer from "./serializers/certificado.serializer";

export default class CertificadoService extends CoreApiService {
  constructor() {
    super(undefined, "certificados", new CertificadoSerializer());
  }

  async list() {
    const response = await api.get(`${this.endpoint}`);
    const data = response.data || [];

    if (data.length) {
      return data.map((item) => this.serializer.fromJson(item));
    }

    return data;
  }

  download(idUsuario, idEvento) {
    window.open(
      `${process.env.REACT_APP_API_URL}certificados/${idUsuario}/${idEvento}/display`
    );
  }
}
