import validaDigitos from "./valida-digitos";

export function validaTelefone(parent, campo) {
  campo = validaDigitos(campo);

  if (
    validaDigitos(parent.ddi) ||
    validaDigitos(parent.ddd) ||
    validaDigitos(parent.telefone) ||
    validaDigitos(parent.tipo)
  ) {
    if (campo) {
      if (campo) return true;
    } else {
      return false;
    }
  }

  return true;
}
