import BaseSerializer from "./base.serializer";

export default class VideoIdiomaSerializer extends BaseSerializer {
  fromJson(json) {
    const idioma = {};

    Object.assign(
      idioma,
      json.id_moderacao_video_idioma && { id: json.id_moderacao_video_idioma },
      json.titulo && { titulo: json.titulo },
      json.descricao && { descricao: json.descricao },
      json.link && { link: json.link },
      json.id_idioma && { idIdioma: json.id_idioma }
    );

    return idioma;
  }
}
