export default class ExpositorIdiomaSerializer {
  fromJson(json) {
    const expositorIdiomas = {};

    Object.assign(
      expositorIdiomas,
      json.id_expositor_idioma && {
        idExpositorIdioma: json.id_expositor_idioma,
      },
      json.id_expositor && { idExpositor: json.id_expositor },
      json.descricao && { descricao: json.descricao },
      json.idioma && { idioma: json.idioma }
    );

    return expositorIdiomas;
  }

  toJson(expositor) {
    const expositorIdiomas = {};

    Object.assign(
      expositorIdiomas,

      expositor.idIdioma && { id_idioma: expositor.idIdioma },
      expositor.idExpositor && {
        id_expositor_idioma: expositor.idExpositor,
      },
      expositor.descricao && { descricao: expositor.descricao },
      expositor.idioma && { idioma: expositor.idioma }
    );
    return expositorIdiomas;
  }
}
