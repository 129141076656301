import React from "react";
import history from "services/history";

export default function MainPage({ campoCustomizado }) {

  return (
    <div className="venha-ver-container">
      <div className="infos">
        <div className="texto">
          <h1>{campoCustomizado[14]?.item}</h1>

          <h3>{campoCustomizado[15]?.item}</h3>
          <h3>{campoCustomizado[16]?.item}</h3>
          <h3>{campoCustomizado[17]?.item}</h3>
          <h3>{campoCustomizado[18]?.item}</h3>
        </div>

        <button
          type="button"
          onClick={() => {
            history.push("/programacao-eventos");
          }}
        >
          {campoCustomizado[19]?.item}
        </button>
      </div>
    </div>
  );
}
