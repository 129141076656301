import React from "react";

export default function VideoEmbed({ url = "" }) {
  let regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let match = url.match(regExp);

  const youtube = match && match[7].length === 11 ? match[7] : false;

  let vimeo = null;
  if (!youtube) {
    if (url.match("https://(player.)?vimeo.com")) {
      vimeo = url.split(/video\/|https:\/\/vimeo\.com\//);
      if (vimeo.length > 1) {
        vimeo = vimeo[1].split(/[?&]/)[0];
      }
    }
  }

  return (
    <div>
      {youtube && (
        <iframe
          src={`https://www.youtube.com/embed/${youtube}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      )}

      {vimeo && (
        <iframe
          src={`https://player.vimeo.com/video/${vimeo}`}
          title="Vimeo"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      )}
    </div>
  );
}
