import React, { useState } from "react";
import TextareaInput from "components/TextareaInput";
import { Form, Formik } from "formik";
import { Button, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import FaleComExpositorService from "services/fale-com-expositor.service";
import { useTranslation } from "react-i18next";

export default function ModalFaleConosco({ modalAberto, setModalAberto }) {
  const { t } = useTranslation();
  const _faleConoscoService = new FaleComExpositorService();
  const [mensagemEnviada, setMensagemEnviada] = useState(false);
  const [mensagemEnviando, setMensagemEnviando] = useState(false);
  const [primeiroEnvio, setPrimeiroEnvio] = useState(true);

  async function enviarEmail(values) {
    await _faleConoscoService
      .createMensagem(values)
      .then(() => {
        setMensagemEnviada(true);
        setMensagemEnviando(false);
      })
      .catch(() => {
        setPrimeiroEnvio(true);
        setMensagemEnviando(false);
      });
  }

  return (
    <div>
      <Modal
        size="lg"
        isOpen={modalAberto}
        toggle={() => {
          setModalAberto(false);
        }}
        className="modal-fale-conosco"
      >
        <ModalHeader>{t("Fale conosco")}</ModalHeader>

        <ModalBody>
          <Formik
            enableReinitialize={true}
            onSubmit={(values) => {
              enviarEmail(values);
            }}
            initialValues={{
              assunto: "",
            }}
          >
            {(props) => {
              return (
                <Form>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    className="container-texto"
                  >
                    <TextareaInput
                      placeholder={t("Digite sua mensagem aqui")}
                      name="assunto"
                    />
                  </Col>

                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    className="mensagem-enviada"
                  >
                    {mensagemEnviada && <h7>{t("A mensagem foi enviada.")}</h7>}
                  </Col>

                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    className="fale-conosco-button-container"
                  >
                    <Button
                      className="btn-galeria-dark modal-fale-conosco-button-submit"
                      type="submit"
                      onClick={() => {
                        setMensagemEnviando(true);
                        setMensagemEnviada(false);
                        setPrimeiroEnvio(false);
                      }}
                    >
                      {primeiroEnvio && t("Enviar")}
                      {mensagemEnviando && t("Enviando...")}
                      {mensagemEnviada && t("Enviar novamente")}
                    </Button>

                    <Button
                      className="btn-event-dark"
                      type="button"
                      onClick={() => setModalAberto(false)}
                    >
                      Voltar
                    </Button>
                  </Col>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
}
