import CoreApiService from "./core-api.service";
import api from "./api.service";
import ExpositoresClicadosSerializer from "./serializers/expositores-clicados.serializer";
import QueryOptions from "utils/query-options";
import EditionService from "./edition.service";

export default class ExpositoresClicadosService extends CoreApiService {
  constructor() {
    super("expositores", "clicados", new ExpositoresClicadosSerializer());
  }

  async listarUltimosVisitados(queryOptions = null, isListView = null) {
    const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
    const _edicaoService = new EditionService();

    const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
      queryOptionsEdicaoAtiva
    );

    const response = await api.get(
      `${this.parentEndpoint}/${this.endpoint}/edicao/${edicaoAtual.id}?${
        (queryOptions && queryOptions.toQueryString()) || ""
      }`
    );
    const data = { expositoresClicados: response.data.items };

    return this.serializer.fromJson(data);
  }
}
