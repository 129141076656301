import axios from "axios";

export default class FetchAddressService {
  queryCep(cep) {
    const url = `${process.env.REACT_APP_VIACEP}${cep}/json`;
    return axios
      .get(url)
      .then((res) => res.data)
      .then((data) => {
        return data;
      })
      .catch(() => {
        throw new Error("Serviço ViaCep está indisponível.");
      });
  }
}
