import EventService from "services/evento.service";
import AvertisingdService from "services/propagandad.service";

export async function incrementarCliqueEmPublicidade(publicidade) {
  const _publicidadesService = new AvertisingdService();

  window.open(publicidade.link, "_blank");
  await _publicidadesService.incrementarClique(publicidade.id);
}

export async function incrementarCliqueEmBannerEvento(banner) {
  const _bannerEvento = new EventService();

  window.open(banner.link, "_blank");
  await _bannerEvento.incrementarClique(banner.idEventoBanner);
}
