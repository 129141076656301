import * as Yup from "yup";
import * as messages from "./messages";

const mensagens = messages.getMessages();

const parseDigits = (v, reg) => (v ? v.replace(reg, "") : "");

Yup.addMethod(Yup.string, "cpfCnpj", function validateCPF(message) {
  return this.test("cpf", message || mensagens.DOCUMENT_INVALID, (val) => {
    const str = parseDigits(val, /(?!\d)./g);

    if (str.length > 0) {
      if (str.length <= 11) {
        const strCPF = str;
        if (
          strCPF === "00000000000" ||
          strCPF === "11111111111" ||
          strCPF === "22222222222" ||
          strCPF === "33333333333" ||
          strCPF === "44444444444" ||
          strCPF === "55555555555" ||
          strCPF === "66666666666" ||
          strCPF === "77777777777" ||
          strCPF === "88888888888" ||
          strCPF === "99999999999"
        ) {
          return false;
        }

        let soma;
        let rest;
        soma = 0;
        if (strCPF === "00000000000") return false;

        for (let i = 1; i <= 9; i += 1)
          soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
        rest = (soma * 10) % 11;

        if (rest === 10 || rest === 11) rest = 0;
        if (rest !== parseInt(strCPF.substring(9, 10), 10)) return false;

        soma = 0;
        for (let i = 1; i <= 10; i += 1)
          soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
        rest = (soma * 10) % 11;

        if (rest === 10 || rest === 11) rest = 0;
        if (rest !== parseInt(strCPF.substring(10, 11), 10)) return false;
        return true;
      } else {
        const strCNPJ = str;
        if (
          strCNPJ === "00000000000000" ||
          strCNPJ === "11111111111111" ||
          strCNPJ === "22222222222222" ||
          strCNPJ === "33333333333333" ||
          strCNPJ === "44444444444444" ||
          strCNPJ === "55555555555555" ||
          strCNPJ === "66666666666666" ||
          strCNPJ === "77777777777777" ||
          strCNPJ === "88888888888888" ||
          strCNPJ === "99999999999999"
        )
          return false;

        // validate DVs
        let len = strCNPJ.length - 2;
        let numbers = strCNPJ.substring(0, len);
        const digits = strCNPJ.substring(len);
        let soma = 0;
        let pos = len - 7;
        for (let i = len; i >= 1; i -= 1) {
          soma += Number(numbers.charAt(len - i)) * pos;
          pos -= 1;
          if (pos < 2) pos = 9;
        }
        let result = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (result !== Number(digits.charAt(0))) return false;

        len += 1;
        numbers = strCNPJ.substring(0, len);
        soma = 0;
        pos = len - 7;
        for (let i = len; i >= 1; i -= 1) {
          soma += Number(numbers.charAt(len - i)) * pos;
          pos -= 1;
          if (pos < 2) pos = 9;
        }
        result = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (result !== Number(digits.charAt(1))) return false;

        return true;
      }
    } else {
      return true;
    }
  });
});

Yup.addMethod(Yup.string, "cpf", function validateCPF(message) {
  return this.test("cpf", message || mensagens.CPF, (val) => {
    const strCPF = parseDigits(val, /(?!\d)./g);

    if (
      strCPF === "00000000000" ||
      strCPF === "11111111111" ||
      strCPF === "22222222222" ||
      strCPF === "33333333333" ||
      strCPF === "44444444444" ||
      strCPF === "55555555555" ||
      strCPF === "66666666666" ||
      strCPF === "77777777777" ||
      strCPF === "88888888888" ||
      strCPF === "99999999999"
    ) {
      return false;
    }

    let soma;
    let rest;
    soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i += 1)
      soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
    rest = (soma * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(strCPF.substring(9, 10), 10)) return false;

    soma = 0;
    for (let i = 1; i <= 10; i += 1)
      soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
    rest = (soma * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(strCPF.substring(10, 11), 10)) return false;
    return true;
  });
});

Yup.addMethod(Yup.string, "cnpj", function validateCNPJ(message) {
  return this.test("cnpj", message || mensagens.CNPJ, (val) => {
    const strCNPJ = parseDigits(val, /(?!\d)./g);

    // exclude invalids
    if (
      strCNPJ === "00000000000000" ||
      strCNPJ === "11111111111111" ||
      strCNPJ === "22222222222222" ||
      strCNPJ === "33333333333333" ||
      strCNPJ === "44444444444444" ||
      strCNPJ === "55555555555555" ||
      strCNPJ === "66666666666666" ||
      strCNPJ === "77777777777777" ||
      strCNPJ === "88888888888888" ||
      strCNPJ === "99999999999999"
    )
      return false;

    // validate DVs
    let len = strCNPJ.length - 2;
    let numbers = strCNPJ.substring(0, len);
    const digits = strCNPJ.substring(len);
    let soma = 0;
    let pos = len - 7;
    for (let i = len; i >= 1; i -= 1) {
      soma += Number(numbers.charAt(len - i)) * pos;
      pos -= 1;
      if (pos < 2) pos = 9;
    }
    let result = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (result !== Number(digits.charAt(0))) return false;

    len += 1;
    numbers = strCNPJ.substring(0, len);
    soma = 0;
    pos = len - 7;
    for (let i = len; i >= 1; i -= 1) {
      soma += Number(numbers.charAt(len - i)) * pos;
      pos -= 1;
      if (pos < 2) pos = 9;
    }
    result = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (result !== Number(digits.charAt(1))) return false;

    return true;
  });
});

Yup.addMethod(Yup.string, "password", function validatePassword(message) {
  return this.test("password", message || mensagens.PASSWORD_SAFE, (val) => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%<^&*?=])[0-9a-zA-Z!@#$%<^&*?=]{6,}$/.test(
      val
    );
  });
});
