/* eslint-disable react-hooks/exhaustive-deps */
import BannersPublicidade from "components/expo-revestir/BannersPublicidade";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CampoCustomizadoItem from "services/campo-customizado-item.service";
import { AppContext } from "store";
import Card from "./Card";
import CarrouselEventosNaoOficiais from "./CarrouselEventosNaoOf";
import DuvidasPage from "./DuvidasPage";
import EventoDoDia from "./EventoDoDiaCard";
import LancamentoEdicao from "./LancamentosEdicao";
import MainPage from "./MainPage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Home() {
  const { i18n } = useTranslation();
  const history = useHistory();
  const [campoCustomizado, setCampoCustomizado] = useState([]);
  const { user } = useContext(AppContext);

  useEffect(() => {
    async function init() {
      const _campoCustomizadoService = new CampoCustomizadoItem();
      const campoCustomizadoResponse = await _campoCustomizadoService.listSub(
        2
      );
      setCampoCustomizado(campoCustomizadoResponse);
    }

    if ([4,5].includes(user.perfil.id)) {
      history.push("/assentador-cursos");
    }

    init();
  }, [i18n.language]);

  return (
    <>
      <BannersPublicidade idAreaPublicidade={1} bannerHome={true} />

      <div className="main-page-container">
        <Card campoCustomizado={campoCustomizado} />
      </div>

      <LancamentoEdicao campoCustomizado={campoCustomizado} />

      <EventoDoDia campoCustomizado={campoCustomizado} />

      <MainPage campoCustomizado={campoCustomizado} />

      <CarrouselEventosNaoOficiais campoCustomizado={campoCustomizado} />

      <DuvidasPage />
    </>
  );
}
