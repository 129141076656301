/* eslint-disable react-hooks/exhaustive-deps */
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/scss/paper-kit.scss";
// styles
import "bootstrap/scss/bootstrap.scss";
import ExpositorNav from "components/expo-revestir/ExpositorNav";
import React, { useContext, useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { Router, Route } from "react-router-dom";
import history from "services/history";
import { ExpositorCatalogos } from "./ExpositorCatalogos";
import ExpositorEventos from "./ExpositorEventos";
import ExpositorProduto from "./ExpositorProduto";
import { ExpositorSobre } from "./ExpositorSobre";
import { ExpositorVideos } from "./ExpositorVideos";
import { useParams } from "react-router";
import ProdutoIndividual from "./ExpositorProduto/ProdutoIndividual";
import BannerExpositor from "components/expo-revestir/BannerExpositor";
import ExpositoresService from "services/expositores.service";
import { AppContext } from "store";
import { Container } from "reactstrap";

export default function ExpositorRoutes() {
  const { setIdExpositor, setExpositorUriUnico } = useContext(AppContext);
  const [expositor, setExpositor] = useState();
  const { uriUnico } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      const _expositorService = new ExpositoresService();
      const data = await _expositorService.readPorUri(uriUnico);
      setIdExpositor(data.id);
      setExpositorUriUnico(uriUnico);
      setExpositor(data);
    }

    init();
  }, []);

  return (
    <>
      {loading && (
        <div className="expositor-loading-container">
          <lottie-player
            src="https://assets3.lottiefiles.com/packages/lf20_zlftkfzn.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px", margin: "0 auto" }}
            loop
            autoplay
          />
        </div>
      )}

      <div hidden={loading}>
        <div className="expositor-headline-container">
          <BannerExpositor loading={loading} setLoading={setLoading} />

          <Container>
            <div className="header-container-expositor-nav">
              <h1>{expositor?.nomeFantasia}</h1>
            </div>
          </Container>
        </div>

        <ExpositorNav />
        <Router history={history}>
          <Switch>
            <Route
              path="/expositor/:uriUnico"
              component={ExpositorProduto}
              exact
            />
            <Route
              path="/expositor/:uriUnico/produtos"
              component={ExpositorProduto}
            />
            <Route
              path="/expositor/:uriUnico/produto/:id"
              component={ProdutoIndividual}
            />

            <Route
              path="/expositor/:uriUnico/videos"
              component={ExpositorVideos}
            />

            <Route
              path="/expositor/:uriUnico/catalogos"
              component={ExpositorCatalogos}
            />

            <Route
              path="/expositor/:uriUnico/eventos"
              component={ExpositorEventos}
            />

            <Route
              path="/expositor/:uriUnico/sobre"
              component={ExpositorSobre}
            />
          </Switch>
        </Router>
      </div>
    </>
  );
}
