export default class languagesSerializer {
  fromJson(json) {
    
    const languages = {
      id: json.id_idioma,
      nome: json.nome,
      codigo: json.codigo,
      disabled: json.disabled,
      label: json.nome,
      value: json.id_idioma,
    };

    return languages;
  }

  toJson(languages) {
    return {
      id_idioma: languages.id,
      nome: languages.nome,
      codigo: languages.codigo,
      disabled: languages.disabled
    };
  }
}
