
export default class CursoModuloIdiomaSerializer {
  fromJson(json) {
    const idioma = {};

    Object.assign(
      idioma,
      json.id_curso_modulo_idioma && { idCursoModuloIdioma: json.id_curso_modulo_idioma },
      json.id_idioma && { idIdioma: json.id_idioma },
      json.nome_modulo && { nome: json.nome_modulo },
    );

    return idioma;
  }
}
