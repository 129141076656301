import { add } from "date-fns";

export function fromDateGmt(date) {
  const fusoHorario = 3;

  date = new Date(date.substring(0, date.length - 4));
  date = add(date, { hours: 0 - fusoHorario });

  return date;
}

export function toDateGMT(date) {
  const fusoHorario = 3;

  date = add(date, { hours: 0 - fusoHorario });

  const ano = date.getFullYear();
  const mes = (date.getMonth() + 1).toString().padStart(2, "0");
  const dia = date.getDate().toString().padStart(2, "0");

  const horas = date.getHours().toString().padStart(2, "0");
  const minutos = date.getMinutes().toString().padStart(2, "0");
  const segundos = date.getSeconds().toString().padStart(2, "0");

  return `${ano}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;
}

export function paraDataNascimento(date) {

  const ano = date.getUTCFullYear().toString();
  const mes = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const dia = date.getUTCDate().toString().padStart(2, "0");

  const horas = date.getUTCHours().toString().padStart(2, "0");
  const minutos = date.getUTCMinutes().toString().padStart(2, "0");
  const segundos = date.getUTCSeconds().toString().padStart(2, "0");

  return `${ano}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;
}
