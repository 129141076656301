/* eslint-disable react-hooks/exhaustive-deps */
import EnderecoForm, { enderecoFields } from "components/form/EnderecoForm";
import PhoneForm, { telefoneFields } from "components/form/PhoneForm";
import ImageeInputPreview from "components/ImageInputPreview";
import TextInput from "components/TextInput";
import { Form, Formik, FieldArray } from "formik";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Modal, ModalBody, Row } from "reactstrap";
import UsuarioService from "services/usuario.service";
import { AppContext } from "store";
import { userProfiles } from "utils/permission-user";
import unMask from "utils/unmask";
import { usuarioUpdateSchema } from "validations/usuario-update.validation";
import imageProfileDefault from "../../assets/img/expo-revestir/icons/default-profile.svg";
import DialogRemoverFoto from "./DialogRemoverFoto";

export default function ModalEditarUsuario({
  modalAberto,
  setModalAberto,
  isList = true,
}) {
  const { user, setUser } = useContext(AppContext);
  const { t } = useTranslation();
  const [dadosPerfilUsuario, setDadosPerfilUsuario] = useState(true);
  const [enderecoContainer, setEnderecoContainer] = useState(false);
  const [contatoContainer, setContatoContainer] = useState(false);
  const [imagemPerfil, setImagePerfil] = useState(imageProfileDefault);
  const [flagImagemAlterada, setFlagImagemAlterada] = useState(false);
  const [confirmarDelecaoFoto] = useState(false);
  const [dialogAberto, setDialogAberto] = useState(false);
  const [mostrarValidacaoSenha, setMostrarValidacaoSenha] = useState(false);
  const [validationPass, setValidationPass] = useState({
    numero: false,
    letraMinuscula: false,
    letraMaiuscula: false,
    caracterEspecial: false,
    length: false,
  });
  const _usuarioService = new UsuarioService();

  useEffect(() => {
    const imagemPerfil = getImageProfile(user);
    setImagePerfil(imagemPerfil);
  }, [user]);

  function validPass(event) {
    const value = event.target.value;

    const numero = /^(?=.*\d)/.test(value);
    const letraMinuscula = /(?=.*[a-z])/.test(value);
    const letraMaiuscula = /(?=.*[A-Z])/.test(value);
    const caracterEspecial = /(?=.*[!@#$%<^&*?=])/.test(value);
    const length = value.length >= 6;

    setValidationPass({
      numero,
      letraMinuscula,
      letraMaiuscula,
      caracterEspecial,
      length,
    });
  }

  function getImageProfile() {
    const baseUrl = process.env.REACT_APP_API_URL;
    const tempo = new Date().getTime();

    return user.imageFileName
      ? `${baseUrl}usuarios/${user.id}/display?${tempo}`
      : imageProfileDefault;
  }

  async function handleChangeImageFile(imageFile) {
    if (imageFile) {
      await _usuarioService.uploadImage(imageFile, user.id);
      setUser({ ...user, imageFileName: new Date().getTime() });
      setFlagImagemAlterada(true);
    } else {
      return null;
    }
  }

  async function removerFotoPerfil() {
    await _usuarioService.removerImagemPerfil(user.id).then(() => {
      setUser({ ...user, imageFileName: null });
      setDialogAberto(false);
    });
  }

  async function onSubmit(values) {
    values.id = user.id;

    const data = { ...values };

    const telefonesValidosUsuario = data.telefones.filter(
      (item) => unMask(item.ddi) && unMask(item.telefone)
    );
    data.telefones = telefonesValidosUsuario;

    const enderecosValidosUsuarios = data.enderecos.filter(
      (item) => item.logradouro
    );
    data.enderecos = enderecosValidosUsuarios;

    await _usuarioService
      .update(data)
      .then(async (response) => {
        setModalAberto(!modalAberto);
        setUser(response);
        setDadosPerfilUsuario(true);
        setEnderecoContainer(false);
        setContatoContainer(false);
      })
      .catch(({ response }) => {});
  }

  function mudarCorBotao(id, cor) {
    const idsBotoes = [
      "botao-alterar-dados",
      "botao-endereco",
      "botao-contato",
    ];

    if (mudarCorBotao) {
      document.getElementById(id).style.backgroundColor = cor;

      idsBotoes.forEach((botoesPerfil) => {
        if (botoesPerfil !== id) {
          document.getElementById(botoesPerfil).style.backgroundColor = "#fff";
        }
      });
    }
  }

  return (
    <div>
      <DialogRemoverFoto
        dialogAberto={dialogAberto}
        setDialogAberto={setDialogAberto}
        removerFotoPerfil={removerFotoPerfil}
      />

      <Modal size="xl" isOpen={modalAberto} className="modal-perfil-usuario">
        <ModalBody className="modal-perfil-usuario-body">
          <div className="modal-perfil-usuario-tab-menu">
            <button
              onClick={() => {
                setDadosPerfilUsuario(true);
                setEnderecoContainer(false);
                setContatoContainer(false);
                mudarCorBotao("botao-alterar-dados", "#F87FB4");
              }}
              style={{ backgroundColor: "#F87FB4" }}
              id="botao-alterar-dados"
            >
              {t("Meus dados")}
            </button>

            <button
              onClick={() => {
                setDadosPerfilUsuario(false);
                setEnderecoContainer(true);
                setContatoContainer(false);
                mudarCorBotao("botao-endereco", "#93D501");
              }}
              id="botao-endereco"
            >
              {t("Endereço")}
            </button>

            <button
              onClick={() => {
                setDadosPerfilUsuario(false);
                setEnderecoContainer(false);
                setContatoContainer(true);
                mudarCorBotao("botao-contato", "#52C9E8");
              }}
              id="botao-contato"
            >
              {t("Contato")}
            </button>
          </div>

          <Formik
            enableReinitialize={true}
            initialValues={{
              ...user,
              senha: "",
              confSenha: "",
            }}
            validationSchema={usuarioUpdateSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {(props) => {
              return (
                <Form>
                  <Container>
                    {dadosPerfilUsuario && (
                      <Row id="dados-basicos">
                        <Col xs="12" sm="12" md="12" lg="12" xl="4">
                          <div className="container-foto-do-usuario">
                            <div className="foto-usuario">
                              <ImageeInputPreview
                                image={
                                  user?.imageFileName
                                    ? imagemPerfil
                                    : imageProfileDefault
                                }
                                changeImage={(e) => {
                                  handleChangeImageFile(e);
                                }}
                              />
                              <label for="files" className="alterar-foto">
                                {t("Alterar foto do perfil")}
                              </label>

                              {user.imageFileName && (
                                <>
                                  {!confirmarDelecaoFoto && (
                                    <h5
                                      className="remover-foto"
                                      onClick={() => setDialogAberto(true)}
                                    >
                                      {t("Remover foto do perfil")}
                                    </h5>
                                  )}
                                </>
                              )}

                              <br />

                              <label>
                                {flagImagemAlterada && (
                                  <p className="imagem-atualizada-label">
                                    {t("A imagem de perfil foi atualizada!")}
                                  </p>
                                )}
                              </label>
                            </div>
                          </div>
                        </Col>

                        <Col
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="8"
                          className="form-informacoes-pessoais"
                        >
                          <Col
                            xs="9"
                            sm="9"
                            md="12"
                            lg="12"
                            xl="12"
                            className="col-dados-basicos nome-completo"
                          >
                            <TextInput
                              label={t("Nome Completo")}
                              name="nome"
                              placeholder={t("Nome Completo")}
                              className="input-modal-usuario"
                            />
                          </Col>

                          <Row xs="12" sm="12" md="12" lg="12" xl="12">
                            <Col
                              xs="12"
                              sm="12"
                              md="12"
                              lg="6"
                              xl="6"
                              className="col-dados-basicos"
                            >
                              <TextInput
                                label={t("E-mail")}
                                name="email"
                                placeholder="E-mail"
                                className="input-modal-usuario"
                                disabled={!isList}
                              />
                            </Col>

                            <Col
                              xs="12"
                              sm="12"
                              md="12"
                              lg="6"
                              xl="6"
                              className="col-dados-basicos"
                            >
                              <TextInput
                                label={t("Confirme o e-mail")}
                                placeholder={t("Confirme o email")}
                                name="confEmail"
                                className="input-modal-usuario"
                                disabled={!isList}
                              />
                            </Col>
                          </Row>

                          {user?.perfil?.id !== userProfiles.visitante && (
                            <Row>
                              <Col
                                xs="12"
                                sm="12"
                                md="12"
                                lg="6"
                                xl="6"
                                className="col-dados-basicos"
                              >
                                <TextInput
                                  label={t("Senha")}
                                  placeholder={t("Senha")}
                                  name="senha"
                                  className="input-modal-usuario"
                                  onKeyUp={validPass}
                                  onFocus={() => setMostrarValidacaoSenha(true)}
                                  autoComplete={true}
                                  onBlur={(e) => {
                                    setMostrarValidacaoSenha(false);
                                    props.handleBlur(e);
                                  }}
                                  type="password"
                                />
                                {mostrarValidacaoSenha && (
                                  <div className="info-pass">
                                    <div className="title-list">
                                      {t("A senha deve conter")}:
                                    </div>
                                    <div>
                                      <div
                                        className={
                                          validationPass.numero &&
                                          "check-success"
                                        }
                                        style={{ display: "flex" }}
                                      >
                                        <div className="check">
                                          {!validationPass.numero && (
                                            <span
                                              className="check-text"
                                              style={{ marginRight: "3px" }}
                                            >
                                              -
                                            </span>
                                          )}
                                          {validationPass.numero && (
                                            <i className="pi pi-check"></i>
                                          )}
                                        </div>
                                        {t("Mínimo 1 número")}
                                      </div>
                                      <div
                                        className={
                                          validationPass.letraMinuscula &&
                                          "check-success"
                                        }
                                        style={{ display: "flex" }}
                                      >
                                        <div className="check">
                                          {!validationPass.letraMinuscula && (
                                            <span
                                              className="check-text"
                                              style={{ marginRight: "3px" }}
                                            >
                                              -
                                            </span>
                                          )}
                                          {validationPass.letraMinuscula && (
                                            <i className="pi pi-check"></i>
                                          )}
                                        </div>
                                        {t("Mínimo 1 letra minúscula")}
                                      </div>
                                      <div
                                        className={
                                          validationPass.letraMaiuscula &&
                                          "check-success"
                                        }
                                        style={{ display: "flex" }}
                                      >
                                        <div className="check">
                                          {!validationPass.letraMaiuscula && (
                                            <span
                                              className="check-text"
                                              style={{ marginRight: "3px" }}
                                            >
                                              -
                                            </span>
                                          )}
                                          {validationPass.letraMaiuscula && (
                                            <i className="pi pi-check"></i>
                                          )}
                                        </div>
                                        {t("Mínimo 1 letra maiúscula")}
                                      </div>
                                      <div
                                        className={
                                          validationPass.caracterEspecial &&
                                          "check-success"
                                        }
                                        style={{ display: "flex" }}
                                      >
                                        <div className="check">
                                          {!validationPass.caracterEspecial && (
                                            <span
                                              className="check-text"
                                              style={{ marginRight: "3px" }}
                                            >
                                              -
                                            </span>
                                          )}
                                          {validationPass.caracterEspecial && (
                                            <i className="pi pi-check"></i>
                                          )}
                                        </div>
                                        {t("Mínimo 1 caractere especial")}{" "}
                                        {"(!@#$%<^&*?=)"}
                                      </div>
                                      <div
                                        className={
                                          validationPass.length &&
                                          "check-success"
                                        }
                                        style={{ display: "flex" }}
                                      >
                                        <div className="check">
                                          {!validationPass.length && (
                                            <span
                                              className="check-text"
                                              style={{ marginRight: "3px" }}
                                            >
                                              -
                                            </span>
                                          )}
                                          {validationPass.length && (
                                            <i className="pi pi-check"></i>
                                          )}
                                        </div>
                                        {t("Mínimo 6 caracteres")}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Col>
                              <Col
                                xs="12"
                                sm="12"
                                md="12"
                                lg="6"
                                xl="6"
                                className="col-dados-basicos"
                              >
                                <TextInput
                                  label={t("Confirme a senha")}
                                  placeholder={t("Senha")}
                                  name="confSenha"
                                  className="input-modal-usuario"
                                  onKeyUp={validPass}
                                  onFocus={() => setMostrarValidacaoSenha(true)}
                                  onBlur={(e) => {
                                    setMostrarValidacaoSenha(false);
                                    props.handleBlur(e);
                                  }}
                                  type="password"
                                />
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    )}

                    {enderecoContainer && (
                      <div id="endereco-form-usuario-container">
                        <FieldArray
                          name="enderecos"
                          render={(arrayHelpers) => (
                            <div>
                              {props?.values?.enderecos?.map(
                                (endereco, index) => (
                                  <div key={index}>
                                    <EnderecoForm
                                      form={props}
                                      index={index}
                                      arrayHelpers={arrayHelpers}
                                      address={endereco}
                                    />
                                  </div>
                                )
                              )}
                              {isList ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  className="add-item"
                                  onClick={() => {
                                    arrayHelpers.insert(
                                      props?.values?.enderecos?.length + 1,
                                      enderecoFields
                                    );
                                  }}
                                >
                                  {t("Adicionar um novo endereço")}
                                </div>
                              ) : null}
                            </div>
                          )}
                        />
                      </div>
                    )}

                    {contatoContainer && (
                      <div id="contato-form-usuario-container">
                        <FieldArray
                          name="telefones"
                          render={(arrayHelpers) => (
                            <div className="p-col-12">
                              {props?.values?.telefones?.map((phone, index) => (
                                <div key={index}>
                                  <PhoneForm
                                    parent="telefones."
                                    arrayHelpers={arrayHelpers}
                                    index={index}
                                    phones={props.values.telefones}
                                    form={props}
                                    requiredHashInFields={
                                      props.errors?.phones ? true : false
                                    }
                                  />
                                </div>
                              ))}

                              {isList ? (
                                <div
                                  className="add-item"
                                  onClick={() =>
                                    arrayHelpers.insert(
                                      props?.values?.telefones?.length + 1,
                                      telefoneFields
                                    )
                                  }
                                >
                                  {t("Adicionar um novo telefone")}
                                </div>
                              ) : null}
                            </div>
                          )}
                        />
                      </div>
                    )}

                    <Row className="modal-perfil-usuario-botoes">
                      <Col>
                        <Button
                          type="submit"
                          className="modal-perfil-botao-salvar"
                        >
                          {t("Salvar")}
                        </Button>

                        <Button
                          onClick={() => {
                            setModalAberto(!modalAberto);
                            setDadosPerfilUsuario(true);
                            setEnderecoContainer(false);
                            setContatoContainer(false);
                          }}
                          className="modal-perfil-botao-cancelar wrapper"
                        >
                          {t("Cancelar")}
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
}
