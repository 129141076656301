import * as Yup from "yup";
import * as messages from "./messages";

const mensagens = messages.getMessages();

function validaPais(pais, campo) {
  if (pais?.id === 1) {
    return campo !== "" && campo !== null && campo !== undefined;
  } else {
    return true;
  }
}

export const addressSchema = Yup.object({
  idPais: Yup.object().required(mensagens.REQUIRED),
  cep: Yup.string().test("cep", mensagens.REQUIRED, function (value) {
    return validaPais(this.parent.idPais, value);
  }),
  logradouro: Yup.string().required(mensagens.REQUIRED),
  bairro: Yup.string().required(mensagens.REQUIRED),
  numero: Yup.string().required(mensagens.REQUIRED),

  cidadeExterior: Yup.string().test(
    "cidadeExterior",
    mensagens.REQUIRED,
    function (value) {
      if (this.parent?.idPais?.id !== 1) {
        return value !== "" && value !== null && value !== undefined;
      } else {
        return true;
      }
    }
  ),
  estadoExterior: Yup.string().test(
    "estadoExterior",
    mensagens.REQUIRED,
    function (value) {
      if (this.parent?.idPais?.id !== 1) {
        return value !== "" && value !== null && value !== undefined;
      } else {
        return true;
      }
    }
  ),

  idEstado: Yup.object()
    .test("idEstado", mensagens.REQUIRED, function (value) {
      return validaPais(this.parent.idPais, value);
    })
    .nullable(),
  idCidade: Yup.object()
    .test("idCidade", mensagens.REQUIRED, function (value) {
      return validaPais(this.parent.idPais, value);
    })
    .nullable(),
});
