/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Authentication from "../../services/authentication.service";
import history from "../../services/history";

export default function Logout() {
  // const { socketio } = useContext(AppContext);
  useEffect(() => {
    Authentication.logout();

    // socketio.disconnect();
    history.push("/entrar");
  }, []);

  return <div></div>;
}
