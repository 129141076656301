import { format } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import getDataLocale from "utils/map-data";

export default function EventosConteudosDisponiveis({
  setModalAberto,
  modalAberto,
  setEvento,
  eventosDisponiveis,
}) {
  const { t } = useTranslation();
  const [time] = useState(new Date().getTime());

  return (
    <div>
      {eventosDisponiveis?.length ? (
        <div className="eventos-conteudos-disponiveis-container">
          <div className="container">
          <Row className="filtragem-e-info-container">
                <Col>
                <h1>{t("Conteúdos Disponíveis")}</h1>
                </Col>
              </Row>
            <div className="eventos-container">
              <div className="eventos-expositor-container">
               
                <Row>
                  {eventosDisponiveis?.map((evento) => (
                    <Col xs="12" sm="12" md="6" lg="4" xl="3">
                      <div
                        className="imagem-card"
                        onClick={() => {
                          setModalAberto(!modalAberto);
                          setEvento(evento);
                        }}
                      >
                        <div>
                          <div className="data-evento-carrosel">
                            <span>{format(evento.dataInicio, "dd")}</span>
                            <span>
                              {format(evento.dataInicio, "MMM", {
                                locale: getDataLocale(),
                              })}
                            </span>
                          </div>
                          {evento.idEventoCategoria === 1 && (
                            <div
                              style={{
                                borderTop: "3px solid #52C9E8",
                              }}
                            >
                              <div class="card-evento">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time}`}
                                  alt="Imagem do evento"
                                />
                              </div>
                            </div>
                          )}
                          {evento.idEventoCategoria === 2 && (
                            <div
                              style={{
                                borderTop: "3px solid #60269E",
                              }}
                            >
                              <div class="card-evento">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time}`}
                                  alt="Imagem do evento"
                                />
                              </div>
                            </div>
                          )}
                          {evento.idEventoCategoria === 3 && (
                            <div
                              style={{
                                borderTop: "3px solid #F87FB4",
                              }}
                            >
                              <div class="card-evento">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time}`}
                                  alt="Imagem do evento"
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="card-body">
                          <div className="card-tags">
                            {evento.idEventoCategoria === 1 && (
                              <div
                                style={{
                                  marginRight: "8px",
                                  backgroundColor: "#52C9E8",
                                  padding: "1px 5px",
                                }}
                              >
                                <p style={{ margin: "0", color: "#fff" }}>
                                  {t("Evento oficial")}
                                </p>
                              </div>
                            )}

                            {evento.idEventoCategoria === 2 && (
                              <div
                                style={{
                                  marginRight: "8px",
                                  backgroundColor: "#60269E",
                                  padding: "1px 5px",
                                }}
                              >
                                <p style={{ margin: "0", color: "#fff" }}>
                                  {t("Evento parceiro")}
                                </p>
                              </div>
                            )}

                            {evento.idEventoCategoria === 3 && (
                              <div
                                style={{
                                  marginRight: "8px",
                                  backgroundColor: "#F87FB4",
                                  padding: "1px 5px",
                                }}
                              >
                                <p style={{ margin: "0", color: "#fff" }}>
                                  {t("Evento expositor")}
                                </p>
                              </div>
                            )}

                            <div className="plataforma-evento">
                              {evento.eventoExterno ? (
                                <div
                                  style={{
                                    backgroundColor: "#FE391F",
                                    padding: "1px 5px",
                                  }}
                                >
                                  <p style={{ margin: "0", color: "#fff" }}>
                                    {t("Evento externo")}
                                  </p>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    backgroundColor: "#93D501",
                                    padding: "1px 5px",
                                  }}
                                >
                                  <p style={{ margin: "0", color: "#fff" }}>
                                    {t("Plataforma Expo Revestir")}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="text-container">
                            <h3>{evento.titulo}</h3>
                            <p>{evento.descricao}</p>
                          </div>

                          <div className="card-footer-container">
                            <div className="evento-data">
                              <div className="evento-horas-data">
                                <p>
                                  {format(evento.dataInicio, "H:mm")} -{" "}
                                  {format(evento.dataFim, "H:mm")}
                                </p>
                              </div>{" "}
                              <div className="evento-calendario-data">
                                <p>{evento.dataFimStr}</p>
                              </div>{" "}
                            </div>{" "}
                            <div className="button-agenda-expositor">
                              <button className="mr-1 btn-card-event btn btn-info botao-card-evento">
                                {t("Ver evento")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
