import QueryOptions from "utils/query-options";
import api from "./api.service";
import CoreApiService from "./core-api.service";
import EditionService from "./edition.service";
import UsuarioSerializer from "./serializers/usuario.serializer";

export default class UsuarioService extends CoreApiService {
  constructor() {
    super(undefined, "usuarios", new UsuarioSerializer());
  }

  async recuperarSenha(data) {
    const response = await api.post(`recupera-senha`, data);
    return response;
  }

  async mudarSenha(data) {
    const response = await api.put(`altera-senha`, data);
    return response;
  }

  async favoritar(data) {
    const response = await api.put(`${this.endpoint}/favoritos`, data);
    return response.data;
  }

  async uploadImage(file, id) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.put(`${this.endpoint}/${id}/uploads`, formData);
    const data = response.data;
    return this.serializer.fromJson(data.oret);
  }

  async CadastrarUsuario(item) {
    this.parentEndpoint = "plataforma";

    const response = await api.post(
      `${this.parentEndpoint}/${this.endpoint}`,
      this.serializer.toJson(item)
    );

    return response;
  }

  async agendaEventos() {
    this.parentEndpoint = "usuarios/agendas";
    this.endpoint = "edicoes";

    const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
    const _edicaoService = new EditionService();

    const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
      queryOptionsEdicaoAtiva
    );

    const response = await api.get(
      `${this.parentEndpoint}/${this.endpoint}/${edicaoAtual.id}`
    );

    return this.serializer.fromJson({ eventosAgendados: response.data });
  }

  async agendarEventos(item) {
    this.parentEndpoint = "usuarios";
    this.endpoint = "agendas";

    await api.put(`${this.parentEndpoint}/${this.endpoint}`, item);

    return this.agendaEventos();
  }

  async removerImagemPerfil(id) {
    const response = await api.delete(`${this.endpoint}/${id}/imagens`);
    return response;
  }
}
