export default class StateSerializer {
  fromJson(json) {
    const state = {
      id: json.id_estado,
      value: json.id_estado,
      uf: json.uf,
      label: json.uf,
      state: json.estado,
      ibje: json.ibje,
      ddd: json.ddd,
    };

    return state;
  }

  toJson(state) {
    return {
      id_estado: state.id,
    };
  }
}
