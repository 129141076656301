import LanguagesSerializer from "./languages.serializer";

export default class ExpositoreslanguageSerializer {
  constructor() {
    this.languagesSerializer = new LanguagesSerializer();
  }
  fromJson(json) {
    const expositoresLanguage = {};

    Object.assign(
      expositoresLanguage,
      json.descricao && { descricao: json.descricao },
      json.idioma.id_idioma && { idIdioma: json.idioma.id_idioma },
      json.id_expositor_idioma && {
        idExpositorIdioma: json.id_expositor_idioma,
      },
      json.titulo_video && { tituloVideo: json.titulo_video },
      json.descricao_video && { descricaoVideo: json.descricao_video },
      json.link_video && { linkVideo: json.link_video }
    );

    return expositoresLanguage;
  }

  toJson(expositor) {
    const expositorToJson = {};
    Object.assign(
      expositorToJson,
      expositor.descricao && { descricao: expositor.descricao },
      expositor.idIdioma && {
        id_idioma: expositor.idIdioma,
      },
      expositor.tituloVideo && { titulo_video: expositor.tituloVideo },
      expositor.descricaoVideo &&
        expositor.descricaoVideo && {
          descricao_video: expositor.descricaoVideo,
        },
      expositor.linkVideo && { link_video: expositor.linkVideo },
      expositor.idExpositorIdioma && {
        id_expositor_idioma: expositor.idExpositorIdioma,
      }
    );

    if (expositor.linkVideo === "") {
      expositorToJson.link_video = null;
    }

    if (expositor.descricaoVideo === "") {
      expositorToJson.descricao_video = null;
    }

    if (expositor.tituloVideo === "") {
      expositorToJson.titulo_video = null;
    }

    return expositorToJson;
  }
}
