import api from "./api.service";
import CoreApiService from "./core-api.service";
import MapaInterativoSerializer from "./serializers/mapa-interativo.serializer";

export default class MapaInterativoService extends CoreApiService {
  constructor() {
    super(undefined, "mapas", new MapaInterativoSerializer());
  }

  async get(slug) {
    const response = await api.get(`${this.endpoint}/${slug}/nome`);
    const data = response.data;
    return this.serializer.fromJson(data);
  }
}
