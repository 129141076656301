import { useContext, useState } from "react";
import { AppContext } from "store";
import { Container } from "reactstrap";
import ModalEditarUsuario from "components/expo-revestir/ModalEditarUsuario";
import { useTranslation } from "react-i18next";

export default function InfoUsuario() {
  const { user } = useContext(AppContext);
  const [modalAberto, setModalAberto] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <ModalEditarUsuario
        modalAberto={modalAberto}
        setModalAberto={setModalAberto}
      />

      <div className="usuario-nome-container">
        <Container>
          <h1>{user.nome}</h1>
          <h4 onClick={() => setModalAberto(true)}>{t("Alterar dados")}</h4>
        </Container>
      </div>
    </>
  );
}
