export default class SegmentoIdiomaSerializer {
  fromJson(json) {
    const segmentoIdioma = {
      id: json.id_segmento_idioma,
      nome: json.nome,
      label: json.nome,
      codigoIdioma: json.idioma.codigosegmentosIdiomas
    };

    return segmentoIdioma;
  }
}
